.Title
{
    font-size: var(--tiny-large);
    font-weight: 400;
    line-height: 1.2;

    letter-spacing: 0.12rem;

    color: var(--black);

    @media screen and (max-width: $mobile-media) {
        font-size: var( --additional-medium );
    }

    @media screen and (max-width: $small-mobile-media) {
        font-size: var( --extra-medium );
    }


    &_white {
        color: var(--white);
    }

    &_compact {
        @media screen and (max-width: $mobile-media) {
            top: 2rem;
        }
    }
}
