.Phone
{
    font-size: var(--extra-small);
    font-weight: bold;

    display: inline-block;

    text-decoration: none;
    letter-spacing: 0.08rem;

    color: var(--black);

    &:hover
    {
        color: var(--green-300);
    }

    &_topLine
    {
        font-size: var(--extra-medium);

        margin-left: auto;

        letter-spacing: 0.1rem;

        @media screen and (max-width: $mobile-media)
        {
            font-size: var(--extra-small );
            margin-right: auto;
        }
    }
}
