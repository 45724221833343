.CarouselItem
{
    width: 100%;
    padding: 2.4rem 3.9rem;

    border-right: 0.1rem solid var(--black);

    &-Date
    {
        font-size: var(--extra-small);

        margin-bottom: 1.5rem;

        color: var(--gray-550);
    }

    &-Text
    {
        color: var(--black);
        font-size: var(--additional-small);
        font-weight: 400;
        line-height: 1.6;
        text-decoration: none;

        overflow: hidden;

        height: 11rem;
        margin-bottom: 2rem;

        letter-spacing: 0.025rem;
    }

    &-ReadMore
    {
        font-size: var(--extra-small);
        font-weight: 400;

        position: relative;

        display: block;

        text-decoration: none;
        letter-spacing: 0.025rem;

        color: var(--gray-900);

        &::after
        {
            position: absolute;
            right: 0;

            width: 1.6rem;
            height: 1.6rem;

            content: '';

            background: no-repeat 50% 50% url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMTIiIHZpZXdCb3g9IjAgMCAxMiAxMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTAuMzU3MTQzIDEyVjEwLjVIOS40Mjg1N0wwIDEuMDcxNDNMMS4wNzE0MyAwTDEwLjUgOS40Mjg1N1YwLjI4NTcxNEgxMlYxMkgwLjM1NzE0M1oiIGZpbGw9IiM4RDhGOTIiLz48L3N2Zz4=);
        }

        &:hover
        {
            color: var(--black);

            &::after
            {
                background: no-repeat 50% 50% url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTciIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAxNyAxOCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTguMjUyNTMgMTcuMjMyN0w3LjE5MTg3IDE2LjE3MjFMMTMuNjA2MyA5Ljc1NzYxTDAuMjcyMzI0IDkuNzU3NjFWOC4yNDIzOUwxMy42MDYzIDguMjQyMzlMNy4xNDEzNiAxLjc3NzQxTDguMjAyMDIgMC43MTY3NDlMMTYuNDg1MyA5TDguMjUyNTMgMTcuMjMyN1oiIGZpbGw9ImJsYWNrIi8+PC9zdmc+);
            }
        }
    }
}
