.FromWindowView {
  display: flex;
  gap: 2rem;
  justify-content: space-between;

  &-Photo {
    display: block;
  }

  &-PhotoThumb {
    width: 100%;
  }
}