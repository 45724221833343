
:root {
    //Default Variables
    --font-size: 100%;
    --black: #000;
    --white: #fff;
    --red: #f00;


    //Color Variables
    --gray-25: #D9D9DE;
    --gray-50: #E8E8E8;
    --gray-75: #E2E2E2;
    --gray-100: #FEFEFF;
    --gray-125: #DBDBDB;
    --gray-150: #F3F3F3;
    --gray-200: #ECECEC;
    --gray-250: #C2C2C2;
    --gray-300: #CACACA;
    --gray-350: #B7B7B7;
    --gray-400: #999B9E;
    --gray-450: #A7A8AA;
    --gray-500: #727273;
    --gray-550: #76797A;
    --gray-600: #58595B;
    --gray-650: #7B7E7F;
    --gray-700: #595959;
    --gray-750: #959595;
    --gray-800: #898989;
    --gray-850: #555555;
    --gray-900: #8D8F92;
    --gray-950: #5F6062;
    --gray-1000: #636363;
    --gray-1050: #707075;
    --gray-1100: #5C5D60;
    --gray-1150: #888D93;
    --gray-1200: #222733;

    --green-200: #E6F6EF;
    --green-300: #00A655;
    --green-400: #00964D;
    --green-500:  #00A556;


    --red-500:  #FF0000;
    --orange-500:  #DB7521;

    //Font Variables
    --ultra-small: 1.2rem;
    --tiny: 1.3rem;
    --small: 1.4rem;
    --small2: 1.5rem;
    --extra-small: 1.6rem;
    --additional-small: 1.8rem;

    --extra-medium: 2rem;
    --medium: 2.4rem;
    --additional-medium: 2.6rem;
    --additional-medium-2: 2.8rem;
    --ultra-medium: 3.2rem;

    --ultra-large: 3.2rem;
    --tiny-large: 3.4rem;
    --sub-large: 3.6rem;
    --large: 4.2rem;
    --extra-large: 4.4rem;
    --extra-large-second: 4.6rem;

    --ultra-big: 5.0rem;
    --additional-big: 5.2rem;
    --large-big: 5.6rem;


    --small-big: 6.4rem;
    --big: 6.8rem;
    --extra-big: 6.8rem;

    --HeaderSliderTitle: 10rem;

    //Other Variables
    --transition-time: .3s;
    --transition-time-slow: .75s;
    --wid-project: 160rem;
    --max-wid-project: 160rem;

}

//Size Variables
$wid-project: 1120px;
$small-mobile-media: 374px;
$medium-mobile-media: 410px;
$mobile-media: 480px;
$landscape-mobile-media: 767px;
$tablet-media: 768px;
$desktop-media: 1024px;
$fix-size: 1120px;
$medium-desktop-media: 1200px;
$second-medium-desktop-media: 1407px;
$large-desktop-media: 1600px;
$large-desktop-media-second: 1664px;
$full-desktop-media: 1920px;

//@media (min-width: $mobile-media) {} //480
//@media (min-width: $tablet-media) {}  //768
//@media (min-width: $desktop-media) {} //1024
//@media (min-width: $fix-size) {} //1120
//@media (min-width: $medium-desktop-media) {} //1200
//@media (min-width: $second-medium-desktop-media) {} //1407
//@media (min-width: $large-desktop-media) {} //1600
//@media (min-width: $large-desktop-media-second) {} //1664
//@media (min-width: $full-desktop-media) {} //1920