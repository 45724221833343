.Favourites
{
    @include AdaptiveBlock;
    //width: 100%;
    //max-width: var(--wid-project);
    margin: 0 auto 4.8rem;

    &-Top
    {
        display: flex;

        margin-bottom: 4.8rem;

        align-items: center;
        justify-content: space-between;
    }

    &-Quantity
    {
        font-size: var(--extra-small);
        font-weight: 400;

        letter-spacing: 0.02rem;
    }
}
