.Radio {
    &_full-width {
        width: 100%;

        > span {
            width: 100%;
        }
    }
}

/* для элемента input c type="radio" */
.Radio > input
{
    position: absolute;
    z-index: -1;

    opacity: 0;
}

/* для элемента label связанного с .Radio */
.Radio > span
{
    font-size: var(--extra-small);
    font-weight: 400;

    position: relative;

    display: inline-flex;

    //min-width: 6.4rem;
    //height: 6.4rem;

    min-width: 4.8rem;
    height: 4.8rem;

    cursor: pointer;
    user-select: none;

    color: var(--gray-950);
    border-radius: 3.2rem;

    align-items: center;

    @media (min-width: $tablet-media) {
        min-width: 5.6rem;
        height: 5.6rem;
    }  //768

    @media (min-width: $desktop-media) {
        min-width: 6.4rem;
        height: 6.4rem;
    } //1024

}

.Radio_size_big > span {
    width: 15.4rem;

    @media screen and (max-width: $mobile-media) {
        width: auto;
    }
}

/* создание в label псевдоэлемента  before со следующими стилями */
.Radio > span::before
{
    position: absolute;

    display: inline-block;

    width: 100%;

    height: 4.8rem;
    margin-right: 0.5em;

    content: '';

    border: 0.1rem solid var(--gray-150);
    border-radius: 3.2rem;
    background: var(--gray-150);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 50% 50%;

    flex-shrink: 0;
    flex-grow: 0;

    @media (min-width: $tablet-media) {
        height: 5.6rem;
    }  //768

    @media (min-width: $desktop-media) {
        height: 6.4rem;
    } //1024

}

.Radio_white > span::before {
    background: var(--white);
}

.Radio > span > span
{
    z-index: 10;

    margin: auto;
    padding-right: 2rem;
    padding-left: 2rem;

    text-align: center;
}

/* стили при наведении курсора на радио */
.Radio > input:not(:disabled):not(:checked) + span:hover::before
{
    border-color: var(--gray-50);
    background: var(--gray-50);
}

/* стили для активной радиокнопки (при нажатии на неё) */
.Radio > input:not(:disabled):active + span::before
{
    //background-color: #b3d7ff;
    //border-color: #b3d7ff;
    border-color: var(--green-300);
    background-color: var(--green-300);
}

/* стили для радиокнопки, находящейся в фокусе */
.Radio > input:focus + span::before
{
    //box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

/* стили для радиокнопки, находящейся в фокусе и не находящейся в состоянии checked */
.Radio > input:focus:not(:checked) + span::before
{
    border-color: var(--gray-150);
}

.Radio > input:checked + span
{
    color: white;
}

/* стили для радиокнопки, находящейся в состоянии checked */
.Radio > input:checked + span::before
{
    border-color: var(--green-300);
    background-color: var(--green-300);

    //background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}

/* стили для радиокнопки, находящейся в состоянии disabled */
.Radio > input:disabled + span::before
{
    background-color: #e9ecef;
}
