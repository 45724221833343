$dot-weight: 100%;
$dot-height: 0.5rem;
$dot-max-weight: 30rem;

.HeaderSlider
{
    @media screen and (max-width: $fix-size) {
        margin-top: 5rem;
    }

    //margin-bottom: 6.4rem;slick-dots
    .slick-list
    {
        //height: 80vh;
    }

    &-Item
    {
        position: relative;

        overflow: hidden;

        height: 80vh;
        max-height: 120rem;

        @media screen and (max-width: $fix-size) {
            height: auto;
        }

        @media screen and (max-width: $mobile-media) {
            height: 42.5vw;
        }

        &::after
        {
            position: absolute;
            z-index: 100;
            bottom: 0;
            left: 0;

            width: 100%;
            height: 48.4rem;

            content: '';

            //background: linear-gradient(180deg, rgba(0, 0, 0, 0) 9.42%, rgba(0, 0, 0, 0.7) 100%);
        }
    }

    &-Img
    {
        width: 100%;
        height: 100%;

        object-fit: cover;
        object-position: center top;
    }

    &-Slick {
        &.slick-slider.slick-dotted {
            margin-bottom: 0;
            margin-top: 5.5rem;

            @media screen and (max-width: $desktop-media)
            {
                margin-top: 1.8rem;
            }
            &:hover {
                .slick-next
                {
                    opacity: 0.8;
                    background: rgba(255, 255, 255, 0.2) no-repeat 50% 50%  url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzMiIGhlaWdodD0iMzQiIHZpZXdCb3g9IjAgMCAzMyAzNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMS4wMDA0IDE2Ljk5OThMMzEgMTYuOTk5OE0zMSAxNi45OTk4TDE2LjAwMDIgMk0zMSAxNi45OTk4QzMxIDE2Ljk5OTggMjEuODU4IDI2LjE0MTggMTYuMDAwMiAzMS45OTk2IiBzdHJva2U9IndoaXRlIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJzcXVhcmUiLz48L3N2Zz4=);
                }

                .slick-prev
                {
                    opacity: 0.8;
                    background: rgba(255, 255, 255, 0.2) 50% 50% no-repeat url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzMiIGhlaWdodD0iMzQiIHZpZXdCb3g9IjAgMCAzMyAzNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMzEuOTk5NiAxNi45OTk4TDIgMTYuOTk5OE0yIDE2Ljk5OThMMTYuOTk5OCAyTTIgMTYuOTk5OEMyIDE2Ljk5OTggMTEuMTQyIDI2LjE0MTggMTYuOTk5OCAzMS45OTk2IiBzdHJva2U9IndoaXRlIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJzcXVhcmUiLz48L3N2Zz4=);
                }
            }
        }
    }

    &-Info
    {
        position: absolute;
        z-index: 200;
        bottom: 13.3rem;
        left: 50%;

        width: 100%;
        max-width: var(--max-wid-project);

        transform: translateX(-50%);

        @include AdaptiveBlock;

        @media screen and (max-width: $desktop-media)
        {
            bottom: 10rem;
        }

        @media screen and (max-width: $mobile-media)
        {
            bottom: 3.4rem;
        }

        @media screen and (max-width: $small-mobile-media)
        {
            bottom: 3.4rem;
        }
    }

    &-Title
    {
        font-weight: bold;

        color: var(--white);
    }

    &-SubTitle
    {
        //font-size: var(--extra-small);
        margin: 0 0 -0.3rem;

        letter-spacing: 0.06rem;
        text-transform: uppercase;

        @media (min-width: $tablet-media)
        {
            font-size: var(--additional-medium);
        }  //768
    }

    &-TitleName
    {
        font-size: var(--extra-large-second);

        margin-bottom: 2.2rem;

        text-indent: -0.5rem;
        letter-spacing: 0.02rem;

        @media (min-width: $mobile-media)
        {
            font-size: var(--small-big);
        }

        //480

        @media (min-width: $tablet-media)
        {
            font-size: var(--HeaderSliderTitle);
        }  //768
    }

    &-Button
    {
        text-align: right;

        &_left
        {
            text-align: left;
        }

        &_flex
        {
            display: flex;

            align-items: center;
            gap: 1.6rem;

            @media screen and (max-width: $desktop-media)
            {
                justify-content: flex-end;
            }

            @media screen and (max-width: $tablet-media)
            {
                justify-content: flex-start;
            }

            .Button
            {
                @media screen and (max-width: $desktop-media)
                {
                    padding: 1.6rem 3rem;
                }

                @media screen and (max-width: $landscape-mobile-media)
                {
                    font-size: var(--tiny);
                    padding: .6rem 1.1rem;
                    margin-bottom: 2rem;
                }
            }
        }
    }

    .slick-dots
    {
        bottom: 6.7rem;
        left: 50%;

        display: flex;

        max-width: var(--max-wid-project);

        transform: translateX(-50%);

        flex-wrap: nowrap;
        align-items: center;
        justify-content: center;
        gap: 0.7rem; //1024
        @include AdaptiveBlock;

        @media (min-width: $mobile-media)
        {
            bottom: 4.9rem;

            gap: 1.2rem;
        }

        @media (max-width: $small-mobile-media)
        {
            bottom: 3.2rem;
        }

        //480

        @media (min-width: $tablet-media)
        {
            gap: 1.5rem;
        }

        //768

        @media (min-width: $desktop-media)
        {
            gap: 2.5rem;
        }

        @media screen and (max-width: $fix-size) {
            bottom: 2.7rem;
        }

        li
        {
            width: $dot-weight;
            max-width: $dot-max-weight;
            height: $dot-height;
            margin: 0;

            &.slick-active button:before
            {
                opacity: 1;
            }

            button
            {
                width: $dot-weight;
                max-width: $dot-max-weight;
                height: $dot-height;
                padding: 0.5rem;

                &:hover,
                &:focus
                {
                    &:before
                    {
                        opacity: 1;
                    }
                }

                &:before
                {
                    width: $dot-weight;
                    max-width: $dot-max-weight;
                    height: $dot-height;

                    content: '';

                    opacity: 0.3;
                    border-radius: 0.5rem;
                    background: var(--white);
                }
            }
        }
    }

    .slick-prev,
    .slick-next
    {
        position: absolute;
        z-index: 100;
        top: 50%;
        //margin-left: -800px;
        //bottom: 13.6rem;
        left: 50%;

        width: 6.4rem;
        height: 6.4rem;

        transition: 0.3s opacity;
        transform: translateY(-50%);
        //top: 50%;
        //transform: translate(0, -50%);
        transform: translate(0, 0);

        opacity: 0;
        border: 0.1rem solid var(--white);
        border-radius: 6.4rem;

        @media screen and (max-width: $tablet-media)
        {
            top: 35%;

            opacity: 1;
        }

        @media screen and (max-width: $mobile-media)
        {
            display: none !important;
        }

        &.slick-disabled
        {
            opacity: 0.2;
        }

        &:before
        {
            position: absolute;
            top: -50vh;
            left: -100%;

            width: 300%;
            height: 100vh;

            content: '';

            background: none;

            @media screen and (max-width: $tablet-media)
            {
                display: none;
            }
        }
    }

    .slick-prev
    {
        right: auto;
        left: 3.2rem;

        margin-left: 0;

        background: 50% 50% no-repeat url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzMiIGhlaWdodD0iMzQiIHZpZXdCb3g9IjAgMCAzMyAzNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMzEuOTk5NiAxNi45OTk4TDIgMTYuOTk5OE0yIDE2Ljk5OThMMTYuOTk5OCAyTTIgMTYuOTk5OEMyIDE2Ljk5OTggMTEuMTQyIDI2LjE0MTggMTYuOTk5OCAzMS45OTk2IiBzdHJva2U9IndoaXRlIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJzcXVhcmUiLz48L3N2Zz4=);

        @media screen and (max-width: $tablet-media)
        {
            left: 2.4rem;
        }

        &:active
        {
            opacity: 1;
        }

        &.slick-disabled
        {
            &:hover,
            &:focus
            {
                opacity: 0.2;
                border: 10px solid #f00;
                background: 50% 50% no-repeat url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzMiIGhlaWdodD0iMzQiIHZpZXdCb3g9IjAgMCAzMyAzNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMzEuOTk5NiAxNi45OTk4TDIgMTYuOTk5OE0yIDE2Ljk5OThMMTYuOTk5OCAyTTIgMTYuOTk5OEMyIDE2Ljk5OTggMTEuMTQyIDI2LjE0MTggMTYuOTk5OCAzMS45OTk2IiBzdHJva2U9IndoaXRlIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJzcXVhcmUiLz48L3N2Zz4=);
            }
        }

        &:before
        {
            @media screen and (max-width: $full-desktop-media)
            {
                display: none;
            }
        }
    }

    .slick-next
    {
        right: 3.2rem;
        left: auto;

        margin-left: 0;

        background: no-repeat 50% 50%  url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzMiIGhlaWdodD0iMzQiIHZpZXdCb3g9IjAgMCAzMyAzNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMS4wMDA0IDE2Ljk5OThMMzEgMTYuOTk5OE0zMSAxNi45OTk4TDE2LjAwMDIgMk0zMSAxNi45OTk4QzMxIDE2Ljk5OTggMjEuODU4IDI2LjE0MTggMTYuMDAwMiAzMS45OTk2IiBzdHJva2U9IndoaXRlIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJzcXVhcmUiLz48L3N2Zz4=);

        @media screen and (max-width: $tablet-media)
        {
            right: 2.4rem;
        }

        &:active
        {
            opacity: 1;
        }

        &.slick-disabled
        {
            border: 10px solid #f00;

            &:hover,
            &:focus
            {
                opacity: 0.2;
                border: 10px solid #000;
                background: no-repeat 50% 50%  url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzMiIGhlaWdodD0iMzQiIHZpZXdCb3g9IjAgMCAzMyAzNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMS4wMDA0IDE2Ljk5OThMMzEgMTYuOTk5OE0zMSAxNi45OTk4TDE2LjAwMDIgMk0zMSAxNi45OTk4QzMxIDE2Ljk5OTggMjEuODU4IDI2LjE0MTggMTYuMDAwMiAzMS45OTk2IiBzdHJva2U9IndoaXRlIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJzcXVhcmUiLz48L3N2Zz4=);
            }
        }
    }

    /* .slick-dotted */
    .slick-slider
    {
        height: 80vh;
        max-height: 120rem;
        margin-bottom: 0;

        @media screen and (max-width: $fix-size) {
            height: auto;
        }
    }
}
