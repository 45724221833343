.ObjectCardList
{
    display: flex;

    flex-wrap: wrap;
    justify-content: center;
    gap: 3.2rem;

    @media (min-width: $large-desktop-media-second) {
        justify-content: flex-start;
    } //1664
}
