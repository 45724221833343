.Contacts
{
    display: flex;

    width: 100%;
    margin: 0 auto 1.9rem;

    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
    column-gap: 3.2rem;
    row-gap: 2.4rem;

    @include AdaptiveBlock;

    &-Title
    {
        font-size: var(--medium);
        font-weight: bold;
        line-height: 1.5;

        display: flex;

        padding: 1.8rem 2.4rem;

        cursor: pointer;
        letter-spacing: 0.055rem;

        align-items: center;
        justify-content: space-between;

        @media screen and (max-width: $mobile-media) {
            font-weight: 400;
            font-size: var( --extra-medium );
        }

        &::after
        {
            display: inline-block;

            width: 2.8rem;
            height: 2.8rem;

            content: '';
            //transition: transform var(--transition-time);
            transform: rotate(180deg);

            background: no-repeat 50% 50% url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjgiIGhlaWdodD0iMjgiIHZpZXdCb3g9IjAgMCAyOCAyOCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNNS44NzE1NiAxOS44MzVMNC4xMDE1NiAxOC4wNjVMMTQuMDAxNiA4LjE2NTA0TDIzLjkwMTYgMTguMDY1TDIyLjEzMTYgMTkuODM1TDE0LjAwMTYgMTEuNzA1TDUuODcxNTYgMTkuODM1WiIgZmlsbD0iYmxhY2siLz48L3N2Zz4=);
        }

        &_active
        {
            &::after
            {
                transform: rotate(0deg);
            }
        }
    }

    &-Content
    {
        display: none;

        padding: 3.2rem 1.6rem;

        border-top: 0.1rem solid var(--gray-300);

        flex-wrap: nowrap;
        align-items: flex-start;
        justify-content: space-between;
        row-gap: 3.2rem;
        column-gap: 1.4rem;

        &_active
        {
            display: flex;
        }

        @media screen and (max-width: $mobile-media) {
            flex-direction: column;
        }
    }

    &-Box
    {
        display: flex;
        flex-direction: column;
        //width: 50%;

        //flex-basis: 28.8rem;
        width: 100%;
        gap: 1.6rem;

        @media (min-width: $medium-desktop-media) {
            width: 100%;
        } //1200
    }

    &-List
    {
        display: flex;
        flex-direction: column;

        width: 100%;
        //max-width: 64.8rem;

        gap: 1.6rem;

        @media (min-width: $desktop-media) {
            width: calc(50% - 1.6rem);
        } //1024

        @media (min-width: $second-medium-desktop-media) {
            max-width: 64.8rem;
        } //1407

    }

    &-Item
    {
        background: var(--gray-150);
    }

    &-DepartmentTitle
    {
        font-size: var(--additional-small);
        font-weight: bold;

        letter-spacing: 0.01rem;
        text-transform: uppercase;

        color: var(--green-300);
    }

    &-InfoTip {
        width: 100%;
        margin-left: .4rem;
    }

    &-Info
    {
        font-size: var(--small);

        display: flex;
        //flex-direction: column;

        color: var(--gray-500);

        flex-wrap: wrap;
        gap: 0.2rem;

        &_worker-posit
        {
            gap: 1.6rem;
        }
    }

    &-Phone,
    &-Mail,
    &-Address,
    &-OpeningHours
    {
        font-size: var(--extra-small);
        line-height: 1.5;

        text-decoration: none;
        letter-spacing: 0.02rem;

        color: var(--black);
    }

    &-Address {
        width: calc(100% - 4rem);

        address
        {
            font-style: normal;
        }
    }

    &-Worker
    {
        display: flex;

        align-items: center;
        justify-content: flex-start;
        gap: .8rem;
    }

    &-WorkerName {
        padding-left: .5rem;
    }

    &-WorkerAvatar
    {
        display: flex;
        overflow: hidden;

        width: 4.8rem;
        height: 4.8rem;

        border-radius: 4.8rem;
        background: var(--gray-75);

        &::before
        {
            width: 2.4rem;
            height: 2.4rem;
            margin: auto;

            content: '';

            background: no-repeat 50% 50% url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTEyIDNDOS41MTM3NSAzIDcuNSA1LjAxMzc1IDcuNSA3LjVDNy41IDkuOTg2MjUgOS41MTM3NSAxMiAxMiAxMkMxNC40ODYyIDEyIDE2LjUgOS45ODYyNSAxNi41IDcuNUMxNi41IDUuMDEzNzUgMTQuNDg2MiAzIDEyIDNaTTE0LjM2MjYgNy40OTk3OUMxNC4zNjI2IDYuMTk0NzkgMTMuMzA1MSA1LjEzNzI5IDEyLjAwMDEgNS4xMzcyOUMxMC42OTUxIDUuMTM3MjkgOS42Mzc1NSA2LjE5NDc5IDkuNjM3NTUgNy40OTk3OUM5LjYzNzU1IDguODA0NzkgMTAuNjk1MSA5Ljg2MjI5IDEyLjAwMDEgOS44NjIyOUMxMy4zMDUxIDkuODYyMjkgMTQuMzYyNiA4LjgwNDc5IDE0LjM2MjYgNy40OTk3OVpNMTguODYyNiAxNy42MjQ4QzE4Ljg2MjYgMTYuOTA0OCAxNS4zNDEzIDE1LjI2MjMgMTIuMDAwMSAxNS4yNjIzQzguNjU4OCAxNS4yNjIzIDUuMTM3NTUgMTYuOTA0OCA1LjEzNzU1IDE3LjYyNDhWMTguODYyM0gxOC44NjI2VjE3LjYyNDhaTTMgMTcuNjI1QzMgMTQuNjMyNSA4Ljk5NjI1IDEzLjEyNSAxMiAxMy4xMjVDMTUuMDAzNyAxMy4xMjUgMjEgMTQuNjMyNSAyMSAxNy42MjVWMjFIM1YxNy42MjVaIiBmaWxsPSIjQTdBOEFBIi8+PC9zdmc+);
        }
    }

    &-Icon
    {
        width: 3.2rem;
        height: 2.2rem;

        @media screen and (max-width: $mobile-media) {
            margin-right: .5rem;
        }

        &::before
        {
            display: block;
            margin-left: auto;
            margin-right: auto;

            width: inherit;
            height: inherit;

            content: '';
        }

        &_mail
        {
            &::before
            {
                background: no-repeat 50% 50% url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjIiIGhlaWdodD0iMjIiIHZpZXdCb3g9IjAgMCAyMiAyMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMjEgNUMyMSAzLjkgMjAuMSAzIDE5IDNIM0MxLjkgMyAxIDMuOSAxIDVWMTdDMSAxOC4xIDEuOSAxOSAzIDE5SDE5QzIwLjEgMTkgMjEgMTguMSAyMSAxN1Y1Wk0xOSA1TDExIDkuOTlMMyA1SDE5Wk0xOSAxN0gzVjdMMTEgMTJMMTkgN1YxN1oiIGZpbGw9IiM3MjcyNzMiLz48L3N2Zz4=);
                background-size: contain;
            }
        }

        &_phone
        {
            &::before
            {
                background: no-repeat 50% 50% url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjIiIGhlaWdodD0iMjIiIHZpZXdCb3g9IjAgMCAyMiAyMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTE1IDBIN0M1LjM0IDAgNCAxLjM0IDQgM1YxOUM0IDIwLjY2IDUuMzQgMjIgNyAyMkgxNUMxNi42NiAyMiAxOCAyMC42NiAxOCAxOVYzQzE4IDEuMzQgMTYuNjYgMCAxNSAwWk0xMyAyMEg5VjE5SDEzVjIwWk02IDE3SDE2VjNINlYxN1oiIGZpbGw9IiM3MjcyNzMiLz48L3N2Zz4=);
            }
        }

        &_mark
        {
            &::before
            {
                background: no-repeat 50% 50% url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjIiIGhlaWdodD0iMjIiIHZpZXdCb3g9IjAgMCAyMiAyMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMTEgMTFDOS45IDExIDkgMTAuMSA5IDlDOSA3LjkgOS45IDcgMTEgN0MxMi4xIDcgMTMgNy45IDEzIDlDMTMgMTAuMSAxMi4xIDExIDExIDExWk0xNyA5LjJDMTcgNS41NyAxNC4zNSAzIDExIDNDNy42NSAzIDUgNS41NyA1IDkuMkM1IDExLjU0IDYuOTUgMTQuNjQgMTEgMTguMzRDMTUuMDUgMTQuNjQgMTcgMTEuNTQgMTcgOS4yWk0xMSAxQzE1LjIgMSAxOSA0LjIyIDE5IDkuMkMxOSAxMi41MiAxNi4zMyAxNi40NSAxMSAyMUM1LjY3IDE2LjQ1IDMgMTIuNTIgMyA5LjJDMyA0LjIyIDYuOCAxIDExIDFaIiBmaWxsPSIjNzI3MjczIi8+PC9zdmc+);
            }
        }

        &_opening-hours
        {
            &::before
            {
                width: 2.4rem;
                height: 2.4rem;

                background: no-repeat 50% 50% url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjIiIGhlaWdodD0iMjIiIHZpZXdCb3g9IjAgMCAyMiAyMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMTAuOTkgMUM1LjQ3IDEgMSA1LjQ4IDEgMTFDMSAxNi41MiA1LjQ3IDIxIDEwLjk5IDIxQzE2LjUyIDIxIDIxIDE2LjUyIDIxIDExQzIxIDUuNDggMTYuNTIgMSAxMC45OSAxWk0xMSAxOUM2LjU4IDE5IDMgMTUuNDIgMyAxMUMzIDYuNTggNi41OCAzIDExIDNDMTUuNDIgMyAxOSA2LjU4IDE5IDExQzE5IDE1LjQyIDE1LjQyIDE5IDExIDE5Wk0xMS41IDZIMTBWMTJMMTUuMjUgMTUuMTVMMTYgMTMuOTJMMTEuNSAxMS4yNVY2WiIgZmlsbD0iIzcyNzI3MyIvPjwvc3ZnPg==);
            }
        }
    }

    &-Map
    {
        //margin-left: 3.2rem;
        width: 100%;

        overflow: hidden;
        border-radius: 1.6rem;

        @media (min-width: $desktop-media) {
            width: calc(50% - 1.6rem);
        } //1024

        @media (min-width: $second-medium-desktop-media) {
            width: calc(100% - 68rem);
        } //1407

        #contactMap {
            width: 100%;
            height: 776px;

            @media screen and (max-width: $mobile-media) {
                max-height: 53vh;
            }
        }
    }

    .b-nowrap
    {
        flex-wrap: nowrap;
    }

    &-WorkerInfo
    {
        display: flex;
        flex-direction: column;
        gap: 1.6rem;
    }
}
