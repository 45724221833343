.Menu
{
    display: none;
    flex-direction: column;
    flex-wrap: wrap;
    padding-top: 3.2rem;
    padding-bottom: 3.2rem;

    @media (min-width: $second-medium-desktop-media) {
        padding-top: 0;
        padding-bottom: 0;
    } //1407

    gap: 0;

    @media (min-width: $desktop-media) {
        flex-direction: row;
        gap: 2.4rem;
    } //1024

    &_open {
        display: flex;
        position: fixed;
        bottom: 7.2rem;
        left: 0;
        right: 0;
        padding-bottom: 1.6rem;

        background: var(--white);
        box-shadow: 0 2.8rem 1.6rem -2rem rgba(97, 97, 97, 0.1) inset, 0 3.6rem 3.2rem -2rem rgba(97, 97, 97, 0.1) inset;

        @media (max-width: $tablet-media)
        {
            box-shadow: 0px -8px 45px -17px rgba(97, 97, 97, 0.1);
        }  //768

        @include AdaptiveBlock;

        .Menu-Item {
            padding: .8em 0;

            &:hover {
                border-bottom: 0;
                padding: .8em 0;
            }
        }
    }

    @media (min-width: $second-medium-desktop-media) {
        display: flex;
    } //1407


    &-Arrow {

        display: flex;
        align-items: center;
        //justify-content: flex-end;
        gap: .6rem;

        &::after {
            cursor: pointer;
            content: '';
            display: inline-block;
            width: 2.4rem;
            height: 2.4rem;
            background: no-repeat 50% 0 url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNNC4yODExNiA4LjMzNjUxTDEyLjM5NCAxNi40NTA0TDIwLjUwNzggOC4zMzY0MyIgc3Ryb2tlPSJibGFjayIgc3Ryb2tlLXdpZHRoPSIyLjUiLz48L3N2Zz4=);
            background-size: cover;
            transform: rotate(-90deg);

            @media (min-width: $desktop-media) {
                display: none;
            } //1024

        }

    }

    &-Button {
        display: inline-block;
        width: 4rem;
        height: 4rem;
        border: 0.1rem solid var(--black);
        border-radius: 50%;
        background: no-repeat 50% 50% url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cmVjdCB4PSIyIiB5PSI1IiB3aWR0aD0iMjAiIGhlaWdodD0iMiIgZmlsbD0iYmxhY2siLz48cmVjdCB4PSIyIiB5PSIxMSIgd2lkdGg9IjIwIiBoZWlnaHQ9IjIiIGZpbGw9ImJsYWNrIi8+PHJlY3QgeD0iMiIgeT0iMTciIHdpZHRoPSIyMCIgaGVpZ2h0PSIyIiBmaWxsPSJibGFjayIvPjwvc3ZnPg==);

        @media (min-width: $second-medium-desktop-media) {
            display: none;
        } //1407

        @media screen and (max-width: $small-mobile-media)
        {
            width: 3.5rem;
            height: 3.5rem;
            align-self: flex-end;
        }

        &_active {
            background: var(--black) no-repeat 50% 50% url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMTAuNTQ1NSAxMi4wMDAxTDQuMDAwMTYgMTguNTQ1NEw1LjQ1NDY5IDIwTDEyIDEzLjQ1NDZMMTguNTQ1MyAyMEwxOS45OTk4IDE4LjU0NTVMMTMuNDU0NSAxMi4wMDAxTDIwIDUuNDU0NTRMMTguNTQ1NSA0TDEyIDEwLjU0NTVMNS40NTQ1MyA0LjAwMDAyTDQgNS40NTQ1NkwxMC41NDU1IDEyLjAwMDFaIiBmaWxsPSJ3aGl0ZSIvPjwvc3ZnPg==);
        }

    }

    &-Item
    {
        margin: 0;
        padding: 2.1rem 0;

        list-style: none;

        letter-spacing: 0.03rem;

        @media (min-width: $desktop-media) {
            max-width: 24rem;
            width: 100%;
        } //1024

        @media (min-width: $second-medium-desktop-media) {
            width: auto;
            max-width: none;
        } //1407

        &:hover
        {
            padding: 2.1rem 0 1.6rem;

            border-bottom: 0.5rem solid var(--black);

            @media (min-width: $second-medium-desktop-media) {

                .Menu-SubMenu
                {
                    //visibility: visible;
                    //
                    //opacity: 1;
                    display: block;
                    padding-bottom: 2rem;
                    max-height: calc(100vh - 6rem);
                    overflow-y: scroll;
                }

            } //1407

        }

        &_active {

            .Menu-Arrow {

                &::after {
                    transform: rotate(0deg);
                }

            }

        }

        &_hide-mobile {
            @media (max-width: $landscape-mobile-media) {
                display: none;
            } //1407
        }

        &_no-mobile-sub {
            .Menu-Arrow {
                &:after {
                    display: none;
                }
            }

            .Menu-SubMenu {
                display: none;
            }
        }

        &:not(:has(.Menu-SubMenu)) .Menu-Arrow {
            &::after {
                display: none;
            }
        }
    }

    &-Link
    {
        text-decoration: none;
        font-size: var(--extra-small);
        font-weight: bold;
        line-height: 2.4rem;

        text-transform: uppercase;

        color: var(--black);

        &[href="#"] {
            cursor: default;
        }

        @media (max-width: $landscape-mobile-media) {
            font-weight: normal;
        } //1407
        @media (min-width: $second-medium-desktop-media) {
            font-size: var(--extra-small);
            font-weight: 400;
            font-style: normal;
            line-height: 1.6;
            text-transform: none;
        } //1407

    }

    &-SubMenu
    {
        //opacity: 0;
        //visibility: hidden;
        display: none;

        @media (min-width: $desktop-media) {
            //visibility: visible;
            //opacity: 1;
            display: block;
        } //1024

        @media (min-width: $second-medium-desktop-media) {
            //opacity: 0;
            //visibility: hidden;
            display: none;

            position: absolute;
            z-index: 999;
            left: 0;

            width: 100%;
            margin-top: 2rem;

            transition: visibility 0s, opacity var(--transition-time) linear;


            background-color: var(--white);
            box-shadow: 0 2.8rem 1.6rem -2rem rgba(97, 97, 97, 0.1) inset, 0 3.6rem 3.2rem -2rem rgba(97, 97, 97, 0.1) inset;


            padding-right: 3.2rem;
            padding-left: 3.2rem;


            @media (min-width: $large-desktop-media-second) {
                padding-left: 0;
                padding-right: 0;
            } //1664
        } //1407

        &_open {
            //height: 100%;
            //visibility: visible;
            //opacity: 1;
            display: block;

            @media (min-width: $second-medium-desktop-media) {
                position: relative;
                box-shadow: 0 0 0 0 rgba(97, 97, 97, 0) inset, 0 0 0 0 rgba(97, 97, 97, 0) inset;
            } //1407


            .Menu-SubMenuWrap {
                flex-direction: column;
                //text-align: right;


            }

        }

    }

    &-SubMenuWrap
    {
        display: flex;
        flex-direction: column;

        max-width: var(--max-wid-project);

        align-items: flex-start;

        @media (min-width: $second-medium-desktop-media) {
            flex-direction: row;
            margin: 3.2rem auto;
            row-gap: 3.2rem;
        } //1407

    }

    &-SubMenuContainer
    {
        display: flex;
        flex-direction: column;

        width: 100%;
        max-width: 35rem;

        list-style: none;

        row-gap: 1rem;
        align-content: start;

        &_closed {
            max-height: 16rem;
            overflow: hidden;
        }
    }

    &-SubMenuShowAll {
        position: absolute;
        bottom: .5rem;
        font-size: 1.4rem;
        padding: .6rem 1.5rem;
        /* border-top: 1px solid var(--gray-125); */
/*         padding: 0 1.2rem; */
        margin: 1rem 0 1rem -1.5rem;
        /* color: var(--gray-350); */
        /* cursor: pointer; */

        &:before {
            content: 'Все объекты';
        }

        &_expanded {
            position: static;
            margin-bottom: 0;
            display: inline-block;
            width: fit-content;

            &:before {
                content: 'Скрыть';
            }
        }
    }

    &-SubMenuItem
    {
        display: none;

        &_showed {
            display: block;
        }

        @media (min-width: $second-medium-desktop-media) {
            display: block;
        } //1407

        &:first-child
        {
            margin-bottom: 0.4rem;

            .Menu-Link
            {
                font-size: var(--extra-small);
                font-weight: 400;
                line-height: 1.6;

                text-decoration: none;
                text-transform: none;

                //color: var(--black);
                color: var(--gray-500);

                @media (min-width: $second-medium-desktop-media) {
                    font-weight: bold;
                    line-height: 2.4rem;

                    text-transform: uppercase;

                    color: var(--black);
                } //1407

                &:hover {
                    background: none;
                }
            }
        }

        &_all-menu
        {
            display: block;

            &:first-child {
                display: none;
                @media (min-width: $second-medium-desktop-media) {
                    display: block;
                } //1407
            }
        }

        .Menu-Link
        {
            display: block;
            width: 100%;
            font-size: var(--small);
            font-weight: 400;
            line-height: 2.2rem;

            text-decoration: none;
            text-transform: none;

            color: var(--gray-500);
            padding-left: .8rem;
            margin-left: -.8rem;

            &:hover {
                background-color: var(--gray-200);
            }

        }

    }
}
