.Pagination
{
    &-List
    {
        display: flex;

        margin: 0;
        padding: 0;

        list-style: none;

        align-items: center;
        justify-content: center;
        gap: 0.8rem;
    }

    &-Link
    {
        font-size: var(--extra-small);
        font-weight: 400;
        line-height: 4.8rem;

        display: inline-block;

        width: 4.8rem;
        height: 4.8rem;

        transition: color var(--transition-time), background-color var(--transition-time);
        text-align: center;
        text-decoration: none;
        letter-spacing: 0.05rem;

        color: var(--gray-950);
        border-radius: 4.8rem;
        //display: flex;
        //align-items: center;
        //justify-content: center;
        background: var(--gray-150);

        &.active
        {
            color: var(--white);
            background: var(--green-300);
        }

        &:hover {
            @media screen and (min-width: $tablet-media)
            {
                color: var(--white);
                background: var(--green-300);
            }
        }
    }
}
