.BuyOnline
{
    padding-bottom: 0;

    &-Big
    {
        min-width: 88rem;
        padding: 3.2rem 4.8rem 4.8rem;
    }

    &-Small
    {
        max-width: 57.6rem;
        padding: 3.2rem 4.8rem 4.8rem;
    }

    &-Form
    {
        display: flex;

        margin-bottom: 1.2rem;

        gap: 2.4rem;
    }

    &-Row
    {
        width: 50%;
        min-height: 42.6rem;
        margin-top: 0;
    }

    &-FormItem
    {
        margin-bottom: 2.4rem;
    }

    &-Wrapper
    {
        max-width: 88rem;
        padding-bottom: 0;
    }

    .step
    {
        display: none;
    }
}
