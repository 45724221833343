.HeaderSearch 
{
    display: none;
    max-width: 76.5rem;
    width: 100%;
    margin: 10px auto;
    background: #f3f3f3;
    border-radius: 24px;
    
    form {
        display: flex;
        width: 100%;
    }

    input {
        width: 100%;
        border: none;
        background: transparent;
        border-radius: 24px;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.02em;
        color: #8D8F92;
        margin: 12px 20px 12px 20px;
        padding: 0 5px;

        &:focus 
        { 
            outline: none;
        }
        &:active 
        { 
            outline: none;
        }
    }

    button {
        background: #00A655;
        border-radius: 20px;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.02em;
        color: #FFFFFF;
        flex: none;
        order: 0;
        flex-grow: 0;
        border: none;
        padding: 8px 24px;
        margin: 4px;
        cursor: pointer;
    }
}