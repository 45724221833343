.UploadFile
{
    max-width: 51.2rem;

    &-Label
    {
        position: relative;

        span
        {
            font-size: var(--extra-small);
            font-weight: bold;

            display: flex;

            width: 100%;
            padding: 2rem 2.4rem;

            cursor: pointer;
            letter-spacing: 0.02rem;

            color: var(--green-300);
            border: 0.2rem solid var(--green-300);
            border-radius: 3.2rem;
            outline: none;
            background: var(--green-200);

            align-items: center;
            justify-content: center;
            gap: 0.8rem;

            &::before
            {
                width: 2.4rem;
                height: 2.4rem;

                content: '';

                background: no-repeat 50% 50% url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjUiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNSAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTE0Ljg3NSAwSDUuMzc1QzQuMDY4NzUgMCAzIDEuMDggMyAyLjRWMjEuNkMzIDIyLjkyIDQuMDU2ODggMjQgNS4zNjMxMyAyNEgxOS42MjVDMjAuOTMxMiAyNCAyMiAyMi45MiAyMiAyMS42VjcuMkwxNC44NzUgMFpNMTcuMjUgMTYuOEgxMy42ODc1VjIwLjRIMTEuMzEyNVYxNi44SDcuNzVWMTQuNEgxMS4zMTI1VjEwLjhIMTMuNjg3NVYxNC40SDE3LjI1VjE2LjhaTTEzLjY4NzUgMS44VjguNEgyMC4yMTg4TDEzLjY4NzUgMS44WiIgZmlsbD0iIzAwQTY1NSIvPjwvc3ZnPg==);
            }
        }

        input[type=file]
        {
            position: absolute;
            z-index: -1;

            display: block;

            width: 0;
            height: 0;

            opacity: 0;
        }

        /* Focus */
        input[type=file]:focus + span
        {
            //box-shadow: 0 0 0 0.2rem rgba(0,123,255,.25);
        }

        /* Hover/Active */
        &:hover span
        {
            //background-color: #59be6e;
        }

        &:active span
        {
            //background-color: #2E703A;
        }

        /* Disabled */
        input[type=file]:disabled + span
        {
            background-color: #eee;
        }
    }

    /* Список файлов */
    &-List
    {
        font-size: var(--small);
        font-weight: 400;

        padding-top: 1.6rem;

        letter-spacing: 0.025em;

        color: var(--gray-950);
    }

    &-Item
    {
        display: flex;

        padding-bottom: 1.2rem;

        align-items: center;
        justify-content: space-between;
    }

    &-ItemRemove
    {
        display: inline-block;
        cursor: pointer;

        width: 2.4rem;
        height: 2.4rem;

        background: no-repeat 50% 50% url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMTkgNi40MUwxNy41OSA1TDEyIDEwLjU5TDYuNDEgNUw1IDYuNDFMMTAuNTkgMTJMNSAxNy41OUw2LjQxIDE5TDEyIDEzLjQxTDE3LjU5IDE5TDE5IDE3LjU5TDEzLjQxIDEyTDE5IDYuNDFaIiBmaWxsPSIjNUY2MDYyIi8+PC9zdmc+);
    }
}
