.Politics
{
    width: 100%;
    max-width: var(--wid-project);
    margin: 0 auto 4.8rem;

    &-MainTitle
    {
        font-size: var(--medium);
        font-weight: 400;
        line-height: 1.3;
    }

    &-MainList
    {
        ul > li,
        ol > li
        {
            font-size: var(--extra-small);
            font-weight: 400;
            line-height: 1.5;
        }

        p
        {
            font-size: var(--extra-small);
            font-weight: 400;
            line-height: 1.5;
        }
    }

    &-Sublist
    {
        font-size: var(--extra-small);
        font-weight: 400;
        line-height: 1.5;
    }

    &-Text
    {
        font-size: var(--extra-small);
        font-weight: 400;
        line-height: 1.5;
    }

    &-MarkedList
    {
        margin: 0;
    }

    .NumberedList-Item
    {
        display: block;

        margin-bottom: 2.4rem;
    }

    &-Title
    {
        margin-bottom: 2.4rem;
    }

    &-Item
    {
        margin-top: 2.4rem;
    }
}
