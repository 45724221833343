.AwardsModal
{
    padding: 3.2rem 4.8rem 4.8rem;
    overflow: auto;

    &::-webkit-scrollbar {
      width: .9rem;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgba(155, 155, 155, 0.5);
      border-radius: 1.4rem;
      border: transparent;
    }

    .Modal
    {

        &-Window
        {
            display: flex;
            overflow: hidden;
            flex-direction: column;

            width: 100%;
            max-width: 109rem;
            //margin: 5rem auto;

            border-radius: 1.6rem;
        }
    }

    &-Text
    {
        font-size: var( --extra-small );
        line-height: 1.5;

        padding-bottom: 3.2rem;

        ul,
        ol {
            margin-top:  var( --extra-small );
            margin-left: var( --ultra-medium );

            li {
                margin-bottom: 1.3rem;
            }
        }
    }

    &-Images
    {
        display: flex;

        flex-wrap: wrap;
        column-gap: 1.4rem;
        justify-content: flex-start;
        row-gap: 1.4rem;
    }

    &-Picture
    {
        flex: 0 0 23.9%;
    }

    &-Image
    {
        display: block;

        max-width: 100%;
    }
}