.StorePlan
{
    display: none;
}

.Floor,
.FloorPlan,
.Parking,
.ParkingPlan,
.Store,
.StorePlan
{
    position: relative;

    width: 100%;
    max-width: var(--wid-project);
    margin: 0 auto;
    padding-top: 3.6rem;

    &-Top
    {
        position: absolute;
        top: -8.2rem;
        left: 1.6rem;

        display: flex;

        align-items: center;
        justify-content: flex-start;
        gap: 3.2rem;

        @media screen and (max-width: $mobile-media) {
            left: 2.4rem;
        }

        @media screen and (max-width: $tablet-media) {
            left: 2.4rem;
        }

        @media screen and (min-width: $large-desktop-media-second) {
            left: 0;
        }
    }

    &-Back
    {
        transform: rotate(180deg);
    }

    &-Title
    {
        font-size: var(--ultra-large);
        font-weight: bold;

        letter-spacing: 0.06rem;
    }

    &-ChoiceWrap
    {
        padding: 0 0 18rem;
        position: relative;

        @media screen and (max-width: $second-medium-desktop-media) {
            padding-left: 3rem;
            padding-right: 3rem;
        }

        @media screen and (max-width: $fix-size) {
            padding-top: 0;
            padding-bottom: 0;
        }        

        &_noIndent {
            padding: 0;
        }
    }

    &-Choice
    {
        position: relative;

        margin: 0 auto;

        &_floor
        {
            img
            {
                display: block;

                width: 100%;
                max-width: fit-content;
                margin: 0 auto;
            }

            svg
            {
                position: absolute;
                top: 0;

                margin: 0 auto;
            }
        }

        &_floor-2
        {
            img
            {
                display: block;

                width: 100%;
                max-width: fit-content;
                margin: 0 auto;
            }

            svg
            {
                position: absolute;
                top: 0;

                width: 100%;
                height: 100%;
            }
        }

        &-Svg
        {
            position: absolute;

            width: 100%;
            height: 100%;

            inset: 0;
        }
    }

    &-Link
    {
        &_purchased,
        &_reservation
        {
            cursor: default;
        }
    }

    &-ChoiceObject
    {
        //stroke-width: .2rem;
        //stroke: var(--green-300);
        fill: transparent;

        &:hover, &_hovered
        {
            fill: var(--green-300);
            fill-opacity: 0.5;
            stroke-opacity: 0.5;
        }

        &_sold
        {
            fill: var(--red-500);
            fill-opacity: 0.5;
            stroke-opacity: 0.5;

            &:hover
            {
                fill: var(--red-500);
                fill-opacity: 0.5;
                stroke-opacity: 0.5;
            }
        }
        &_booking {

            fill: var(--white);
            fill-opacity: 0.8;
            stroke-opacity: 0.5;

            &:hover {
                fill: var(--white);
                fill-opacity: 0.8;
                stroke-opacity: 0.5;
            }

        }
    }
}

.FloorPlan
{
    padding-top: 0;
}

.zamok
{
    position: absolute;
    top: 50%;
    left: 50%;

    border: 10px solid #f00;
    background: red;
}


//Admin styles
.ea {
    .FloorPlan {
        padding-top: 0px;

        &-Title {
            font-size: 32px;
        }

        &-ChoiceWrap{
            padding: 0 24px;
        }
    }
    .Floor {
        padding-top: 0;

        &-Title {
            font-size: 32px;
        }

        &-ChoiceWrap{
            padding: 0 24px;
        }
    }
}