.Purchase
{
    position: relative;

    min-height: 52.2rem;
    margin-bottom: 6.4rem;
    padding-bottom: 6.4rem;

    border-bottom: 0.2rem solid var(--black);

    &-Pos
    {
        width: 100%;
        max-width: var(--wid-project);
        margin: 0 auto;
    }

    &-Calc
    {
        display: flex;
        flex-direction: column;
        height: 100%;

        //gap: 3.2rem;
        justify-content: space-between;


        row-gap: 6.4rem;
        column-gap: 6rem;

        @media (min-width: $second-medium-desktop-media) {
            flex-direction: row;
            justify-content: space-between;
        } //1407





    }

    &-CalcLeft,
    &-CalcRight
    {
        display: flex;
        flex-direction: column;

        width: 100%;
        max-width: 48rem;
        height: 100%;

        justify-content: space-between;

        &_no-width {
            max-width: none;
        }
    }

    &-Calculation,
    &-CalcTotal
    {
        margin-bottom: 2.8rem;
    }

    &-Pole
    {
        margin-bottom: 3rem;

        &_indent
        {
            margin-bottom: 3.6rem;
        }
    }

    &-PoleTile
    {
        font-size: var(--small);
        font-weight: 400;

        margin-bottom: 0.6rem;

        letter-spacing: 0.02rem;

        color: var(--gray-450);

        &_indent
        {
            margin-bottom: 1.2rem;
        }
    }

    &-MonthlyPayment,
    &-Bet
    {
        font-size: var(--large);
        font-weight: 400;

        letter-spacing: 0.025rem;

        color: var(--black);
    }

    &-Bootom
    {
        //position: relative;
    }

    &-Info
    {
        font-size: var(--extra-small);
        line-height: 1.5;
        //margin-top: -9rem;

        position: absolute;
        bottom: 9.8rem;

        max-width: 51.2rem;
        padding: 0 2.4rem;

        letter-spacing: 0.025rem;

        color: var(--gray-800);
    }

    &-Disclaimer {
        font-size: var( --additional-small );
    }
}
