.NewsList
{

    @include AdaptiveBlock;

    display: flex;

    margin-bottom: 3.2rem;
    justify-content: center;

    flex-wrap: wrap;
    gap: 3.2rem;

    @media (min-width: $large-desktop-media-second) {
        justify-content: flex-start;
    } //1664
}
