.Footnote
{
    font-size: var(--small);
    line-height: 1.4;

    width: 100%;
    max-width: var(--wid-project);
    margin: 0 auto;
    padding: 0.7rem 0 3.2rem;

    letter-spacing: 0.02rem;

    flex: 1 0 auto;

    sup
    {
        display: inline-block;

        margin-right: 0.4rem;
        padding-bottom: 0.4rem;
    }
}
