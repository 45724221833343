
.Mortgage
{
    //width: 100%;
    //max-width: var(--wid-project);
    //margin-right: auto;
    //margin-left: auto;
    padding-top: 6.5rem;

    @include AdaptiveBlock;

    @media screen and (max-width: $mobile-media) {
        padding-top: 4.2rem;
    }

    &-Text
    {
        font-size: var(--extra-small);
        line-height: 1.5;

        margin-bottom: 5rem;

        letter-spacing: 0.02rem;

        &-Block
        {
            font-size: var( --extra-small );
            line-height: 1.4;

            margin-bottom: var( --medium );

            p
            {
                margin-top: 2.2rem;
            }
        }
    }

    &-Banks
    {
        margin-top: 5rem;
        margin-bottom: 5rem;

        @media screen and (max-width: $mobile-media)
        {
            display: none;
        }

        .GridContent-Cell
        {
            padding-bottom: 4.8rem;

            &_title
            {
                &-large
                {
                    font-size: var( --medium );
                }
            }
        }

        .Button
        {
            display: block;

            width: 21rem;
            margin-top: 2.8rem;
        }
    }

    &Calc
    {
        padding-bottom: 12rem;

        @include AdaptiveBlock;

        &-Content,
        &-Title
        {
            font-size: var( --large );

            width: 100%;
            max-width: var(--wid-project );
            margin-right: auto;
            margin-left: auto;

            text-align: left;
        }

        &-Content
        {
            margin-top: 5rem;
        }

        &-Title
        {
            font-weight: 400;
            line-height: 1.6;

            display: inline-block;

            letter-spacing: 0.13rem;

            @include AdaptiveBlock;

            @media screen and (max-width: $mobile-media)
            {
                font-size: var(--medium);
                line-height: 1.2;

                padding-bottom: 0.9rem;
            }

            &-Wrap
            {
                margin-top: 0.5rem;
                padding-top: 1rem;

                text-align: center;

                border-bottom: 0.2rem solid var( --black );
            }
        }

        .Purchase
        {
            &-Calculation
            {
                display: flex;
                flex-direction: column;

                justify-content: space-between;
                align-items: flex-start;
                flex-wrap: wrap;

                @media (min-width: $tablet-media)
                {
                    flex-direction: row;

                    column-gap: 2.4rem;
                }

                //768

                @media (min-width: $desktop-media)
                {
                    column-gap: 3.2rem;
                }

                //1024

                @media (min-width: $second-medium-desktop-media)
                {
                    flex-direction: column;
                }

                //1407

                .Filters-Title
                {
                    margin-bottom: 1rem;
                }
            }

            &-Pole
            {
                width: 100%;

                @media (min-width: $tablet-media)
                {
                    width: calc(50% - 1.6rem);
                }

                //768

                @media (min-width: $second-medium-desktop-media)
                {
                    width: 100%;
                } //1407
            }
        }

        .Purchase-Calc
        {
            column-gap: 5rem;
            justify-content: flex-start;

            &Left
            {
                max-width: 100%;

                @media (min-width: $second-medium-desktop-media)
                {
                    max-width: 37.6rem;
                } //1407
            }

            .Purchase-MonthlyPayment
            {
                letter-spacing: 0.11rem;
            }
        }
    }
}
