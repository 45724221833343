.FinishedAbout
{
    width: 100%;
    /* max-width: var(--wid-project); */
    margin: 0 auto 0;
    padding-top: 6.4rem;

    @media screen and (max-width: $desktop-media)
    {
        padding-top: 0;
    }

    .MapOnReadyContainer
    {
        display: none;

        width: 100%;
        min-height: 75vh;
        margin: -4.6rem 0 4.6rem;

        @media screen and (max-width: $large-desktop-media-second)
        {
            min-height: 63vh;
        }
        
        @media screen and (max-width: $mobile-media)
        {
            margin-top: -2.2rem;
        }

        ymaps[class*='balloon__content']
        {
            margin: 0;
            padding: 0;
        }

        &-Close
        {
            font-size: var(--extra-small);

            position: absolute;
            z-index: 9998;

            display: block;

            margin-top: 5%;
            right: 2rem;
            /* margin-left: 134rem; */
            padding: 1.2rem 5.8rem 1.2rem 2.4rem;

            cursor: pointer;
            letter-spacing: 0.02rem;

            color: var(--white);
            border: 0;
            border-radius: 24px;
            background-color: var(--green-300);
            background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEwLjU0NTUgMTIuMDAwMUw0LjAwMDE2IDE4LjU0NTRMNS40NTQ2OSAyMEwxMiAxMy40NTQ2TDE4LjU0NTMgMjBMMTkuOTk5OCAxOC41NDU1TDEzLjQ1NDUgMTIuMDAwMUwyMCA1LjQ1NDU0TDE4LjU0NTUgNEwxMiAxMC41NDU1TDUuNDU0NTMgNC4wMDAwMkw0IDUuNDU0NTZMMTAuNTQ1NSAxMi4wMDAxWiIgZmlsbD0id2hpdGUiLz4KPC9zdmc+Cg==);
            background-repeat: no-repeat;
            background-position: 90% 50% ;
            box-shadow: 0 0 16px 2px rgba(97, 97, 97, 0.03), 0 6px 40px rgba(97, 97, 97, 0.25);

            appearance: none;

            @media screen and (max-width: $tablet-media) {
                margin-left: auto;
                margin-right: 0;
                right: 1rem;
                margin-top: 5rem;        
            }
        }
    }

    .MapPopup
    {
        img
        {
            width: 100%;
            /* 37.6rem; */
        }

        .MapPopupPlacemark
        {
            &-Number
            {
                color: var(--white);
            }
        }

        .MapPopupTitle,
        .MapPopupAddress,
        .MapPopupTerm,
        .MapPopupPrice
        {
            font-weight: 400;
            font-style: normal;

            margin-right: 2.4rem;
            margin-left: 2.4rem;
        }

        .MapPopupTitle
        {
            font-size: var(--medium);
            font-weight: bold;
            line-height: 3.2rem;

            margin-top: 1.6rem;

            letter-spacing: 0.01rem;
        }

        .MapPopupAddress
        {
            font-size: var(--extra-small);
            line-height: 2.4rem;

            margin-top: 1.2rem;

            letter-spacing: 0.01rem;
        }

        .MapPopupTerm
        {
            font-size: var(--extra-small);
            line-height: 2.4rem;

            letter-spacing: 0.01em;

            color: var(--gray-1100);
        }

        .MapPopupPrice
        {
            font-size: var(--additional-small);
            font-weight: bold;
            line-height: 2.6rem;

            margin-top: 1.6rem;
            margin-bottom: 2.4rem;

            letter-spacing: 0.01rem;
        }
    }

    a
    {
        text-decoration: none;
    }

    &-Header
    {
        font-size: var(--large);
        font-weight: 400;
        line-height: 1.1;

        display: flex;

        margin-bottom: 4.8rem;
        padding-bottom: 1.2rem;

        border-bottom: 2px solid var(--black);

        @media (min-width: $tablet-media)
        {
            padding-right: 2.4rem;
            padding-left: 2.4rem;
        }

        @media (min-width: $desktop-media)
        {
            padding-right: 3.2rem;
            padding-left: 3.2rem;
        }

        @media (min-width: $large-desktop-media-second)
        {
            padding-right: calc(( 100% - var(--wid-project)) / 2 );
            padding-left: calc(( 100% - var(--wid-project)) / 2 );
        }

        //1664

        .Button
        {
            line-height: 2rem;

            margin-left: 2.4rem;
        }
    }

    h2
    {
        font-size: var(--medium);
        font-weight: 400;
        line-height: 1.3;

        margin-top: 8rem;
        margin-bottom: 0;

        @media screen and (max-width: $tablet-media) {
            margin-top: 1.5rem;
        }
    }

    &-FeaturesList
    {
        display: flex;

        margin-bottom: 8rem;

        list-style-type: none;

        gap: 3.2rem;
        flex-wrap: wrap;
    }

    &-CardInner
    {
        margin-top: auto;
    }

    &-CardTitle
    {
        margin-bottom: 0.8rem !important;
    }

    &-ObjectInfo
    {
        font-size: var(--extra-small);
        line-height: 1.5;

        margin-bottom: 2.2rem;

        @include AdaptiveBlock;

        &_buttons
        {
            display: flex;

            margin-bottom: 8rem;

            flex-wrap: wrap;
            gap: 2rem;

            @media screen and (max-width: $tablet-media)
            {
                margin-bottom: 5.8rem;
            }

            @media screen and (max-width: $mobile-media)
            {
                gap: 1.6rem;
            }

            .Button
            {
                flex: 0 0 auto;

                @media screen and (max-width: $tablet-media)
                {
                    max-width: initial;
                    margin-right: 0;
                    margin-left: 0;

                    flex: 0 1 48%;
                }

                @media screen and (max-width: $landscape-mobile-media)
                {
                    margin-top: 0;

                    flex: 1 1 100%;
                }
            }
        }
    }

    &-Button
    {
        margin-top: 3.2rem;
        margin-right: 0;

        text-decoration: none;
    }

    &-Text
    {
        &_italic
        {
            font-style: italic;
        }

        &_bold
        {
            font-weight: bold;
        }
    }

    &-Description
    {
        @include AdaptiveBlock;

        p, span {
            font-size: var(--extra-small) !important;
            font-family: 'TT Firs Neue', Arial, Helvetica, sans-serif !important;
        }

        & > p,
        & > li
        {
            font-size: var(--extra-small);
            line-height: 1.5;

            margin-bottom: 2.2rem;
            margin-bottom: 0;
        }

        & > ol,
        & > ul,
        & > p {
            margin-bottom: 1.75rem;
        }

        li
        {
            font-size: var(--extra-small);
            line-height: 1;

            margin: 1.7rem 0 0 2.5rem;
        }

        p {
            & + ul,
            & + ol {
                margin-top: -.75rem;
                margin-bottom: 2rem;
            }
        }

        &_collapsed
        {
            @include CollapsedText;
        }

        .Card {
            justify-content: flex-start;
        }
    }

    &-DescriptionShowAll
    {
        font-family: 'TT Firs Neue', Arial, Helvetica, sans-serif;
        width: auto;

        @include ShowAllText;

        @media screen and (max-width: $desktop-media)
        {
            /* padding: 0; */
        }
    }
}
