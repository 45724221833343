.FavBadge
{
  font-size: var(--small);
  font-weight: bold;
  line-height: 2rem;

  position: absolute;
  right: 0;

  display: none;

  min-width: 2.5rem;
  margin: -8px -1rem 0 0;
  padding: 0.2rem 0.8rem;

  text-align: center;
  text-decoration: none;

  color: white;
  border-radius: 1.2rem;
  background-color: var(--green-300);

  &-Mobile {
    margin: -36px -1rem 0px 0;
  }
}