@mixin TextInput
{
    font-family: 'TT Firs Neue', Arial, Helvetica, sans-serif;
    font-size: var(--extra-small);

    display: block;

    width: 100%;
    margin-top: 3.2rem;
    padding: 2.3rem 2rem;

    letter-spacing: 0.06rem;

    border: none;
    border-radius: 3.2rem;
    outline: none;
    background: var(--gray-150);

    @media screen and (max-width: $mobile-media)
    {
        margin-top: 1.6rem;
        padding-top: 1.6rem;
        padding-bottom: 1.6rem;
    }

    &:active,
    &:focus,
    &:focus-visible
    {
        outline: 0.2rem solid var(--green-300);
    }

    &:-ms-input-placeholder,
    &::placeholder
    {
        color: var(--gray-900);
    }
}

@mixin InputSubmit
{
    font-size: var(--extra-small);
    font-weight: bold;
    font-style: normal;
    line-height: 2.4rem;

    display: block;

    margin: 4.8rem auto 0 auto;
    padding: 2rem 4.5rem;

    cursor: pointer;
    text-align: center;
    letter-spacing: 0.055rem;

    color: var(--white);
    border: none;
    border-radius: 10.1rem;
    background-color: var(--green-300);

    @media screen and (max-width: $mobile-media)
    {
        width: 100%;
        margin-top: 1.6rem;
        padding-top: 1.4rem;
        padding-bottom: 1.4rem;
    }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active
{
    background: var(--gray-150);
    -webkit-box-shadow: 0 0 0 40px var(--gray-150) inset !important;

    -webkit-text-fill-color: var(--black);
}

input {outline: none;}
input:-webkit-autofill {
    -webkit-box-shadow: inset 0 0 0 50px #fff !important; /* Цвет фона */
    -webkit-text-fill-color: #999 !important; /* цвет текста */
    color: #999 !important; /* цвет текста */
}
