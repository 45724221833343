.ApartmentCard
{
    position: fixed;
    z-index: 999;

    display: none;

    width: 28.8rem;
    padding: 2.4rem;

    animation: HouseCard 0.5s linear;

    background: var(--white);

    transform-style: preserve-3d;

    &-Title
    {
        font-size: var(--small);
        line-height: 1.5;

        display: flex;

        margin-bottom: 1.6rem;

        letter-spacing: 0.01rem;

        color: var(--black);

        gap: 1.6rem;
        align-items: center;
        justify-content: space-between;
    }

    &-Id
    {
        font-size: var(--small);
        line-height: 1.5;

        letter-spacing: 0.01rem;

        color: var(--gray-550);
    }

    &-Image
    {
        text-align: center;
        max-width: 15.3rem;
        display: block;
        margin: 0 auto 1.6rem;
    }

    &-Prise
    {
        font-size: var(--extra-medium);
        font-weight: 400;
        line-height: 1.4;

        letter-spacing: 0.025rem;

        color: var(--black);
    }

    &-PriceMeter
    {
        font-size: var(--small);
        line-height: 1.7;

        margin-bottom: 1.6rem;

        letter-spacing: 0.02rem;

        color: var(--gray-550);
    }

    &-Parameters
    {
        font-size: var(--small);
        line-height: 1.5;

        display: flex;

        color: var(--black);

        align-items: center;
        justify-content: flex-start;
        gap: 2.4rem;
    }

    &-ParametersItem
    {
        position: relative;

        &::after
        {
            position: absolute;
            top: 50%;
            right: -1.4rem;

            width: 0.4rem;
            height: 0.4rem;

            content: '';
            transform: translateY(-50%);

            border-radius: 0.4rem;
            background: var(--gray-250);
        }

        &:last-child
        {
            &::after
            {
                display: none;
            }
        }
    }
}
