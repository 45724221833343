.Services
{
    width: 100%;
    margin: 0 auto 2.8rem;

    @include AdaptiveBlock;

    @media screen and (max-width: $mobile-media)
    {
        padding: 0;
        margin-top: 7rem;
    }

    &-Text
    {
        font-size: var(--additional-small);
        line-height: 1.45;

        padding: 2.4rem;

        background: var(--gray-150);
    }

    &-Phone
    {
        font-weight: bold;

        margin-left: 1.6rem;

        @media screen and (max-width: $mobile-media)
        {
            display: inline-block;

            margin-left: 0;

            white-space: nowrap;
        }
    }
}
