.ChooseHouse,
.ChooseHouseParking,
.ChooseHouseStore
{
    position: relative;

    margin: 0;

    @media screen and (max-width: $mobile-media)
    {
        position: static;

        overflow: auto;

        max-width: 100%;
        //height: 45vh;
        margin: 0;
        padding-top: 0;
    }

    &-Loader {

        .Tabs-Caption {
            display: flex;
            @media screen and (max-width: $tablet-media)
            {
                margin-bottom: 0;
            }

            li {
                @media screen and (max-width: $tablet-media)
                {
                    width: 100%;
                }
            }

        }
    }

    &-TitleMobile
    {
        padding: 2rem;

        @media screen and (min-width: $mobile-media)
        {
            display: none;
        }
    }

    &-Map,
    &-FloorTabs,
    &-ParkingTabs,
    &-StoreTabs
    {
        width: 100%;
    }

    &-Header
    {
        max-width: var(--wid-project);
        margin-right: auto;
        margin-bottom: 0;
        margin-left: auto;
    }

    &-Map
    {
        position: relative;

        max-width: var(--wid-project);
        margin-right: auto;
        margin-left: auto;

        img
        {
            display: block;

            width: 100%;
        }

        svg
        {
            position: absolute;
            top: 0;

            width: 100%;
            height: 100%;

            @media screen and (max-width: $mobile-media)
            {
                max-height: 40vh;
            }
        }
    }

    &-FloorTabs
    {
        .Tabs-Caption.Tabs-Caption_center {
            li {
                &:first-child {
                    @media screen and (min-width: $desktop-media) {
                        margin-left: 15rem;
                    }
                }
            }
        }

        .Tabs_pad
        {
            @media screen and (max-width: $mobile-media)
            {
                padding-top: 0;
            }
        }
    }

    &-FloorTabs,
    &-ParkingTabs,
    &-StoreTabs
    {
        display: none;
    }

    &-Title
    {
        font-size: var(--ultra-large);
        font-weight: bold;

        position: relative;
        z-index: 100;

        margin: 0 auto -7.7rem 3.2rem;
        padding-top: 3.6rem;

        color: var(--white);

        @media screen and (max-width: $mobile-media)
        {
            display: none;
        }
    }

    &-Popup
    {
        position: absolute;
        left: 5.6rem;

        display: inline-block;
        visibility: hidden;

        padding: 1.6rem;

        transition: opacity var(--transition-time), visibility var(--transition-time);

        opacity: 0;
        background: var(--white);

        @media (max-width: $landscape-mobile-media)
        {
            display: none;
        }

        &_right
        {
            right: 5.6rem;
            left: auto;
        }
    }

    &-Popup-Title
    {
        font-size: var(--extra-medium);
        font-weight: bold;

        padding-bottom: 0.8rem;

        letter-spacing: 0.01rem;

        color: var(--black);
    }

    &-PopupImages
    {
        display: flex;

        gap: 0.8rem;
        align-items: center;
        justify-content: space-between;
    }

    &-Object
    {
        cursor: pointer;

        stroke-width: 0.2rem;
        //stroke: var(--green-300);
        fill: transparent;

        &:hover
        {
            fill: var(--green-300);
            fill-opacity: 0.5;
            stroke-opacity: 0.5;
        }
    }

    &-Icon
    {
        position: absolute;
        z-index: 100;
        /* top: 50%;
        left: 50%; */
        /* 
        left: 3.2rem;
        top: 10rem; */

        width: 4.8rem;
        height: 4.8rem;

        cursor: pointer;

        border-radius: 3.2rem;
        background-color: var(--white);

        &-IconHolder {
            width: 4.8rem;
            height: 4.8rem;
            background: no-repeat 50% 50%;
            background-size: 2.2rem 2.7rem;
            &:hover {
                filter: grayscale(1) brightness(11.5);
            }
        }

        &_empty
        {
            background: var(--white) no-repeat 50% 50%;
            background-size: 2rem 2.5rem;

            &:hover
            {
                background: var(--green-300) no-repeat 50% 50%;

                .ChooseHouse-Popup
                {
                    visibility: visible;

                    opacity: 1;
                }
            }

            &_1
            {
                margin: -38rem 0 0 27rem;
            }

            &_2
            {
                margin: 4rem 0 0 -77rem;
            }

            &_3
            {
                margin: 15rem 0 0 73rem;
            }
        }

        &_cross
        {
            background: var(--white)  no-repeat 50% 50% url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzIiIGhlaWdodD0iMzIiIHZpZXdCb3g9IjAgMCAzMiAzMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTQuODE3MjggMjcuNDQzMkw1LjA0MTMzIDI3LjY2NzNMNS4wNDYxIDI3LjY2MjVDNy45MDg3NyAzMC4zNTIzIDExLjc2MiAzMiAxNiAzMkMyMC4yMzg4IDMyIDI0LjA5MjYgMzAuMzUxNyAyNi45NTU0IDI3LjY2MUwyNi45NjE2IDI3LjY2NzNMMjcuNjY4OCAyNi45NjAyTDI3LjY2MjUgMjYuOTUzOUMzMC4zNTIzIDI0LjA5MTIgMzIgMjAuMjM4IDMyIDE2QzMyIDExLjc2MiAzMC4zNTIzIDcuOTA4NzcgMjcuNjYyNSA1LjA0NjExTDI3LjY2ODggNS4wMzk4NUwyNi45NjE2IDQuMzMyNzRMMjYuOTU1NCA0LjMzODk1QzI0LjA5MjYgMS42NDgzMiAyMC4yMzg4IDAgMTYgMEMxMS43NjIgMCA3LjkwODc3IDEuNjQ3NzMgNS4wNDYxIDQuMzM3NTFMNS4wNDEzMyA0LjMzMjc0TDQuODE3MjggNC41NTY4QzQuNzI5NDYgNC42NDI2MyA0LjY0MjYzIDQuNzI5NDYgNC41NTY4IDQuODE3MjhMNC4zMzQyMyA1LjAzOTg0TDQuMzM4OTUgNS4wNDQ1N0MxLjY0ODMyIDcuOTA3MzkgMCAxMS43NjEyIDAgMTZDMCAyMC4yMzg4IDEuNjQ4MzIgMjQuMDkyNiA0LjMzODk1IDI2Ljk1NTRMNC4zMzQyMyAyNi45NjAyTDQuNTU2ODEgMjcuMTgyN0M0LjY0MjY0IDI3LjI3MDUgNC43Mjk0NyAyNy4zNTc0IDQuODE3MjggMjcuNDQzMlpNNS4wNDY0MiAyNi4yNDhDMi41MzY1OSAyMy41NjY0IDEgMTkuOTYyNyAxIDE2QzEgMTIuMDM3MyAyLjUzNjU5IDguNDMzNTggNS4wNDY0MiA1Ljc1MjA0TDE1LjI5NDQgMTZMNS4wNDY0MiAyNi4yNDhaTTUuNzUzNTggNS4wNDQ5OEwxNi4wMDE1IDE1LjI5MjlMMjYuMjQ4IDUuMDQ2NDJDMjMuNTY2NCAyLjUzNjU5IDE5Ljk2MjcgMSAxNiAxQzEyLjAzODEgMSA4LjQzNDk2IDIuNTM2IDUuNzUzNTggNS4wNDQ5OFpNMjYuOTU1IDUuNzUzNThMMTYuNzA4NiAxNkwyNi45NTUgMjYuMjQ2NEMyOS40NjQgMjMuNTY1IDMxIDE5Ljk2MTkgMzEgMTZDMzEgMTIuMDM4MSAyOS40NjQgOC40MzQ5NiAyNi45NTUgNS43NTM1OFpNMjYuMjQ4IDI2Ljk1MzZMMTYuMDAxNSAxNi43MDcxTDUuNzUzNTggMjYuOTU1QzguNDM0OTYgMjkuNDY0IDEyLjAzODEgMzEgMTYgMzFDMTkuOTYyNyAzMSAyMy41NjY0IDI5LjQ2MzQgMjYuMjQ4IDI2Ljk1MzZaIiBmaWxsPSIjMDBBNjU1Ii8+PC9zdmc+);
            background-size: 3.2rem 3.2rem;

            &_1
            {
                margin: -4rem 0 0 24rem;
            }

            &_2
            {
                margin: 30.6rem 0 0 0.9rem;
            }
        }
    }
}

//Admin styles
.ea
{
    .ChooseHouse
    {
        &-Title
        {
            font-size: 32px;

            margin: 0 auto -77px 24px;
            padding-top: 36px;
        }

        &-Popup
        {
            left: 56px;

            padding: 16px;

            &_right
            {
                right: 56px;
            }
        }

        &-Popup-Title
        {
            font-size: 20px;
            font-weight: bold;

            padding-bottom: 8px;
        }

        &-PopupImages
        {
            gap: 8px;
        }

        &-Object
        {
            stroke-width: 2px;
        }

        &-Icon
        {
            width: 48px;
            height: 48px;

            border-radius: 32px;

            &_park
            {
                background-size: 20px 25px;

                &_1
                {
                    margin: -380px 0 0 270px;
                }

                &_2
                {
                    margin: 40px 0 0 -770px;
                }

                &_3
                {
                    margin: 150px 0 0 730px;
                }
            }

            &_cross
            {
                background-size: 32px 3.2px;

                &_1
                {
                    margin: -40px 0 0 240px;
                }

                &_2
                {
                    margin: 306px 0 0 9px;
                }
            }
        }
    }
}
