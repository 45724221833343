.Filters
{
    display: flex;
    flex-direction: column;

    max-width: var(--max-wid-project);
    margin: 3.4rem auto 6.4rem;
    padding-right: 1.6rem;
    padding-left: 1.6rem;

    row-gap: 2.4rem;
    column-gap: 1.6rem;
    justify-content: space-between;

    //768
    //@include AdaptiveBlock;

    @media (min-width: $tablet-media)
    {
        flex-direction: row;

        padding-right: 2.4rem;
        padding-left: 2.4rem;

        flex-wrap: wrap;
    }

    @media (min-width: $second-medium-desktop-media)
    {
        flex-direction: column;

        @include NoAdaptiveBlock;
    }

    @media screen and (max-width: $desktop-media)
    {
        margin-bottom: 4.8rem;
    }

    @media screen and (max-width: $tablet-media)
    {
        margin-bottom: 3.3rem;
    }

    @media screen and (max-width: $mobile-media)
    {
        margin: 1.4rem auto;

        gap: 1.4rem;
    }

    &:has(.Button_commerce)
    {
        flex-wrap: nowrap;

        @media screen and (max-width: $tablet-media) {
            flex-wrap: wrap;
        }
    }

    //1407


    &_vertical
    {
        display: block;
        //width: 37.6rem;

        width: 100%;
        max-width: 37.6rem;
        margin: 0;
        padding-right: 3.2rem;
        padding-left: 3.2rem;

        @media (min-width: $large-desktop-media-second)
        {
            padding-right: 0;
            padding-left: 0;
        }

        .Filters-Top
        {
            flex-direction: column;

            gap: 3.2rem;

            .chosen-container-single .chosen-single
            {
                background: var(--white);
            }
        }

        .Filters-Bottom
        {
            text-align: left;
        }

        .Filters-Elem_rangegroup
        {
            .Values
            {
                border: 0.2rem solid var(--gray-150);
            }

            .Values,
            input
            {
                background-color: var(--white);
            }
        }
    }

    &_rent
    {
        max-width: 100%;

        @media screen and (max-width: $mobile-media)
        {
            position: relative;
            top: -25rem;

            background: var( --white );
        }

        @media (min-width: $second-medium-desktop-media)
        {
            max-width: 37.6rem;
        }

        //1407

        .Filters-ButtonOpen
        {
            @media screen and (max-width: $mobile-media)
            {
                margin-bottom: -10rem;
            }
        }

        .Filters-Head
        {
            display: none;

            @media (min-width: $second-medium-desktop-media)
            {
                display: none;
            } //1407
        }

        .Filters-Bottom
        {
            display: block;

            text-align: center;

            @media (min-width: $second-medium-desktop-media)
            {
                text-align: left;
            } //1407
        }

        .Filters-Block_active
        {
            position: relative;

            display: block;

            @media screen and (max-width: $mobile-media)
            {
                margin-top: 1rem;
                margin-bottom: -23rem;
            }
        }

        .Filters-Top
        {
            @media (min-width: $tablet-media)
            {
                flex-direction: row;
            }

            //768

            @media (min-width: $second-medium-desktop-media)
            {
                flex-direction: column;
            } //1407
        }

        .Filters-Col
        {
            width: 100%;
            min-width: 100%;

            &_01,
            &_02,
            &_03,
            {
                @media (min-width: $tablet-media)
                {
                    width: 30%;
                    min-width: auto;
                }

                //768

                @media (min-width: $second-medium-desktop-media)
                {
                    width: 100%;
                    min-width: 100%;
                }

                //1407

                &_04
                {
                    @media (min-width: $tablet-media)
                    {
                        width: 100%;
                        min-width: 100%;
                    }  //768
                }
            }
        }

        .Filters-ButtonOpen
        {
            @media (min-width: $tablet-media)
            {
                width: 100%;
            }  //768
        }
    }

    &-ButtonOpen
    {
        width: 100%;

        //padding-top: 1.2rem;
        //padding-bottom: 1.2rem;

        @media (min-width: $tablet-media)
        {
            width: 48.8%;
        }

        //768

        @media (min-width: $second-medium-desktop-media)
        {
            display: none;
        }

        //1407

        &_main-page
        {
            width: calc(100% - 3.2rem);
            margin-right: 1.6rem;
            margin-left: 1.6rem;

            @media (min-width: $tablet-media)
            {
                width: calc(48.8% - 2.4rem);
                margin-right: 0;
                margin-left: 2.4rem;
            }
        }
    }

    .Button-Map
    {
        display: flex;

        width: 100%;
        padding: 2rem 3.2rem;

        border-radius: 3.2rem;

        align-items: center;
        justify-content: center;

        @media (min-width: $tablet-media)
        {
            width: 48.8%;
        }

        @media screen and (max-width: $desktop-media)
        {
            padding-top: 1.55rem;
            padding-bottom: 1.55rem;
        }

        @media screen and (max-width: $mobile-media)
        {
            padding-top: 1.3rem;
            padding-bottom: 1.3rem;
        }

        //768

        @media (min-width: $second-medium-desktop-media)
        {
            display: none;
        }

        //1407

        &_main-page
        {
            width: calc(100% - 3.2rem);
            margin-right: 1.6rem;
            margin-left: 1.6rem;

            @media (min-width: $tablet-media)
            {
                width: calc(48.8% - 2.4rem);
                margin-right: 2.4rem;
                margin-left: 0;
            }
        }
    }

    &-Block
    {
        position: fixed;
        z-index: 400;

        display: none;

        width: 100%;
        padding-top: 2.4rem;
        padding-bottom: 1.6rem;

        background: var(--white);

        inset: auto 0 0 0;
        @include AdaptiveBlock;

        @media (min-width: $desktop-media)
        {
            padding-top: 3.2rem;
            padding-bottom: 4rem;
        }

        //1024

        @media (min-width: $second-medium-desktop-media)
        {
            position: relative;

            display: block;

            padding-top: 0;
            padding-bottom: 0;

            background: none;
        }

        //1407

        &_active
        {
            display: block;
        }
    }

    &-Head
    {
        display: flex;

        margin-bottom: 6rem;

        align-items: center;
        justify-content: space-between;

        @media (min-width: $second-medium-desktop-media)
        {
            display: none;
        } //1407
    }

    &-HeadBack
    {
        font-size: var(--small);

        display: flex;

        cursor: pointer;

        color: var(--gray-1200);

        align-items: center;
        justify-content: flex-start;
        gap: 1rem;

        span
        {
            @media (max-width: $landscape-mobile-media)
            {
                display: none;
            }
        }

        &:before
        {
            display: inline-block;

            width: 2rem;
            height: 2rem;

            content: '';

            background: no-repeat 50% 50% url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNOC45Mzk0IDEwTDIuNDY5NzMgMTYuNDY5N0wzLjUzMDM5IDE3LjUzMDNMMTAuMDAwMSAxMS4wNjA3TDE2LjQ2OTcgMTcuNTMwM0wxNy41MzA0IDE2LjQ2OTdMMTEuMDYwNyAxMEwxNy41MzA0IDMuNTMwMzNMMTYuNDY5NyAyLjQ2OTY3TDEwLjAwMDEgOC45MzkzNEwzLjUzMDM5IDIuNDY5NjdMMi40Njk3MyAzLjUzMDMzTDguOTM5NCAxMFoiIGZpbGw9ImJsYWNrIi8+PC9zdmc+);

            @media (min-width: $landscape-mobile-media)
            {
                display: none;
            }
        }
    }

    &-HeadTitle
    {
        font-size: var(--extra-medium);
        font-weight: bold;

        color: var(--black);
    }

    &-HeadReset
    {
        font-size: var(--small);

        cursor: pointer;

        color: var(--gray-950);
    }

    &-Top
    {
        display: flex;
        flex-direction: column;

        margin-bottom: 2.4rem;

        flex-wrap: wrap;
        justify-content: space-between;
        gap: 1.6rem;

        &_centered
        {
            justify-content: center;
            gap: 5rem;
        }

        @media (min-width: $tablet-media)
        {
            flex-direction: row;

            gap: 2.4rem;
        }

        //768

        @media (min-width: $second-medium-desktop-media)
        {
            flex-wrap: nowrap;
        } //1407
    }

    &-Row
    {
        display: flex;

        align-items: center;
        justify-content: space-between;
        gap: 3.2rem;
    }

    &-Input
    {
        margin-top: 0;

        @include TextInput;

        &_error
        {
            outline: 0.2rem solid var(--red-500);
        }
    }

    &-Col
    {
        display: flex;
        flex-direction: column;

        gap: 0.4rem;

        &_01
        {
            &_small
            {
                width: 100%;
            }
        }

        &_02
        {
            &_small
            {
                width: 100%;
            }
        }

        &_04
        {
            &_small
            {
                width: 100%;
            }
        }

        &_50,
        &_01,
        &_02,
        &_04,
        &_05,
        &_27,
        &_281,
        &_313,
        &_112,
        &_184,
        &_216
        {
            width: 100%;
        }

        @media (min-width: $tablet-media)
        {
            width: 48.3%;
        }

        //768

        @media (min-width: $desktop-media)
        {
            width: 100%;

            &_01,
            &_02,
            &_03
            {
                width: 31%;
            }
        }

        //1024



        &_50
        {
            width: 49%;

            @media (min-width: $second-medium-desktop-media)
            {
                width: 49%;
            } //1407
        }

        &_01
        {
            @media (min-width: $second-medium-desktop-media)
            {
                width: 100%;
                //min-width: 24rem;
                min-width: 21rem;
                max-width: 36.8rem;
            }

            //1407


            &_small
            {
                @media (min-width: $second-medium-desktop-media)
                {
                    width: 19.1rem;
                } //1407
            }
        }

        &_02
        {
            @media (min-width: $second-medium-desktop-media)
            {
                width: 27.1rem;

                flex-shrink: 0;
            }

            //1407

            &_small
            {
                @media (min-width: $second-medium-desktop-media)
                {
                    width: 27.1rem;
                } //1407
            }
        }

        &_04
        {
            @media (min-width: $second-medium-desktop-media)
            {
                width: 100%;
                min-width: 31rem;
                max-width: 31.8rem;
            }

            //1407


            &_small
            {
                @media (min-width: $second-medium-desktop-media)
                {
                    width: 19.1rem;
                } //1407
            }
        }

        &_05
        {
            @media (min-width: $second-medium-desktop-media)
            {
                width: 21.6rem;

                flex-shrink: 0;
            } //1407
        }

        &_27
        {
            @media (min-width: $second-medium-desktop-media)
            {
                width: 27%;
            } //1407
        }

        &_281
        {
            width: 28.1rem;

            @media (min-width: $second-medium-desktop-media)
            {
                width: 28.1rem;
            } //1407
        }

        &_313
        {
            width: 31.3rem;

            @media (min-width: $second-medium-desktop-media)
            {
                width: 31.3rem;
            } //1407
        }

        &_112
        {
            width: 11.2rem;

            @media (min-width: $second-medium-desktop-media)
            {
                width: 11.2rem;
            } //1407
        }

        &_184
        {
            width: 18.4rem;

            @media (min-width: $second-medium-desktop-media)
            {
                width: 18.4rem;
            } //1407
        }

        &_216
        {
            width: 21.6rem;

            @media (min-width: $second-medium-desktop-media)
            {
                width: 21.6rem;
            } //1407
        }
    }

    &-Title
    {
        font-size: var(--small);
        font-weight: 400;

        min-height: 1.9rem;

        letter-spacing: 0.02rem;

        color: var(--gray-450);
    }

    &-Info {
        font-size: var(--small);
        line-height: 6rem;
    }

    &-Elem
    {
        &_onlyMob
        {
            @media screen and (min-width: $mobile-media)
            {
                display: none !important;
            }
        }

        &_rangegroup,
        &_radiogroup
        {
            display: flex;

            column-gap: 0.8rem;
        }

        &_rangegroup
        {
            flex-wrap: wrap;

            .Ranges,
            .Values
            {
                display: flex;

                flex: 1 1 100%;
                align-items: center;
                justify-content: space-between;
            }

            .Values
            {
                font-size: var(--extra-small);
                font-weight: 400;

                padding-right: 1.5rem;
                padding-left: 1.5rem;

                color: var(--gray-950);
                border-radius: 3.2rem;
                background: var(--gray-150);

                &-active
                {
                    outline: 0.2rem solid var(--green-300);
                }

                .Filters-Input
                {
                    font-family: 'TT Firs Neue', Arial, Helvetica, sans-serif;
                    font-size: var(--extra-small);
                    font-weight: 400;

                    margin-top: 0;
                    margin-right: 0.5rem;
                    margin-left: 0.5rem;
                    padding-right: 0;
                    padding-left: 0;

                    color: var(--gray-950);
                    border: none;

                    &:focus-visible,
                    &:active,
                    &:focus
                    {
                        outline: none !important;
                    }

                    &:last-child
                    {
                        margin-right: 0;
                    }
                }
            }
        }

        .Button
        {
            height: 4.8rem;

            @media (min-width: $tablet-media)
            {
                height: 5.6rem;
            }

            //768

            @media (min-width: $desktop-media)
            {
                height: 6.4rem;
            } //1024
        }

        #projectId
        {
            min-width: 20rem;
        }
    }

    &-Bottom
    {
        display: none;

        padding-right: 3rem;

        /* text-align: right; */

        @media (min-width: $second-medium-desktop-media)
        {
            display: block;
        }

        //1407

        &_centered
        {
            text-align: center !important;
        }

        &_right
        {
            padding-right: 0;

            text-align: right !important;
        }
    }

    &-Reset
    {
        font-family: 'TT Firs Neue', Arial, Helvetica, sans-serif;
        font-size: var(--extra-small);

        display: inline-block;

        padding-right: 2.8rem;

        cursor: pointer;
        letter-spacing: 0.02rem;

        color: var(--gray-950);
        border: 0;
        background: no-repeat 100% 50% url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNNi41NDU0NiA4LjAwMDA4TDAuMDAwMTU1NDY3IDE0LjU0NTRMMS40NTQ2OSAxNkw3Ljk5OTk5IDkuNDU0NjJMMTQuNTQ1MyAxNkwxNS45OTk4IDE0LjU0NTVMOS40NTQ1MiA4LjAwMDA4TDE2IDEuNDU0NTRMMTQuNTQ1NSAwTDcuOTk5OTkgNi41NDU1M0wxLjQ1NDUzIDEuNTY5MzllLTA1TDAgMS40NTQ1Nkw2LjU0NTQ2IDguMDAwMDhaIiBmaWxsPSIjNUY2MDYyIi8+PC9zdmc+);

        appearance: none;
    }

    &_color
    {
        &_white
        {
            .chosen-container-single .chosen-single
            {
                background: var(--white);
            }
        }
    }

    &_marginTop
    {
        &_none
        {
            margin-top: 0;
        }
    }

    #region_chosen
    {
        .chosen-single
        {
            span
            {
                font-family: TT Firs Neue;
                font-size: var(--extra-small);
                font-weight: 400;
                font-style: normal;

                letter-spacing: 0.032rem;

                color: var(--black);
            }
        }
    }
}


.noUi {
    &-handle {
        width: 15px !important;
        height: 15px !important;
        border-radius: 10px;
        border: none;
        right: -5px !important;

        &:after, &:before {
            display: none;
        }
    }
    &-touch-area {
        &:after, &:before {
            display: none;
        }

        border: none;
        background: var(--green-500);
        width: 15px;
        height: 15px;
        border-radius: 10px;
    }

    &-base {
        height: 3px;
        border: none;
        //background: var(--green-500);
    }

    &-horizontal {
        height: 2px;
        border: none;
        background: var(--green-500);
        margin: 0 2rem
    }

    &-connect {
        height: 3px;
        background: var(--green-500);
    }
}

// Slider

.slider-wrapper
{
    font-family: arial;

    position: relative;

    display: inline-block;

    width: 100%;

    .range-slider
    {
        &.ui-widget-content
        {
            width: calc(100% - 5rem);
            height: 2px;
            margin: 0 auto;

            border: none;
            background: var(--green-500);
        }

        .ui-slider-handle
        {
            position: absolute;
            z-index: 10;
            top: -7px;

            display: block;

            width: 15px;
            height: 15px;

            cursor: pointer; /* fallback if grab cursor is unsupported */
            /* cursor:         grab;
            cursor:    -moz-grab;
            cursor: -webkit-grab;
 */

            border: 1px solid var(--green-500);
            border-radius: 10px;
            background: var(--green-500);

            &:focus-visible,
            &:active
            {
                /* cursor:         grabbing;
                cursor:    -moz-grabbing;
                cursor: -webkit-grabbing; */
                outline: 2px solid var(--green-500);
            }
        }

        .ui-slider-range
        {
            position: absolute;

            height: 3px;
            margin-top: -1.5px;

            border-top: 1px solid var(--green-500);
            border-bottom: 1px solid var(--green-500);
            background: var(--green-500);

            &:after
            {
                position: absolute;
                top: 0;
                left: 0;

                width: 100%;
                height: 1px;

                content: '';

                opacity: 0.3;
                background: #fff;
            }
        }
    }

    .ui-slider
    {
        position: relative;

        height: 7px;

        border: 1px solid #d2d2d2;
        border-radius: 100px;
        background: #efefef;
    }
}

.ui-state-active
{
    width: 22px;
}

// Range Indicator

.range-wrapper
{
    position: absolute;
    top: -50px;
    left: 50%;

    transform: translateX(-50%);
}
