.NewsItem
{
    //height: 45.2rem;

    //max-width: 37.6rem;
    width: 100%;
    background: var(--gray-150);

    display: flex;
    flex-direction: column;

    @media (min-width: $tablet-media) {
        max-width: 47%;
    }  //768

    @media (min-width: 1271px) {
        max-width: 37.6rem;
    } //1024

    &-Img {
        position: relative;
        overflow: hidden;
        height: 100%;
        max-height: 24.2rem;
        display: block;

        flex-shrink: 0;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

    }

    &-Info
    {
        padding: 2.4rem;
        position: relative;
        overflow: hidden;

        flex-grow: 1;
        display: flex;
        flex-direction: column;
    }

    &-Date
    {
        font-size: var(--extra-small);

        margin-bottom: 1.2rem;

        color: var(--gray-550);
    }

    &-ShortText
    {
        font-size: var(--tiny);
        font-weight: normal;

        overflow: hidden;

        height: 5rem;
        margin: 0 0 1rem;

        color: var(--black);
        flex-grow: 1;
    }

    &-Text
    {
        font-size: var(--additional-small);
        font-weight: 400;
        line-height: 1.5;

        overflow: hidden;

        //height: 11rem;
        margin-bottom: 1.2rem;

        text-decoration: none;
        letter-spacing: 0.025rem;

        color: var(--black);
    }

    &-ReadMore
    {
        font-size: var(--extra-small);
        font-weight: 400;

        position: relative;

        display: block;

        text-decoration: none;
        letter-spacing: 0.025rem;

        color: var(--gray-900);

        &::after
        {
            position: absolute;
            right: 0;

            width: 1.6rem;
            height: 1.6rem;

            content: '';

            background: no-repeat 50% 50% url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMTIiIHZpZXdCb3g9IjAgMCAxMiAxMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTAuMzU3MTQzIDEyVjEwLjVIOS40Mjg1N0wwIDEuMDcxNDNMMS4wNzE0MyAwTDEwLjUgOS40Mjg1N1YwLjI4NTcxNEgxMlYxMkgwLjM1NzE0M1oiIGZpbGw9IiM4RDhGOTIiLz48L3N2Zz4=);
        }

        &:hover
        {
            color: var(--black);

            &::after
            {
                background: no-repeat 50% 50% url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTciIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAxNyAxOCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTguMjUyNTMgMTcuMjMyN0w3LjE5MTg3IDE2LjE3MjFMMTMuNjA2MyA5Ljc1NzYxTDAuMjcyMzI0IDkuNzU3NjFWOC4yNDIzOUwxMy42MDYzIDguMjQyMzlMNy4xNDEzNiAxLjc3NzQxTDguMjAyMDIgMC43MTY3NDlMMTYuNDg1MyA5TDguMjUyNTMgMTcuMjMyN1oiIGZpbGw9ImJsYWNrIi8+PC9zdmc+);
            }
        }
    }
}
