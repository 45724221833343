.ApartmentsDecoration
{
    position: relative;

    width: 100%;
    max-width: var(--wid-project);
    margin: 0 auto;
    padding-bottom: 6.4rem;

    @media (max-width: $large-desktop-media-second)
    {
        padding-right: 3.2rem;
        padding-left: 3.2rem;
    }

    @media (max-width: $mobile-media)
    {
        padding-right: 1.6rem;
        padding-left: 1.6rem;
    }

    &-PageSlider
    {
        display: none;

        &_opened
        {
            display: block;
        }
    }

    &-Tour
    {
        line-height: 1.4;

        position: absolute;
        z-index: 2;
        bottom: 13.5rem;
        left: 3.2rem;
    }

    &-Switchers
    {
        display: flex;

        margin-bottom: 3.2rem;

        list-style-type: none;

        gap: 0.8rem;
    }

    &-ImgWrapper
    {
        width: 100%;
        margin: 0 auto;
    }

    &-Img
    {
        max-width: 100%;
        height: auto;
    }
}
