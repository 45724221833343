.Buy
{
    width: 100%;
    max-width: var(--wid-project);
    margin: 0 auto 4.8rem;

    &-Content
    {
        display: flex;

        gap: 6.6rem;
    }

    &-ImgWrapper
    {
        display: flex;

        margin-bottom: 8rem;

        background: var(--gray-150);

        justify-content: center;
        align-items: center;
    }

    &-Img
    {
        display: block;

        width: 78.4rem;
        max-width: 100%;
        height: 44rem;
        max-height: 100%;
    }

    &-Footnote
    {
        margin-top: -1.6rem;

        &_mt46
        {
            margin-top: -4.6rem;
        }
    }

    &-FootnoneTitle
    {
        font-size: var(--medium);
        font-weight: 400;
        line-height: 1.3;

        margin-bottom: 1.2rem;
    }

    &-FootnoteList
    {
        font-size: var(--extra-small);

        display: flex;
        flex-direction: column;

        margin-left: 1.2rem;

        gap: 1.2rem;
    }
}
