.dragscroll
{
    overflow: hidden;

    cursor: grab;

    &:active
    {
        cursor: grabbing;
    }
}
