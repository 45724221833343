.BuyCard
{
    overflow: hidden;

    width: 100%;
    min-height: 100%;
    margin-bottom: 2.4rem;

    @media (min-width: $desktop-media) {
        max-width: 61.4rem;
    } //1024

    &_full_width
    {
        max-width: 100%;

        .BuyCard-TextContent
        {
            padding-right: 3rem;
        }

        .BuyCard-Inner
        {
            gap: 6rem;
            align-items: flex-start;
        }
    }

    &-Inner
    {
        display: flex;

        height: 100%;

        gap: 3.2rem;
    }

    &-TextWrapper
    {
        display: flex;
        flex-direction: column;

        width: 100%;
        //min-width: 51.2rem;;
    }

    &-Legend
    {
        font-size: var(--extra-medium);
        font-weight: 400;
        line-height: 1.3;

        margin-bottom: 4.8rem;

        letter-spacing: 0.025rem;

        color: var(--gray-550);

        @media (min-width: $tablet-media) {
            font-size: var(--medium);
        }  //768

        &_black
        {
            color: var(--black);
        }
    }

    &-TextContent
    {
        color: var(--gray-550);

        &_black
        {
            color: var(--black);
        }
    }

    &-Title
    {
        font-size: var(--medium);
        font-weight: bold;
        line-height: 1.25;

        margin-bottom: 1.6rem;

        letter-spacing: 0.025rem;

        @media (min-width: $tablet-media) {
            font-size: var(--ultra-medium);
        }  //768
    }

    &-Text
    {
        font-size: var(--extra-small);
        font-weight: 400;
        line-height: 1.5;

        letter-spacing: 0.025rem;
    }
}
