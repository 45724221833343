.SendResult
{
    padding-top: 1.2rem;

    &-FormWrapper
    {
        display: flex;

        margin-bottom: 1.5rem;

        gap: 1.6rem;
        justify-content: center;
    }

    &-FormTitle
    {
        font-size: var(--medium);
        font-weight: bold;
        line-height: 1.3;

        margin-top: 1.6rem;
        margin-bottom: 1.6rem;

        text-align: center;
        letter-spacing: 0.02rem;
    }

    &-FormText
    {
        margin-bottom: 4.8rem;
    }
}
