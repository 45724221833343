.Vacancies
{
    display: flex;
    flex-direction: column;

    width: 100%;
    max-width: var(--wid-project);
    margin: 0 auto 4.8rem;

    @media screen and (min-width: $fix-size)
    {
        flex-direction: row;
    }

    &-Form
    {
        width: auto;
        margin-top: -3.2rem;

        @media screen and (min-width: $fix-size)
        {
            width: 41%;
        }
    }

    &-Inner
    {
        max-width: 51.2rem;
    }

    &-Text
    {
        display: flex;
        flex-direction: column;

        justify-content: center;

        @media screen and (min-width: $fix-size)
        {
            width: 41%;
            padding-left: 15.2rem;

            border-left: 0.1rem solid var(--gray-25);
        }
    }

    &-Inner
    {
        margin-right: auto;
        margin-left: auto;

        @media screen and (min-width: $fix-size)
        {
            width: 51.2rem;
            margin-right: auto;
            margin-left: 0;
        }
    }

    &-Title
    {
        font-size: var(--ultra-large);
        font-weight: bold;
        line-height: 1.25;

        margin-bottom: 2.4rem;

        letter-spacing: 0.025rem;

        color: #000;

        @media screen and (max-width: $fix-size)
        {
            margin-top: 4rem;
        }

        &,
        & + div
        {
            @media screen and (max-width: $mobile-media)
            {
                text-align: center;
            }
        }
    }

    &-Upload
    {
        margin-top: 3.2rem;
    }

    &-UploadLabel
    {
        font-size: var(--small);
        font-weight: 400;
        line-height: 1.5;

        margin-bottom: 1rem;

        letter-spacing: 0.025rem;

        color: var(--gray-450);
    }

    &-UploadButton
    {
        margin: 4.8rem auto 0;
        padding: 2rem 3.2rem;
    }
}
