.Footer
{
    padding-top: 4.9rem;

    background: var(--gray-200);

    flex: 0 0 auto;

    &-Wrap
    {
        max-width: var(--max-wid-project);
        margin: 0 auto;

        @include AdaptiveBlock;
    }

    &-Top
    {
        display: flex;
        flex-direction: column;

        margin-bottom: 4.6rem;

        flex-wrap: wrap;
        column-gap: 3.2rem;
        row-gap: 3rem;

        @media (min-width: $desktop-media)
        {
            flex-direction: row;

            justify-content: space-between;
        }

        //1024

        @media (min-width: $medium-desktop-media)
        {
            column-gap: 9.6rem;
        } //1200
    }

    &-RightCol
    {
        display: flex;
        flex-direction: column;

        row-gap: 1.2rem;

        @media (min-width: $desktop-media)
        {
            flex-direction: row;

            flex-grow: 1;
            align-items: flex-start;
            justify-content: space-between;
            column-gap: 3.2rem;
        } //1024
    }

    &-Logo
    {
        margin-top: -1.3rem;
    }

    &-Contacts
    {
        //flex-basis: 24rem;
    }

    &-ContactsRow
    {
        display: flex;
        flex-direction: column;

        align-items: flex-start;
        justify-content: flex-start;

        @media (min-width: $tablet-media)
        {
            flex-direction: row;

            gap: 2.4rem;
        }

        //768

        @media (min-width: $desktop-media)
        {
            flex-direction: column;
        } //1024
    }

    &-ContactsCol
    {
        @media (min-width: $tablet-media)
        {
            width: 100%;
            max-width: 22.4rem;
        }  //768
    }

    &-Title
    {
        font-size: var(--extra-small);
        font-weight: bold;

        margin-bottom: 2rem;

        text-transform: uppercase;

        color: var(--black);
    }

    &-SubTitle
    {
        font-size: var(--small);

        margin-bottom: 0.9rem;

        color: var(--gray-500);
    }

    &-WorkTime
    {
        font-size: var(--extra-small);
        font-weight: bold;

        display: block;

        margin-bottom: 2.6rem;

        text-decoration: none;
        letter-spacing: 0.08rem;

        color: var(--black);
    }

    &-Indent
    {
        margin-bottom: 0.9rem;
    }

    &-BackCall
    {
        margin-bottom: 2.6rem;
        margin-left: -0.5rem;
    }

    &-Offer
    {
        font-size: var(--tiny);
        line-height: 1.5;

        padding: 1.6rem 0;

        color: var(--gray-500);
        border-top: 0.1rem solid var(--gray-300);
        border-bottom: 0.1rem solid var(--gray-300);
    }

    &-Bottom
    {
        font-size: var(--small);

        display: flex;
        flex-direction: column;

        padding: 2.5rem 0;

        color: var(--gray-600);

        justify-content: space-between;
        flex-wrap: wrap;
        column-gap: 2.4rem;
        row-gap: 0.6rem;
        align-items: flex-start;

        @media (min-width: $tablet-media)
        {
            flex-direction: row;

            align-items: center;
            //padding-left: 2.4rem;
            //padding-right: 2.4rem;
        }  //768

        //@media (min-width: $desktop-media) {
        //    padding-left: 3.2rem;
        //    padding-right: 3.2rem;
        //}
        //
        //@media (min-width: $large-desktop-media-second) {
        //    padding-left: 0;
        //    padding-right: 0;
        //} //1664
    }

    &-Left
    {
        display: flex;
        flex-direction: column;

        letter-spacing: 0.035rem;

        column-gap: 2.6rem;
        row-gap: 0.6rem;
        flex-wrap: wrap;
        align-items: flex-start;

        @media (min-width: $tablet-media)
        {
            flex-direction: row;

            align-items: center;
        }  //768
    }

    &-PrivacyPolicy
    {
        text-decoration: none;

        color: var(--gray-600);
    }

    &-TermsUse
    {
        text-decoration: none;

        color: var(--gray-600);
    }

    &-Date
    {
        padding-top: 1.6rem;

        color: var(--gray-400);

        @media (min-width: $tablet-media)
        {
            padding-top: 0;
        }  //768
    }
}
