.MenuTopLine
{
  order: 30;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (min-width: $second-medium-desktop-media) {
    display: block;
    //margin-left: 21.6rem;
    order: 5;
  } //1407

}