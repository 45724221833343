.BuySuccess
{
    width: 57.6rem;
    padding: 4.8rem 0;

    &-Inner
    {
        display: flex;
        flex-direction: column;

        gap: 2.4rem;
        align-items: center;
    }

    &-Title
    {
        font-size: var(--medium);
        font-weight: bold;
        line-height: 1.3;

        text-align: center;
        letter-spacing: 0.02em;

        color: #000;
    }
}
