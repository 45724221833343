$about_background: url('../img/about.webp');
$about_darken_color_1: rgba( 0,0,0, 0.4 );
$about_darken_color_2: rgba( 0,0,0, 0.1 );

.Company
{
    @include AdaptiveBlock;
    margin-bottom: 4.8rem;

    &-Top
    {
        height: 68.2rem;
        //min-height: 68.2rem;

        padding: 4rem 1.6rem;

        color: var(--white);
        background: 50% 50% no-repeat $about_background;


        @media (min-width: $mobile-media) {
            //padding-top: 4.8rem;
            //padding-bottom: 4.8rem;
        } //480

        @media (min-width: $tablet-media) {
            padding-left: 2.4rem;
            padding-right: 2.4rem;
        }  //768

        &_darkened
        {
            //min-height: 65rem;

            background-image: linear-gradient( to right, $about_darken_color_1 0%, $about_darken_color_2 50%, $about_darken_color_2 100% ),
            $about_background;
        }
    }

    &-TopPosit
    {
        width: 100%;
        max-width: var(--wid-project);
        margin: 0 auto;

        display: flex;
        flex-direction: column;
        height: 100%;

        &_about
        {
            margin-bottom: 0;
        }
    }

    &-Title
    {
        font-size: var(--large);
        font-weight: 400;

        margin-bottom: 4.8rem;

        letter-spacing: 0.03rem;

        @media screen and (max-width: $mobile-media)
        {
            font-size: var(--medium);
        }
    }

    &-Text
    {
        font-size: var(--extra-medium);
        font-weight: 400;
        line-height: 1.4;

        width: 33%;
        //max-width: 51.2rem;
        margin-bottom: 4.8rem;

        letter-spacing: 0.16rem;

        @media (max-width: $mobile-media) {
            width: 100%;
        } //480


        &_large
        {
            font-size: var( --medium );

            line-height: 1.25;

            //max-width: 45%;
            max-width: 72rem;
            margin-bottom: 3.3rem;

            @media (min-width: $tablet-media) {
                font-size: var( --ultra-medium );
            }  //768
        }
    }

    &-Subtext
    {
        font-size: var( --additional-small );

        line-height: 1.4;

        max-width: 72rem;
        font-weight: 400;

        @media (min-width: $tablet-media) {
            flex-grow: 1;
            font-size: var( --extra-medium );
        }  //768
    }

    &-Button
    {

        width: 100%;
        margin-top: 3.2rem;
        margin-bottom: 3.2rem;
        //padding: 1.2rem 7.4rem;
        padding: 1.2rem;

        border-radius: 10rem;

        @media (min-width: $tablet-media) {
            padding-top: 1.6rem;
            padding-bottom: 1.6rem;
        }  //768

        @media (min-width: $desktop-media) {
            width: fit-content;
            margin-top: 4.8rem;
            margin-bottom: 4.8rem;
            padding: 2rem 7.4rem;

        } //1024
    }

    &-Quantity
    {
        width: 100%;
        padding: 1.2rem 1.6rem;

        color: var(--black);
        background: var(--white);

        @media (min-width: $mobile-media) {
            padding: 1.2rem 2.4rem;
        } //480

        @media (min-width: $tablet-media) {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 2.4rem;
        }  //768
    }

    &-QuantityItem
    {
        font-size: var(--extra-small);

        display: flex;
        padding: .5rem 0;

        border-bottom: 0.1rem solid var(--gray-750);

        flex-basis: 100%;

        gap: 1.6rem;
        align-items: center;
        justify-content: flex-start;

        &:first-child {
            padding-top: 0;
        }
        &:last-child {
            padding-bottom: 0;
        }


        @media (min-width: $tablet-media) {
            flex-basis: 33%;
            align-items: center;
            padding: 0 1rem;
            border-bottom: 0;
            justify-content: center;
            border-right: 0.1rem solid var(--gray-750);
        }  //768

        @media (min-width: $desktop-media) {
            font-size: var(--medium);
            gap: 2.4rem;
            padding: 0;
        } //1024

        &:last-child
        {
            border: none;
        }
    }

    &-QuantityCount
    {
        font-size: var(--ultra-big);

        @media (min-width: $tablet-media) {
            font-size: var(--additional-big); //52
        }  //768

        @media (min-width: $desktop-media) {
            font-size: var(--large-big); //56
        } //1024

        @media (min-width: $medium-desktop-media) {
            font-size: var(--extra-big); //68
        } //1200

        @media screen and (max-width: $mobile-media)
        {
            font-size: var(--medium);
        }

        &_bold
        {
            font-weight: 400;

            letter-spacing: 0.2rem;
        }
    }

    &-Bottom
    {
        display: flex;

        min-height: 32rem;
        padding: 6.4rem 1.6rem;
        //padding: 6.4rem 2.4rem;

        background: var(--gray-150);

        justify-content: center;
        gap: 2.4rem;

        align-items: center;
        flex-direction: column;

        @media (min-width: $tablet-media) {
            padding-left: 2.4rem;
            padding-right: 2.4rem;
        }  //768

        @media (min-width: $desktop-media) {
            column-gap: 3.2rem;
            flex-direction: row;
        } //1024

        @media (min-width: $medium-desktop-media) {
            column-gap: 16.8rem;
        } //1200

        &_about
        {
            background: none;

            .GridContent-Cell_title
            {
                -ms-flex: 0 0 20%;
                flex: 0 0 20%;
            }
        }
    }

    &-Inform
    {
        font-size: var(--extra-medium);
        line-height: 1.4;

        width: 100%;
        max-width: 92rem;

        color: var(--gray-1000);

        &_top
        {
            padding-top: 0;
        }
    }

    &-Menu
    {
        width: 100%;

        @media (min-width: $medium-desktop-media) {
            max-width: 51.2rem;
        } //1200
    }

    &-Link
    {
        font-size: var(--extra-medium);
        font-weight: 400;
        line-height: 1.4;

        position: relative;

        display: block;

        padding: 1.8rem 2.4rem;

        text-decoration: none;
        letter-spacing: 0.025rem;
        text-transform: uppercase;

        color: var(--black);
        border-bottom: 0.1rem solid var(--black);

        &::after
        {
            position: absolute;
            top: 50%;
            right: 2.4rem;

            width: 1.4rem;
            height: 2.2rem;

            content: '';
            transform: translateY(-50%);

            background: 50% 50% no-repeat url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAxNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMS41IDIzTDEyLjUgMTJMMS41IDEiIHN0cm9rZT0iYmxhY2siIHN0cm9rZS13aWR0aD0iMiIvPjwvc3ZnPg==);
        }
    }

    &-Power
    {
        font-size: 1.1rem;

        display: inline-block;

        margin-left: 0.2rem;
    }

    &_about
    {
        margin-bottom: 0;
    }

    &Map
    {
        &-Image
        {
            display: block;

            max-width: 100%;
        }
    }

    .GridContent {
        &-Cell {
            &_title {
                flex-basis: 28rem;
            }
        }
    }
}
