.FinishedSlider
{
    &_overlap
    {
        margin-top: -14.6rem;

        @media screen and (max-width: $large-desktop-media-second)
        {
            margin-top: -20.1rem;
        }

        @media screen and (max-width: $landscape-mobile-media)
        {
            margin-top: -16rem;
        }

        .HeaderSlider-Item
        {
            @media screen and (max-width: $mobile-media)
            {
                height: auto;
            }
        }
    }

    .HeaderSlider
    {
        overflow: hidden;

        &-Item
        {
            height: calc(100vh - 7.6rem);
            max-height: initial;

            @media screen and (max-width: $mobile-media)
            {
                height: 30vh;
            }
        }

        .slick-slider
        {
            height: calc(100vh - 7.6rem);

            @media screen and (max-width: $mobile-media)
            {
                height: 30vh;
            }
        }

        &-Img
        {
            object-position: center bottom;

            &_contentTop
            {
                object-position: center top;
            }
        }

        .slick-prev,
        .slick-next
        {
            position: absolute;
            z-index: 100;
            top: 50%;
            //margin-left: -800px;
            //bottom: 13.6rem;
            left: 50%;

            width: 6.4rem;
            height: 6.4rem;

            transition: 0.3s opacity;
            transform: translateY(-50%);
            //top: 50%;
            //transform: translate(0, -50%);
            transform: translate(0, 0);

            opacity: 0;
            border: 0.1rem solid var(--white);
            border-radius: 6.4rem;

            @media screen and (max-width: $tablet-media)
            {
                top: 35%;

                opacity: 1;
            }

            @media screen and (max-width: $mobile-media)
            {
                display: none !important;
            }

            &.slick-disabled
            {
                opacity: 0.2;
            }

            &:before
            {
                position: absolute;
                top: -50vh;
                left: -100%;

                width: 300%;
                height: 100vh;

                content: '';

                background: none;

                @media screen and (max-width: $tablet-media)
                {
                    display: none;
                }
            }
        }

        .slick-prev
        {
            right: auto;
            left: 4.8rem;

            margin-left: 0;

            background: 50% 50% no-repeat url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzMiIGhlaWdodD0iMzQiIHZpZXdCb3g9IjAgMCAzMyAzNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMzEuOTk5NiAxNi45OTk4TDIgMTYuOTk5OE0yIDE2Ljk5OThMMTYuOTk5OCAyTTIgMTYuOTk5OEMyIDE2Ljk5OTggMTEuMTQyIDI2LjE0MTggMTYuOTk5OCAzMS45OTk2IiBzdHJva2U9IndoaXRlIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJzcXVhcmUiLz48L3N2Zz4=);

            @media screen and (max-width: $tablet-media)
            {
                left: 2.4rem;
            }

            &:hover,
            &:active,
            &:focus
            {
                opacity: 0.8;
                background: rgba(255, 255, 255, 0.2) 50% 50% no-repeat url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzMiIGhlaWdodD0iMzQiIHZpZXdCb3g9IjAgMCAzMyAzNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMzEuOTk5NiAxNi45OTk4TDIgMTYuOTk5OE0yIDE2Ljk5OThMMTYuOTk5OCAyTTIgMTYuOTk5OEMyIDE2Ljk5OTggMTEuMTQyIDI2LjE0MTggMTYuOTk5OCAzMS45OTk2IiBzdHJva2U9IndoaXRlIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJzcXVhcmUiLz48L3N2Zz4=);
            }

            &:active
            {
                opacity: 1;
            }

            &.slick-disabled
            {
                &:hover,
                &:focus
                {
                    opacity: 0.2;
                    border: 10px solid #f00;
                    background: 50% 50% no-repeat url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzMiIGhlaWdodD0iMzQiIHZpZXdCb3g9IjAgMCAzMyAzNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMzEuOTk5NiAxNi45OTk4TDIgMTYuOTk5OE0yIDE2Ljk5OThMMTYuOTk5OCAyTTIgMTYuOTk5OEMyIDE2Ljk5OTggMTEuMTQyIDI2LjE0MTggMTYuOTk5OCAzMS45OTk2IiBzdHJva2U9IndoaXRlIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJzcXVhcmUiLz48L3N2Zz4=);
                }
            }

            &:before
            {
                @media screen and (max-width: $full-desktop-media)
                {
                    display: none;
                }
            }
        }

        .slick-next
        {
            right: 4.8rem;
            left: auto;

            margin-left: 0;

            background: no-repeat 50% 50%  url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzMiIGhlaWdodD0iMzQiIHZpZXdCb3g9IjAgMCAzMyAzNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMS4wMDA0IDE2Ljk5OThMMzEgMTYuOTk5OE0zMSAxNi45OTk4TDE2LjAwMDIgMk0zMSAxNi45OTk4QzMxIDE2Ljk5OTggMjEuODU4IDI2LjE0MTggMTYuMDAwMiAzMS45OTk2IiBzdHJva2U9IndoaXRlIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJzcXVhcmUiLz48L3N2Zz4=);

            @media screen and (max-width: $tablet-media)
            {
                right: 2.4rem;
            }

            &:hover,
            &:active,
            &:focus
            {
                opacity: 0.8;
                background: rgba(255, 255, 255, 0.2) no-repeat 50% 50%  url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzMiIGhlaWdodD0iMzQiIHZpZXdCb3g9IjAgMCAzMyAzNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMS4wMDA0IDE2Ljk5OThMMzEgMTYuOTk5OE0zMSAxNi45OTk4TDE2LjAwMDIgMk0zMSAxNi45OTk4QzMxIDE2Ljk5OTggMjEuODU4IDI2LjE0MTggMTYuMDAwMiAzMS45OTk2IiBzdHJva2U9IndoaXRlIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJzcXVhcmUiLz48L3N2Zz4=);
            }

            &:active
            {
                opacity: 1;
            }

            &.slick-disabled
            {
                border: 10px solid #f00;

                &:hover,
                &:focus
                {
                    opacity: 0.2;
                    border: 10px solid #000;
                    background: no-repeat 50% 50%  url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzMiIGhlaWdodD0iMzQiIHZpZXdCb3g9IjAgMCAzMyAzNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMS4wMDA0IDE2Ljk5OThMMzEgMTYuOTk5OE0zMSAxNi45OTk4TDE2LjAwMDIgMk0zMSAxNi45OTk4QzMxIDE2Ljk5OTggMjEuODU4IDI2LjE0MTggMTYuMDAwMiAzMS45OTk2IiBzdHJva2U9IndoaXRlIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJzcXVhcmUiLz48L3N2Zz4=);
                }
            }
        }

        .slick-dotted.slick-slider
        {
            margin-bottom: 0;
        }
    }

    &_page
    {
        margin-bottom: 6.4rem;

        .HeaderSlider
        {
            &-Item
            {
                /* height: auto;                
                margin-top: 8rem; */
                margin-bottom: 6.5rem;
            }
        }
    }
}
