.LoginPage {
}

.LoginForm {

  &-Block {
    width: 40rem;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 3.2rem;

    .Form-Input {
      margin-top: 0;
    }

    .Form-Submit {
      margin-top: 0;
    }
  }

  &-Row {
    display: flex;
    flex-direction: column;
    gap: .8rem;
  }

}