.TitleBlock
{
    font-size: var(--large);
    font-weight: 400;

    margin-bottom: 4.6rem;

    letter-spacing: 0.13rem;

    color: var(--black);
    border-bottom: 0.2rem solid var(--black);

    &_nodivider
    {
        margin-bottom: 0.4em;

        border-bottom: transparent;
    }

    &_planblock
    {
        @media screen and (max-width: $desktop-media)
        {
            display: none;
        }
    }

    &_note
    {
        font-size: var( --medium );

        padding-bottom: 0.55rem;

        align-self: flex-end;

        @media screen and (max-width: $mobile-media)
        {
            font-size: var( --extra-small );

            padding-bottom: 0;
        }
    }

    @media screen and (max-width: $mobile-media)
    {
        font-size: var( --sub-large );
        line-height: 1.25;

        margin-bottom: 2.3rem;
    }

    @media screen and (max-width: $small-mobile-media)
    {
        font-size: var( --ultra-large );
    }

    &-Wrap
    {
        display: flex;

        width: 100%;
        max-width: var(--max-wid-project);
        margin: 0 auto;
        padding: 1.1rem 0 0.9rem;

        align-items: center;
        justify-content: flex-start;
        gap: 2.4rem;
        @include AdaptiveBlock;

        @media screen and (max-width: $mobile-media)
        {
            font-size: var(--medium);

            flex-wrap: wrap;
            gap: 1rem;
        }

        .Button
        {
            display: none;

            @media (min-width: $second-medium-desktop-media)
            {
                display: block;
            } //1407
        }

        &_full
        {
            .Button
            {
                display: block;
            }
        }
    }
}
