.ObjectAbout
{
    width: 100%;
    max-width: var(--wid-project);
    margin: 0 auto;
    margin-bottom: 4.8rem;

    &-Phone
    {
        font-weight: bold;
    }

    &-Title
    {
        font-size: var(--medium);
        font-weight: 400;
        line-height: 1.3;
    }

    &-Distance
    {
        display: flex;

        margin-top: 3.2rem;
        margin-bottom: 4.8rem;

        list-style-type: none;

        gap: 3.2rem;
        flex-wrap: wrap;
    }

    &-DistanceItem
    {
        display: flex;

        width: 23.5%;
        padding: 2.4rem;

        gap: 1.6rem;
    }

    &-DistanceIcon
    {
        display: flex;

        width: 6.4rem;
        height: 6.4rem;

        border-radius: 100%;
        background: var(--white);

        justify-content: center;
        align-items: center;
    }

    &-DistanceTitle
    {
        font-size: var(--extra-small);
        font-weight: bold;
        line-height: 1.5;

        margin-bottom: 1.6rem;
    }

    &-DistanceSubtitle
    {
        font-size: var(--extra-small);
        line-height: 1.5;

        letter-spacing: 0.02em;

        color: var(--gray-1050);
    }

    &_Collapsed
    {
        @include CollapsedText;

        &:not([style])
        {
            margin-bottom: 0;
        }
    }

    &-ShowAll
    {
        @include ShowAllText;
    }
}
