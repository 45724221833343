#CallBackNow-Connecting,
#CallBackNow-Counter
{
    display: none;
}

.CallBackNow
{
    &-Modal
    {
        &-ValidationFail
        {
            font-size: var(--small);

            display: none;

            padding-left: 2.4rem;

            color: var(--red-500);
        }

        &-Form
        {
            padding: 2.4rem 2.4rem;
        }

        &-Button
        {
            font-family: TT Firs Neue;
            font-size: 1.6rem;
            font-weight: bold;
            font-style: normal;
            line-height: 2.4rem; /* 150% */

            display: block;

            width: 100%;
            margin: 4.8rem auto 2.4rem;
            padding: 2rem 3.2rem;

            cursor: pointer;

            letter-spacing: 0.032rem;

            color: var(--white);
            border: none;
            border-radius: 10.1rem;
            background: var(--green-300);

            justify-content: center;
        }

        .Modal-Window
        {
            width: 100%;
            max-width: 44rem;
        }

        &-Title
        {
            display: flex;
            flex-direction: row-reverse;
        }

        .Modal-Close
        {
            position: initial;

            width: 1.2em;
            height: 1.2rem;
            margin-bottom: 2.4rem;

            &_white
            {
                &::before
                {
                    background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEyLjAwMjIgMTAuNTQ1NEw0Ljk1MTQxIDMuNDk0NjlMMy40OTY4NyA0Ljk0OTIyTDEwLjU0NzYgMTEuOTk5OUwzLjQ5NjY5IDE5LjA1MDhMNC45NTEyNCAyMC41MDUzTDEyLjAwMjIgMTMuNDU0NEwxOS4wNTMzIDIwLjUwNTVMMjAuNTA3OCAxOS4wNTFMMTMuNDU2NyAxMS45OTk5TDIwLjUwNzggNC45NDkxNkwxOS4wNTMzIDMuNDk0NjNMMTIuMDAyMiAxMC41NDU0WiIgZmlsbD0id2hpdGUiLz4KPC9zdmc+Cg==');
                }
            }
        }

        &-Counter
        {
            font-family: TT Firs Neue;
            font-size: 2.4rem;
            font-weight: bold;
            font-style: normal;
            line-height: 3.2rem;

            letter-spacing: 0.048rem;

            color: var(--green-300);
        }

        &-TextWhite
        {
            font-family: TT Firs Neue;
            font-size: 2.4rem;
            font-weight: 400;
            font-style: normal;
            line-height: 3.2rem; /* 133.333% */

            flex-direction: column;

            padding: 2.4rem 2.4rem 8rem 2.4rem;

            text-align: center;
            letter-spacing: 0.048rem;

            color: var(--black);
            background: var(--white);

            align-items: flex-end;
            gap: 3.2rem;
        }

        &-Text
        {
            font-family: TT Firs Neue;
            font-size: 2.4rem;
            font-weight: 400;
            font-style: normal;
            line-height: 3.2rem; /* 133.333% */

            display: flex;
            flex-direction: column;

            padding: 2.4rem 2.4rem 8rem 2.4rem;

            text-align: center;
            letter-spacing: 0.048rem;

            color: #fff;
            background: var(--green-300);

            &-Seconds
            {
                font-weight: bold;

                text-decoration: underline;
            }
        }
    }

    &-Button
    {
        position: fixed;
        right: 4.8rem;
        bottom: 4.8rem;

        display: block;

        width: 6.4rem;
        height: 6.4rem;

        cursor: pointer;

        border-radius: 50%;
        background-color: var(--green-300);
        background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIHZpZXdCb3g9IjAgMCA0MCA0MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTUuNDA1NTcgMzQuNzY5NkM5LjQyODA4IDM4LjQxNjUgMTcuOTczOCAzMy4wMDg3IDIxLjQzMjIgMzAuNzkyNEMyNS4yNDI4IDI4LjM5OTUgMjguNDczNSAyNS4yMDY4IDMwLjg5NDggMjEuNDQxMUMzMi45MjY4IDE4LjM0MTQgMzguMjY5NyA5LjE2NzY3IDM0LjkyNDcgNS41NDY0M0MzMy4wMTI4IDMuNDc2NjQgMjcuNzgzIDMuNTgxMzYgMjUuNzc5MSA1LjI4NDQ2QzI0LjI1NDIgNi41ODA0MyAyMy4wMjQ0IDExLjI0NCAyMy40NzYzIDEzLjMzOEMyMy43OTUyIDE0LjgxNiAyNS4yOTk0IDE1Ljg0OTEgMjYuMzIxMiAxNi44NTlDMjQuMDc2MSAyMC43NjA4IDIwLjgwNyAyMy45OTE2IDE2Ljg1ODYgMjYuMjEwMkMxNS44MzU3IDI1LjE5OTQgMTQuNzg5NyAyMy43MTM2IDEzLjI5NTggMjMuMzk4OEMxMS4xNzExIDIyLjk1MSA2LjQzODE4IDI0LjE2NjcgNS4xMzA0NCAyNS42OTJDMy40OTUxNiAyNy41OTkzIDMuNjc0ODUgMzMuMjAwNCA1LjQwNTU3IDM0Ljc2OTZaIiBzdHJva2U9IndoaXRlIiBzdHJva2Utd2lkdGg9IjEuNSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIi8+Cjwvc3ZnPgo=');
        background-repeat: no-repeat;
        background-position: center;
        box-shadow: 0 16px 32px 2px rgba(97, 97, 97, 0.10), 0 8px 16px 2px rgba(97, 97, 97, 0.10);

        &:hover
        {
            animation: phone 0.9s ease-in-out infinite;
        }

        &:hover::after,
        &:hover::before
        {
            position: absolute;
            top: -20px;
            right: -20px;
            bottom: -20px;
            left: -20px;

            content: '';
            animation: pulse 1.8s linear infinite;

            opacity: 0;
            border: 1px solid var(--green-300);
            border-radius: 50%;
        }

        &:hover::after
        {
            animation-delay: 0.5s;
        }
    }

    &-Connecting-Text
    {
        margin-top: 2.4rem;
        font-family: TT Firs Neue;
        font-size: 2.4rem;
        font-weight: bold;
        font-style: normal;
        line-height: 3.2rem;

        letter-spacing: 0.048rem;

        color: var(--green-300);
    }
}

@keyframes phone
{
    0%,
    100%
    {
        transform: rotate(-20deg);
    }

    50%
    {
        transform: rotate(21deg);
    }
}

@keyframes pulse
{
    0%
    {
        transform: scale(0.5);

        opacity: 0;
    }

    50%
    {
        opacity: 1;
    }

    100%
    {
        transform: scale(1.2);

        opacity: 0;
    }
}
