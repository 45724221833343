.AboutCard
{
    width: 100%;
    padding: 3.2rem;

    background: var(--gray-150);

    @media screen and (max-width: $mobile-media)
    {
        padding-right: 1.6rem;
        padding-bottom: 1.9rem;
        padding-left: 1.6rem;
    }

    &-Header
    {
        margin-bottom: 3.2rem;
    }

    &-Text
    {
        font-size: var(--additional-medium-2);
        font-weight: bold;
        line-height: 1.25;

        letter-spacing: 0.2rem;
    }

    &-Subtitle
    {
        font-size: var(--small);
        font-weight: 400;
        line-height: 1.7;

        margin-top: 0.4rem;

        letter-spacing: 0.02em;

        color: var(--gray-550);
    }

    &-Content
    {
        margin-bottom: 3.2rem;

        list-style-type: none;
    }

    &-Item
    {
        font-size: var(--extra-small);
        line-height: 1.5;

        display: flex;

        padding: 1.2rem 0;

        border-bottom: 0.1rem solid var(--gray-300);

        justify-content: space-between;
    }

    &-Value
    {
        font-weight: 400;

        letter-spacing: 0.025em;
    }

    &-Price
    {
        letter-spacing: 0.01em;

        &_tiny
        {
            font-size: var(--small);
            font-weight: 400;
            line-height: 1.7;

            letter-spacing: 0.02em;
        }

        &_gray
        {
            color: var(--gray-550);
        }

        &_green
        {
            color: var(--green-300);
        }

        &_large
        {
            font-size: var(--additional-medium-2);
            font-weight: bold;
            line-height: 1.3;
        }
    }

    &-Additionally
    {
        margin-top: 3rem;
        padding: 1.6rem;

        background: var(--gray-50);
    }

    &-AdditionallyTitle
    {
        font-size: var(--extra-small);
        font-weight: bold;

        display: flex;

        cursor: pointer;

        color: var(--black);

        gap: 1.6rem;
        justify-content: space-between;
        align-items: center;

        &::after
        {
            display: inline-block;

            width: 2.4rem;
            height: 2.4rem;

            content: '';
            transform: rotate(180deg);

            background: no-repeat 50%  50% url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNNSAxNS41TDEyIDguNUwxOSAxNS41IiBzdHJva2U9IiM1RjYwNjIiIHN0cm9rZS13aWR0aD0iMS41Ii8+PC9zdmc+);
        }

        &_active
        {
            &::after
            {
                transform: rotate(0);
            }
        }
    }

    &-AdditionallyList
    {
        display: none;
        flex-direction: column;

        margin-top: 2rem;

        gap: 1.6rem;

        &_active
        {
            display: flex;
        }
    }

    &-ButtonsWrapper
    {
        font-family: 'TT Firs Neue', Arial, Helvetica, sans-serif;
        display: flex;

        margin-top: 3.2rem;

        flex-wrap: wrap;
        gap: 2rem;

        @media screen and (min-width: $mobile-media)
        {
            gap: 1.4rem;
        }

        @media screen and (min-width: $tablet-media)
        {
            gap: 2.5rem;
        }

        @media screen and (max-width: $tablet-media)
        {
            margin-top: 2.1rem;
        }

        @media screen and (min-width: $medium-desktop-media)
        {
            flex-direction: column;

            gap: 1.6rem;
        }

        .Button
        {
            font-family: 'TT Firs Neue', Arial, Helvetica, sans-serif;

            @media screen and (max-width: $mobile-media)
            {
                flex: 1 1 100%;
            }
        }
    }

    &-ButtonsInner
    {
        display: flex;
        flex-direction: column;

        flex: 1 1 100%;
        gap: 2rem;

        @media screen and (min-width: $mobile-media)
        {
            flex-direction: row;

            //flex-wrap: wrap;
            gap: 1.4rem;
        }

        @media screen and (min-width: $tablet-media)
        {
            gap: 2.5rem;
        }

        @media screen and (min-width: $medium-desktop-media)
        {
            gap: 1.6rem;
        }
    }

    &-Button
    {
        line-height: 2.4rem;

        flex: 1 1 100%;

        /* width: 100%; */

        @media screen and (min-width: $mobile-media)
        {
            flex-basis: 47%;
        }
    }

    &-ListTitle
    {
        font-size: var(--extra-small);
        font-weight: bold;
        line-height: 1.5;
    }

    &-Dropdown
    {
        position: relative;

        display: flex;

        margin-top: 2.4rem;
        margin-bottom: 1.2rem;

        justify-content: space-between;
    }

    &-DropdownIcon
    {
        position: absolute;
        top: 50%;
        right: 0;

        transform: translateY(-50%);
    }
}
