.PageGallery
{
    display: grid;

    grid-gap: 2rem;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: masonry;

    @media screen and (max-width: $mobile-media)
    {
        grid-template-columns: repeat(2, 1fr);
    }

    &-ImgContainer
    {
        position: relative;

        overflow: hidden;

        max-width: 37rem;
        padding: 0.6rem;

        -webkit-box-shadow: 0 1px 8px 0 rgba(34, 60, 80, 0.2);
           -moz-box-shadow: 0 1px 8px 0 rgba(34, 60, 80, 0.2);
                box-shadow: 0 1px 8px 0 rgba(34, 60, 80, 0.2);
    }

    &-Link
    {
        height: 24rem;
    }

    &-Img
    {
        display: block;

        width: 100%;
        height: 100%;
        margin: auto;

        object-fit: cover;
        object-position: center center;
    }
}
