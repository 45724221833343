.FeaturesCard
{
    display: flex;

    width: 23.5%;
    padding: 2.4rem;

    background: var(--gray-150);

    gap: 1.6rem;

    &::before
    {
        display: none !important;
    }

    &-Icon
    {
        display: flex;

        width: 6.4rem;
        min-width: 6.4rem;
        height: 6.4rem;
        min-height: 6.4rem;

        border-radius: 100%;
        background: var(--white);

        justify-content: center;
        align-items: center;

        img
        {
            width: 75%;
            height: 75%;
            margin: auto;
        }
    }

    &-Title
    {
        font-family: 'TT Firs Neue', Arial, Helvetica, sans-serif;
        font-size: var(--extra-small);
        font-weight: bold;
        line-height: 2.4rem;

        margin: auto 0;

        white-space: normal;
        letter-spacing: 0.032rem;
    }

    &-Subtitle
    {
        font-size: var(--extra-small);
        line-height: 1.5;

        letter-spacing: 0.02em;

        color: var(--gray-1050);
    }
}
