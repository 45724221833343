.TopLine
{
    position: absolute;
    z-index: 999;

    width: 100%;

    transition: 0.45s top;

    background-color: var(--white);
    box-shadow: 0 0.8rem 1.6rem 0.2rem rgba(97, 97, 97, 0.1), 0 1.6rem 3.2rem 0.2rem rgba(97, 97, 97, 0.1);

    inset: 1.4rem 0 auto;

    &_allPages
    {
        position: relative;

        inset: auto;
    }

    &_relative
    {
        top: 0;
    }

    &_fixed
    {
        position: fixed;
        top: 14px;
        left: 0;
    }

    &-Wrap
    {
        display: flex;

        max-width: var(--max-wid-project);
        min-height: 5rem;
        margin: 0 auto;
        padding: 0.5rem 0;

        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        column-gap: 0.8rem;  //768
        @include AdaptiveBlock;

        @media screen and (max-width: $medium-mobile-media)
        {
            gap: 0;
        }

        @media (min-width: $mobile-media)
        {
            position: relative;

            column-gap: 2.4rem;
        }

        //480

        @media (min-width: $tablet-media)
        {
            column-gap: 3.2rem;
        }

        //768
        @media (min-width: $medium-desktop-media)
        {
            position: unset;
        }

        @media screen and (max-width: $small-mobile-media)
        {
            padding-right: 1rem;
            padding-left: 1rem;
        }
    }

    &-Right
    {
        display: flex;

        align-items: center;
        justify-content: flex-end;
        order: 20;
        column-gap: 0.8rem;
        flex-grow: 1;

        //@media (min-width: $mobile-media)
        //{
        //    position: relative;
        //
        //    column-gap: 2.4rem;
        //}

        //480

        //@media (min-width: $tablet-media)
        //{
        //    column-gap: 3.2rem;
        //}

        //768
        //@media (min-width: $medium-desktop-media)
        //{
        //    position: unset;
        //}
    }

    .Logo
    {
        //position: absolute;
        //top: 2.6rem;
        //left: 1.6rem;
        //transform: translateY(-50%);
        /* width: 1.7rem; */
        width: 17.8rem;

        background-position: 100% 50%;

        order: 1;

        @media (min-width: $mobile-media)
        {
        }

        //480

        @media (min-width: $desktop-media)
        {
            //position: unset;
            //left: 3.2rem;
        }

        @media (min-width: $large-desktop-media-second)
        {
            //left: 0;
        }

        @media screen and (max-width: $small-mobile-media)
        {
            width: 14rem;

            background-size: contain;
        }
    }

    .Phone
    {
        order: 10;

        @media screen and (max-width: $medium-mobile-media)
        {
            margin-right: 0;
            margin-left: auto;
            white-space: nowrap;
            letter-spacing: 0;
        }
    }

    .Widget
    {
        position: relative;

        display: none;

        margin-left: auto;

        order: 20;

        @media (min-width: $tablet-media)
        {
            display: inline-block;

            margin-left: 0;
        }  //768
    }
}
