.Apartments
{
    width: 100%;
    max-width: var(--wid-project);
    margin: 0 auto;

    &-Map
    {
        height: 50rem;

        @media (max-width: $large-desktop-media-second)
        {
            margin-bottom: 5rem;
        }

        @media screen and (max-width: $desktop-media)
        {
            margin-bottom: 3.4rem;
        }

        @media screen and (max-width: $mobile-media)
        {
            height: 39vh;
        }

        @media screen and (max-width: $small-mobile-media)
        {
            height: 47vh;
        }
    }

    &-Placemark
    {
        font-size: 1.8rem;
        font-weight: bold;
        font-style: normal;

        display: flex;

        width: 5.2rem;
        height: 5.2rem;
        padding: 1.6rem;

        text-align: center;
        letter-spacing: 0.036rem;

        color: var(--green-300);
        border: 4px solid var(--green-300);
        border-radius: 30rem;
        background-color: var(--white);

        justify-content: center;
        align-items: center;
    }
}
