.Bredcrumbs
{
    padding-top: 5.2rem;
    margin-bottom: 1.8rem;

    &-List
    {
        display: flex;

        margin: 0;
        padding: 0;

        list-style: none;

        //flex-wrap: wrap;
        justify-content: flex-start;
        align-items: center;
        gap: 1.3rem;

        @media screen and (max-width: $mobile-media) {
            flex-wrap: wrap;
        }
    }

    &-Item
    {
        font-size: var(--small);

        color: var(--black);

        &:last-child
        {
            overflow: hidden;

            width: 100%;
            max-width: 41.5rem;

            white-space: nowrap;
            text-overflow: ellipsis;

            @media screen and (max-width: $mobile-media) {
                width: auto;
            }
        }
    }

    &-Link
    {
        display: flex;

        text-decoration: none;

        color: var(--gray-1050);

        align-items: center;
        justify-content: space-between;
        gap: 1.4rem;

        &::after
        {
            display: inline-block;

            width: 0.7rem;
            height: 1.2rem;

            content: '';

            background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOCIgaGVpZ2h0PSIxMiIgdmlld0JveD0iMCAwIDggMTIiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTEgMTFMNiA2TDEgMSIgc3Ryb2tlPSIjNzA3MDc1IiBzdHJva2Utd2lkdGg9IjEuNSIvPjwvc3ZnPg==);
        }
    }
}
