.ApartmentsCalc
{
    width: 100%;
    max-width: var(--wid-project);
    margin: 0 auto 3.6rem;

    &-Filters
    {
        display: flex;
        flex-direction: column;

        width: 50%;
    }

    &-FiltersWrapper
    {
        display: flex;

        gap: 3.2rem;
    }

    &-Purchase
    {
        margin-top: 4rem;
    }

    &-PurchaseInner
    {
        display: flex;

        width: 88.6rem;

        justify-content: space-between;
        gap: 3.2rem;
    }

    &-PurchasePole
    {
        width: 50%;
        max-width: 40.6rem;
    }

    &-Payment
    {
        font-size: var(--extra-medium);
        font-weight: 400;

        display: flex;

        justify-content: space-between;
        align-items: center;
    }
}
