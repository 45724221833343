$mobile-flat-image-width: 32%;

.FlatListBox
{
    padding-top: 7rem;

    @include AdaptiveBlock;
    row-gap: 6.4rem;
    column-gap: 6rem;

    @media (max-width: $tablet-media)
    {
        padding-right: 0;
        padding-left: 0;
    }

    @media (max-width: $mobile-media)
    {
        padding-top: 3rem;
    }

    @media (min-width: $second-medium-desktop-media)
    {
        display: flex;

        justify-content: space-between;
    }

    //1407

    //@media (min-width: $desktop-media) {
    //    display: flex;
    //    gap: 6.4rem;
    //} //1024

    .Filters-Block
    {
        @include NoAdaptiveBlock;
    }
}

.FlatList
{
    margin-bottom: 8rem;
    padding-top: 3.2rem;

    flex-grow: 1;

    &-Wrap
    {
        display: flex;

        max-width: var(--wid-project);
        margin-right: auto;
        margin-left: auto;

        column-gap: 8rem;

        @media screen and (max-width: $second-medium-desktop-media)
        {
            flex-direction: column;
        }
    }

    &-Filters
    {
        padding-bottom: 10rem;

        flex: 0 0 23.5%;

        &-Top
        {
            flex-direction: column;

            margin-bottom: 0;

            align-items: center;
        }

        .Filters-Col
        {
            width: 100%;
            margin-bottom: 3.5rem;

            .chosen-single
            {
                span
                {
                    font-weight: bold;
                }
            }
        }
    }

    &-Content
    {
        flex: 1 1 auto;
    }

    &-MetaControls
    {
        display: flex;
        flex-direction: column-reverse;

        padding-right: 1.6rem;
        //padding-top: 1rem;
        padding-left: 1.6rem;

        gap: 1.6rem;
        justify-content: space-between;

        @media screen and (min-width: $mobile-media)
        {
            flex-direction: row;

            gap: 0;
        }

        @media screen and (min-width: $tablet-media)
        {
            justify-content: flex-start;
        }
    }

    &-MetaControlsItem
    {
        font-size: var(--extra-small);
        font-weight: bold;

        display: inline-flex;

        vertical-align: middle;

        align-items: center;

        @media screen and (min-width: $tablet-media)
        {
            margin-right: 3rem;
        }

        &_filter
        {
            cursor: pointer;
        }

        .ParkingSlots-Icon
        {
            margin-right: 1rem;
        }
    }

    &-List
    {
        //margin: 3.5rem 0 0 0;
        margin: 0;

        @media screen and (max-width: $tablet-media)
        {
            margin-top: 5rem;
        }
    }

    &-Item
    {
        display: flex;

        padding: 0;
        padding-right: 7rem;

        border-bottom: 0.1rem solid var( --gray-25 );
        //background-color: var( --gray-150 );

        justify-content: space-between;
        align-items: center;
        column-gap: 3rem;

        @media screen and (min-width: $tablet-media)
        {
            padding-top: 4.7rem;
            padding-bottom: 4.7rem;
        }

        @media screen and (min-width: $desktop-media)
        {
            padding-right: 2rem;
        }

        @media (max-width: $second-medium-desktop-media)
        {
            position: relative;

            padding-left: 2rem;
        }

        @media screen and (max-width: $tablet-media)
        {
            padding: 2rem 2rem 2rem $mobile-flat-image-width;

            justify-content: flex-start;
            flex-wrap: wrap;
            align-items: flex-start;
            gap: 0;
        }

        @media screen and (min-width: $landscape-mobile-media) and (max-width: $desktop-media)
        {
            padding-left: 14rem;
        }

        &:last-child
        {
            border-bottom: 0;
        }

        &_reserved
        {
            position: relative;

            &:before
            {
                position: absolute;
                top: 50%;
                left: 7rem;

                width: 5rem;
                height: 5rem;

                content: '';
                transform: translateY(-50%);

                border-radius: 50%;
            }

            .FlatList
            {
                &-Label
                {
                    color: var( --gray-450 );

                    &_amount
                    {
                        &:before
                        {
                            display: block;
                        }
                    }
                }
            }
        }

        &_assignment
        {
            position: relative;

            background-color: var( --gray-150 );

            &:before
            {
                position: absolute;
                top: 50%;
                left: 7rem;

                width: 5rem;
                height: 5rem;

                content: '';
                transform: translateY(-50%);

                border-radius: 50%;
            }

            .FlatList
            {
                &-Label
                {
                    &_assignment
                    {
                        &:before
                        {
                            display: block;

                            margin-top: -35px;
                        }
                    }
                }
            }
        }
    }

    &-LabelBoxMain
    {
        display: flex;
        //align-items: center;
        flex-direction: column;

        column-gap: 3rem;
        row-gap: 1.6rem;
        justify-content: space-between;

        //flex-grow: 1;

        @media (min-width: $tablet-media)
        {
            flex-direction: row;

            flex-grow: 1;
        }

        //768

        @media (min-width: $desktop-media)
        {
            //flex-grow: 0;
        } //1024
    }

    &-LabelBoxFirst
    {
        display: flex;
        flex-direction: column;

        justify-content: space-between;
        //align-items: center;
        column-gap: 3rem;
        row-gap: 1.2rem;
        flex-grow: 1;

        //@media (min-width: $tablet-media) {
        //    flex-direction: row;
        //}  //768

        @media (min-width: $desktop-media)
        {
            flex-direction: row;
        } //1024
    }

    &-LabelBox
    {
        display: flex;

        align-items: center;
        flex-wrap: wrap;
        gap: 0;

        .FlatList-Label
        {
            white-space: nowrap;
        }

        .FlatList-SubLabel
        {
            display: none;
        }

        @media (min-width: $desktop-media)
        {
            row-gap: 1.6rem;
            column-gap: 3rem;
            flex-basis: 30%;
            justify-content: space-between;
            flex-wrap: nowrap;

            .FlatList-SubLabel
            {
                display: block;
            }

            @media (min-width: $desktop-media)
            {
            } //1024
        } //1024
    }

    &-Label
    {
        font-size: var(--extra-small);
        //padding: 0 3rem;

        padding: 0;

        text-align: left;
        letter-spacing: 0.05rem;

        @media (min-width: $tablet-media)
        {
            white-space: nowrap;
        }

        @media (min-width: $desktop-media)
        {
            font-size: var(--extra-medium );
        }

        @media (max-width: $mobile-media)
        {
            font-size: var(--small);
        }

        &:after
        {
            @include AfterDot;

            @media (max-width: $small-mobile-media)
            {
                margin-right: 0.5rem;
                margin-left: 0.5rem;
            }
        }

        //480

        /* &:nth-child(2)
        {
            white-space: normal;
        } */

        &_big
        {
            font-size: var( --small2 );

            @media (min-width: $tablet-media)
            {
                font-size: var(--extra-medium);
            }

            //480

            &:after
            {
                display: none;
            }
        }

        &_address
        {
            flex: 1 1 33%;
            &:after
            {
                display: none;
            }

            @media screen and (max-width: $mobile-media)
            {
                margin-bottom: 1.6rem;

                flex: 1 1 100%;
            }
        }

        &_floor
        {
            &:after
            {
                display: none;
            }

            @media screen and (max-width: $mobile-media)
            {
                direction: rtl;

                .ObjectCard-Address
                {
                    font-size: inherit !important;

                    display: inline-block;

                    padding: 0 !important;

                    text-transform: lowercase;

                    color: inherit;
                }
            }
        }

        &_image
        {
            position: absolute;
            top: 50%;
            left: 0;

            width: $mobile-flat-image-width;
            height: 90%;
            padding: 0 2rem;

            transform: translateY(-50%);

            flex-shrink: 0;
            flex-basis: 5.4rem;

            @media screen and (min-width: $landscape-mobile-media) and (max-width: $desktop-media)
            {
                width: 15rem;
                height: 70%;
            }

            @media (min-width: $desktop-media)
            {
                position: static;

                height: auto;
                padding: 0 2rem;

                transform: none;

                flex-basis: 12rem;
            }

            @media screen and (max-width: $small-mobile-media)
            {
                padding: 0 2rem;
            }

            //480

            .ObjectCard-Pic
            {
                width: 100%;
                height: 100%;

                object-fit: cover;

                //max-height: 8.8rem;
                //max-height: 5.4rem;

                @media screen and (max-width: $desktop-media)
                {
                    object-fit: contain;
                }
            }

            &:after
            {
                display: none;
            }
        }

        &_title
        {
            font-weight: 400;

            margin-right: auto;

            text-align: left;
            text-decoration: none;

            color: var(--black);

            @media screen and (min-width: $desktop-media)
            {
                font-size: var(--extra-medium );

                padding-left: 3.5rem;
            }

            @media screen and (max-width: $fix-size)
            {
                margin-bottom: 0.75rem;

                flex: 1 1 100%;
            }

            @media screen and (max-width: $small-mobile-media)
            {
                font-size: var( --small2 );
            }

            &:after
            {
                display: none;
            }
        }

        &_area
        {
            @media screen and (min-width: $tablet-media)
            {
                padding-right: 3rem;

                text-align: right;
            }

            & + .FlatList-Label
            {
                &:after
                {
                    display: none;
                }
            }
        }

        &_amount
        {
            font-weight: 400;

            position: relative;

            min-width: 17rem;
            padding-right: 0.5rem;

            @media (max-width: $mobile-media)
            {
                font-weight: 400;

                display: flex;
                flex-direction: column;

                align-items: flex-start;
            }

            @media (min-width: $tablet-media)
            {
                text-align: right;
            }

            @media screen and (min-width: $landscape-mobile-media) and (max-width: $desktop-media)
            {
                /*   position: absolute;
                top: 0;
                right: 6rem;

                display: flex;
                flex-direction: column;

                margin: 0; */
            }

            @media screen and (max-width: $fix-size)
            {
                font-size: var( --extra-medium );
                line-height: 1.7;

                margin-top: 1.5rem;

                flex: 1 1 100%;
            }

            @media screen and (max-width: $small-mobile-media)
            {
                font-size: var( --extra-small );
                line-height: 1.5;
            }

            //768

            &:before
            {
                font-size: var( --small );

                position: absolute;
                top: -2rem;
                right: 0;

                display: none;

                content: 'Бронь';

                color: var( --red );
            }

            &:after
            {
                display: none;
            }
        }

        &_assignment
        {
            position: relative;

            @media (min-width: $tablet-media)
            {
                /* min-width: 22rem; */
                padding-right: 0.5rem;

                text-align: right;
            }

            @media screen and (max-width: $desktop-media)
            {
                order: -1;
            }

            &:before
            {
                font-size: var( --small );

                position: absolute;
                top: -2rem;
                right: 0;

                display: none;

                content: 'Переуступка';

                color: var( --red );
            }
        }

        &_favourite
        {
            position: absolute;
            top: 1.6rem;
            right: 1.6rem;

            min-width: initial;
            padding-left: 0;

            align-self: flex-start;

            @media (min-width: $desktop-media)
            {
                position: static;
            }

            @media (min-width: $tablet-media)
            {
                align-self: center;
            }

            @media screen and (min-width: $landscape-mobile-media) and (max-width: $desktop-media)
            {
                top: 50%;

                transform: translateY(-50%);
            }

            //768


            .Icon_heart
            {
                background-color: transparent;
            }

            &:after
            {
                display: none;
            }
        }

        & > a
        {
            text-decoration: none;
        }
    }

    &-Queue
    {
        font-size: var(--small2);

        margin-bottom: -3rem;
        padding: 1rem 0 0 0;

        color: var(--gray-400);

        @media screen and (min-width: $desktop-media)
        {
            font-size: var(--extra-medium );

            padding-left: 3.5rem;
        }

        @media screen and (max-width: $fix-size)
        {
            margin-bottom: 0.75rem;

            flex: 1 1 100%;
        }

        @media screen and (max-width: $small-mobile-media)
        {
            font-size: var( --small2 );
        }
    }

    &-SubLabel
    {
        font-size: var(--ultra-small);
        //position: absolute;
        //right: 0;
        //bottom: -2rem;

        color: var( --gray-650 );

        @media (min-width: $tablet-media)
        {
            margin-bottom: 1rem;
        }

        @media (min-width: $desktop-media)
        {
            font-size: var( --small );
        }

        @media screen and (min-width: $landscape-mobile-media) and (max-width: $desktop-media)
        {
            margin: 0;
        }

        //480

        @media (max-width: $tablet-media)
        {
            font-size: var(--small);
        }
    }

    &-Power
    {
        font-size: var( --ultra-small );

        display: inline-block;

        margin-left: 0.2rem;

        &_large
        {
            font-size: var( --tiny );
        }
    }

    &-Icon
    {
        width: 2.2rem;
        height: 2.2rem;
        margin-right: 0.6rem;

        background-size: contain;

        @media screen and (max-width: $small-mobile-media)
        {
            width: 1.8rem;
            height: 1.8rem;

            background-size: contain !important;
        }
    }

    &-Actions
    {
        padding: 3.5rem 0;

        text-align: center;
    }

    .ObjectCard-Address
    {
        font-size: var(--ultra-small);
        //font-size: var(--small);

        margin-bottom: 1rem;
        padding-right: 4rem;

        @media (min-width: $tablet-media)
        {
            font-size: var(--small);

            padding-right: 0;
        } //480
    }

    .ObjectCard-Name
    {
        color: var(--black);
    }

    .ObjectCard-Queue
    {
        font-size: var(--small2);

        margin-bottom: -3rem;
        padding: 1rem 0 0 0;

        color: var(--gray-400);

        @media screen and (min-width: $desktop-media)
        {
            font-size: var(--extra-medium );
        }

        @media screen and (max-width: $fix-size)
        {
            margin-bottom: 0.75rem;

            flex: 1 1 100%;
        }

        @media screen and (max-width: $small-mobile-media)
        {
            font-size: var( --small2 );
        }
    }
}
