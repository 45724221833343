.Card
{
    display: flex;
    flex-direction: column;

    width: 100%;
    margin: 0 auto 3.6rem;

    gap: 2.4rem;
    //justify-content: space-between;
    flex-wrap: wrap;
    //1024
    @include ProjectWidth;

    &_stretch
    {
        justify-content: stretch;
        flex: 1 1 100%;
    }

    @media (min-width: $tablet-media)
    {
        flex-direction: row;
    }

    //768

    @media screen and  (min-width: $desktop-media)
    {
        gap: 3.2rem;
    }

    @media screen and  (min-width: $medium-desktop-media)
    {
        gap: 5.2rem;
    }

    @media screen and (max-width: $small-mobile-media)
    {
        margin-bottom: 1.6rem;
    }

    &-Item
    {
        //min-height: 45rem;
        position: relative;

        padding-bottom: 2rem;

        transition: background-color var(--transition-time);
        text-decoration: none;

        background: var(--gray-150);

        @include AdaptiveItemTreeColumn;

        @media (max-width: $mobile-media)
        {
            padding-bottom: 0;
        }

        &.active,
        &:hover
        {
            background-color: var(--gray-50);

            .Card-ImgBlock
            {
                &::after
                {
                    opacity: 0;
                }

                &::before
                {
                    opacity: 0.25;
                }
            }

            .Card-Img
            {
                filter: blur(4px);
            }

            .Card-InformHouse
            {
                transform: translateY(0);

                .Card-Title {
                    margin-top: 6rem;
                }
            }

            .Card-Title
            {
                margin-bottom: 1.6rem;
            }

            .Card-Stage_imgBlock
            {
                top: -0.1rem;

                opacity: 0;
            }
        }

        // hover

        &.active
        {
            background-color: transparent;;

            .Card-InformHouse
            {
                transition-duration: var( --transition-time-slow );
            }

            .Card-Img
            {
                filter: none;

                &Block
                {
                    &::before
                    {
                        transition-duration: var( --transition-time-slow );

                        opacity: 0.35;
                    }
                }
            }
        }
    }

    &-ImgBlock
    {
        position: relative;

        overflow: hidden;

        height: 25.6rem;

        @media (min-width: $mobile-media)
        {
            height: 27.4rem;
        }

        //480

        @media (min-width: $tablet-media)
        {
            height: 31.4rem;
        }

        //768

        //@media (max-width: $desktop-medium) {
        //    height: 28.4rem;
        //} //1400

        //@media (max-width: $tablet) {
        //    height: 21.4rem;
        //} //768



        &::after
        {
            position: absolute;
            z-index: 70;

            height: 11.2rem;

            content: '';
            transition: opacity var(--transition-time);

            opacity: 1;
            background: linear-gradient(180deg, rgba(0, 0, 0, 0) 9.42%, rgba(0, 0, 0, 0.7) 100%);

            inset: auto 0 0;
        }

        &::before
        {
            position: absolute;
            z-index: 70;

            content: '';
            transition: opacity var(--transition-time);

            opacity: 0;
            background: var(--black);

            inset: 0;
        }
    }

    &-Img
    {
        position: absolute;
        z-index: 50;
        left: 50%;

        width: 100%;
        height: 100%;

        transition: filter var(--transition-time);
        transform: translateX(-50%);

        object-fit: cover;
    }

    &-InformHouse
    {
        position: absolute;
        z-index: 100;

        display: flex;
        overflow: hidden;
        flex-direction: column;

        transition: transform var(--transition-time);
        transform: translateY(87.5%);

        inset: 2.4rem;
        justify-content: space-between;
    }

    &-Title
    {
        font-size: var(--additional-medium);
        font-weight: bold;

        margin-bottom: 2.4rem;

        letter-spacing: 0.01rem;

        color: var(--gray-100);

        @media (max-width: $mobile-media)
        {
            font-size: var(--extra-medium);
        }
    }

    &-Comfort
    {
        display: flex;

        gap: 0.6rem;
    }

    &-Quantity
    {
        display: flex;

        justify-content: space-between;
        align-items: center;
    }

    &-QuantityItem
    {
        display: flex;
        flex-direction: column;

        padding: 1.4rem;

        text-align: center;
        letter-spacing: 0.07rem;
        text-transform: uppercase;

        color: var(--white);
        border-right: 0.1rem solid var(--white);

        align-self: flex-start;
        flex-basis: 33%;
        gap: 0.4rem;

        &:last-child
        {
            border: none;
        }
    }

    &-QuantityCount
    {
        font-size: var(--medium);
    }

    &-Inform
    {
        padding: 2.4rem 2.4rem 1rem;

        background-color: var( --gray-150 );

        @media (max-width: $mobile-media)
        {
            padding: 1.6rem 1.6rem 0.9rem;
        }

        @media (max-width: $small-mobile-media)
        {
            padding: 1.6rem 1.7rem 1rem;
        }
    }

    &-Address
    {
        font-size: var(--extra-small);
        font-weight: 400;
        line-height: 1.5;

        margin-bottom: 0.5rem;

        letter-spacing: 0.01rem;

        color: var(--black);
    }

    &-LabelsContainer
    {
        position: absolute;
        z-index: 70;
        top: 1.2rem;
        left: 1.2rem;
        width: calc(100% - 2.4rem);
        display: flex;
        justify-content: space-between;
    }
    &-AnchorContainer
    {
        order:-1
    }
    &-Anchor
    {
        display: block;

        max-height: 5rem;
        margin-bottom: 0.5rem;
        margin-left: -2.5rem;
    }

    &-Stage
    {
        font-size: var(--small);
        font-weight: 400;

        display: inline-block;

        margin-bottom: 1.2rem;

        letter-spacing: 0.12rem;

        color: var(--gray-1100);

        &_imgBlock
        {
            line-height: 2.8rem;

            height: 2.8rem;
            padding: 0 0.8rem;

            transition: all var(--transition-time);
            letter-spacing: 0.06rem;

            color: var(--white);
            border-radius: 2rem;
            background: var(--green-300);
            box-shadow: 0 0.2rem 0.4rem rgba(97, 97, 97, 0.18), 0 0.4rem 0.8rem rgba(97, 97, 97, 0.18);
        }

        &_white
        {
            color: var(--black);
            background: var(--white);
        }

        &_right {
            float: right;
        }
    }

    &-Price
    {
        font-size: var(--additional-small);
        font-weight: bold;

        letter-spacing: 0.02rem;

        color: var(--black);
    }

    &-Count
    {
        font-size: var(--medium);
        font-weight: 400;

        margin-right: 0.6rem;
        margin-left: 0.4rem;
    }
}
