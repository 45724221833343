.PageSlider
{
    margin-bottom: 4.1rem;

    &_ofset
    {
        &_none
        {
            margin-bottom: 0;
        }
    }

    &-Image
    {
        width: calc(100% - 2px);
        max-width: 182.4rem;
        margin: 0 auto;

        //padding-right: 11rem;
        //padding-left: 11rem;

        .slick-prev,
        .slick-next
        {
            z-index: 100;
            top: 50%;

            width: 6.4rem;
            height: 6.4rem;

            transform: translate(0, -50%);

            border: 0.1rem solid var(--black);
            border-radius: 6.4rem;

            &.slick-disabled
            {
                opacity: 0.2;
            }
        }

        .slick-prev
        {
            //left: -11.2rem;
            background: no-repeat 50% 50% url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIHZpZXdCb3g9IjAgMCA0MCA0MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMzQuOTk5NiAxOS45OTk4TDUgMTkuOTk5OE01IDE5Ljk5OThMMTkuOTk5OCA1TTUgMTkuOTk5OEM1IDE5Ljk5OTggMTQuMTQyIDI5LjE0MTggMTkuOTk5OCAzNC45OTk2IiBzdHJva2U9ImJsYWNrIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJzcXVhcmUiLz48L3N2Zz4=);

            &:hover,
            &:focus
            {
                background: var(--black) no-repeat 50% 50% url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIHZpZXdCb3g9IjAgMCA0MCA0MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMzQuOTk5NiAxOS45OTk4TDUgMTkuOTk5OE01IDE5Ljk5OThMMTkuOTk5OCA1TTUgMTkuOTk5OEM1IDE5Ljk5OTggMTQuMTQyIDI5LjE0MTggMTkuOTk5OCAzNC45OTk2IiBzdHJva2U9IndoaXRlIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJzcXVhcmUiLz48L3N2Zz4=);
            }

            &.slick-disabled
            {
                &:hover,
                &:focus
                {
                    background: no-repeat 50% 50% url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIHZpZXdCb3g9IjAgMCA0MCA0MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMzQuOTk5NiAxOS45OTk4TDUgMTkuOTk5OE01IDE5Ljk5OThMMTkuOTk5OCA1TTUgMTkuOTk5OEM1IDE5Ljk5OTggMTQuMTQyIDI5LjE0MTggMTkuOTk5OCAzNC45OTk2IiBzdHJva2U9ImJsYWNrIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJzcXVhcmUiLz48L3N2Zz4=);
                }
            }
        }

        .slick-next
        {
            //right: -11.2rem;
            background: no-repeat 50% 50% url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIHZpZXdCb3g9IjAgMCA0MCA0MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNNS4wMDA0IDE5Ljk5OThMMzUgMTkuOTk5OE0zNSAxOS45OTk4TDIwLjAwMDIgNU0zNSAxOS45OTk4QzM1IDE5Ljk5OTggMjUuODU4IDI5LjE0MTggMjAuMDAwMiAzNC45OTk2IiBzdHJva2U9ImJsYWNrIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJzcXVhcmUiLz48L3N2Zz4=);

            &:hover,
            &:focus
            {
                background: var(--black) no-repeat 50% 50% url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIHZpZXdCb3g9IjAgMCA0MCA0MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNNS4wMDA0IDE5Ljk5OThMMzUgMTkuOTk5OE0zNSAxOS45OTk4TDIwLjAwMDIgNU0zNSAxOS45OTk4QzM1IDE5Ljk5OTggMjUuODU4IDI5LjE0MTggMjAuMDAwMiAzNC45OTk2IiBzdHJva2U9IndoaXRlIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJzcXVhcmUiLz48L3N2Zz4=);
            }

            &.slick-disabled
            {
                &:hover,
                &:focus
                {
                    background: no-repeat 50% 50% url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIHZpZXdCb3g9IjAgMCA0MCA0MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNNS4wMDA0IDE5Ljk5OThMMzUgMTkuOTk5OE0zNSAxOS45OTk4TDIwLjAwMDIgNU0zNSAxOS45OTk4QzM1IDE5Ljk5OTggMjUuODU4IDI5LjE0MTggMjAuMDAwMiAzNC45OTk2IiBzdHJva2U9ImJsYWNrIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJzcXVhcmUiLz48L3N2Zz4=);
                }
            }
        }

        .slick-prev
        {
            //left: 5px;
            left: -10rem;

            [dir='rtl'] &
            {
                right: -125px;
                left: auto;
            }
        }

        .slick-next
        {
            right: 0;

            @media (min-width: $large-desktop-media-second)
            {
                right: -10rem;
            }

            //1200


            [dir='rtl'] &
            {
                right: auto;
                left: -125px;
            }
        }
    }

    &-Nav
    {
        margin-top: -0.5rem;

        .slick-list {
            padding-left: 0 !important;
        }

        .slick-track {
            transform: none !important;
            margin-left: 0 !important;
        }

        .slick-slide img
        {
            border-right: 0.2rem solid transparent;
        }
    }
}
