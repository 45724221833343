@charset 'UTF-8';

// Default Variables

// Slick icon entity codes outputs the following
// "\2190" outputs ascii character "←"
// "\2192" outputs ascii character "→"
// "\2022" outputs ascii character "•"

$slick-font-path: './fonts/' !default;
$slick-font-family: 'slick' !default;
$slick-loader-path: './' !default;
$slick-arrow-color: white !default;
$slick-dot-color: black !default;
$slick-dot-color-active: $slick-dot-color !default;
$slick-prev-character: '\2190' !default;
$slick-next-character: '\2192' !default;
$slick-dot-character: '\2022' !default;
$slick-dot-size: 6px !default;
$slick-opacity-default: 0.75 !default;
$slick-opacity-on-hover: 1 !default;
$slick-opacity-not-active: 0.25 !default;

//@function slick-image-url($url) {
//    @if function-exists(image-url) {
//        @return image-url($url);
//    }
//    @else {
//        @return url($slick-loader-path + $url);
//    }
//}
//
//@function slick-font-url($url) {
//    @if function-exists(font-url) {
//        @return font-url($url);
//    }
//    @else {
//        @return url($slick-font-path + $url);
//    }
//}

/* Slider */

//.slick-list {
//    .slick-loading & {
//        background: #fff slick-image-url("ajax-loader.gif") center center no-repeat;
//    }
//}

/* Icons */
//@if $slick-font-family == "slick" {
//    @font-face {
//        font-family: "slick";
//        src: slick-font-url("slick.eot");
//        src: slick-font-url("slick.eot?#iefix") format("embedded-opentype"), slick-font-url("slick.woff") format("woff"), slick-font-url("slick.ttf") format("truetype"), slick-font-url("slick.svg#slick") format("svg");
//        font-weight: normal;
//        font-style: normal;
//    }
//}

/* Arrows */

.slick-prev,
.slick-next
{
    font-size: 0;
    line-height: 0;

    position: absolute;
    top: 50%;

    display: block;

    width: 20px;
    height: 20px;
    padding: 0;

    cursor: pointer;
    -webkit-transform: translate(0, -50%);
        -ms-transform: translate(0, -50%);
            transform: translate(0, -50%);

    color: transparent;
    border: none;
    outline: none;
    background: transparent;

    &:hover,
    &:focus
    {
        color: transparent;
        outline: none;
        background: transparent;

        &:before
        {
            opacity: $slick-opacity-on-hover;
        }
    }

    &.slick-disabled:before
    {
        opacity: $slick-opacity-not-active;
    }

    &:before
    {
        font-family: $slick-font-family;
        font-size: 20px;
        line-height: 1;

        opacity: $slick-opacity-default;
        color: $slick-arrow-color;

        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
}

.slick-prev
{
    left: -25px;

    [dir='rtl'] &
    {
        right: -25px;
        left: auto;
    }

    &:before
    {
        content: $slick-prev-character;

        [dir='rtl'] &
        {
            content: $slick-next-character;
        }
    }
}

.slick-next
{
    right: -25px;

    [dir='rtl'] &
    {
        right: auto;
        left: -25px;
    }

    &:before
    {
        content: $slick-next-character;

        [dir='rtl'] &
        {
            content: $slick-prev-character;
        }
    }
}

/* Dots */

.slick-dotted.slick-slider
{
    margin-bottom: 30px;
}

.slick-dots
{
    position: absolute;
    bottom: -25px;

    display: block;

    width: 100%;
    margin: 0;
    padding: 0;

    list-style: none;

    text-align: center;

    li
    {
        position: relative;

        display: inline-block;

        width: 20px;
        height: 20px;
        margin: 0 5px;
        padding: 0;

        cursor: pointer;

        button
        {
            font-size: 0;
            line-height: 0;

            display: block;

            width: 20px;
            height: 20px;
            padding: 5px;

            cursor: pointer;

            color: transparent;
            border: 0;
            outline: none;
            background: transparent;

            &:hover,
            &:focus
            {
                outline: none;

                &:before
                {
                    opacity: $slick-opacity-on-hover;
                }
            }

            &:before
            {
                font-family: $slick-font-family;
                font-size: $slick-dot-size;
                line-height: 20px;

                position: absolute;
                top: 0;
                left: 0;

                width: 20px;
                height: 20px;

                content: $slick-dot-character;
                text-align: center;

                opacity: $slick-opacity-not-active;
                color: $slick-dot-color;

                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
            }
        }

        &.slick-active button:before
        {
            opacity: $slick-opacity-default;
            color: $slick-dot-color-active;
        }
    }
}
