.Slider
{
    width: 100%;

    &-Numb
    {
        font-family: 'TT Firs Neue', Arial, Helvetica, sans-serif;
        font-size: var(--extra-small);
        font-weight: 400;
        //line-height: 6.4rem;
        line-height: 4.8rem;

        width: 100%;
        //height: 6.4rem;
        height: 4.8rem;
        margin-bottom: -7px;
        padding: 0 2rem;

        color: var(--black);
        background: var(--gray-150);

        border-radius: 3.2rem;
        border: none;


        @media (min-width: $tablet-media) {
            line-height: 5.6rem;
            height: 5.6rem;
        }  //768

        @media (min-width: $desktop-media) {
            line-height: 6.4rem;
            height: 6.4rem;
        } //1024

    }

    input[type='range']
    {
        width: calc(100% - 6rem);
        height: 0.2rem;
        //background: var(--gray-150);

        border: none;
        outline: none;
        background: var(--green-400);
        display: block;
        margin: 0.4rem auto;

        -webkit-appearance: none !important;
        //overflow: hidden;
        margin-top: 7px;

    }

    input[type='range']::-webkit-slider-thumb
    {
        width: 1.6rem;
        height: 1.6rem;

        cursor: pointer;

        border: 0.2rem solid var(--green-400);
        border-radius: 50%;
        background: var(--green-400);

        -webkit-appearance: none !important;
        //box-shadow: -407px 0 0 400px red;
    }

    input[type='range']::-webkit-slider-thumb:hover
    {
        background: var(--green-400);
    }
}



/*generated with Input range slider CSS style generator (version 20211225)
https://toughengineer.github.io/demo/slider-styler*/
//input[type=range].styled-slider {
//  height: 2.2em;
//  -webkit-appearance: none;
//}
//
///*progress support*/
//input[type=range].styled-slider.slider-progress {
//  --range: calc(var(--max) - var(--min));
//  --ratio: calc((var(--value) - var(--min)) / var(--range));
//  --sx: calc(0.5 * 2em + var(--ratio) * (100% - 2em));
//}
//
//input[type=range].styled-slider:focus {
//  outline: none;
//}
//
///*webkit*/
//input[type=range].styled-slider::-webkit-slider-thumb {
//  -webkit-appearance: none;
//  width: 2em;
//  height: 2em;
//  border-radius: 1em;
//  background: #007cf8;
//  border: none;
//  box-shadow: 0 0 2px black;
//  margin-top: calc(max((1em - 1px - 1px) * 0.5,0px) - 2em * 0.5);
//}
//
//input[type=range].styled-slider::-webkit-slider-runnable-track {
//  height: 1em;
//  border: 1px solid #b2b2b2;
//  border-radius: 0.5em;
//  background: #efefef;
//  box-shadow: none;
//}
//
//input[type=range].styled-slider::-webkit-slider-thumb:hover {
//  background: #0061c3;
//}
//
//input[type=range].styled-slider:hover::-webkit-slider-runnable-track {
//  background: #e5e5e5;
//  border-color: #9a9a9a;
//}
//
//input[type=range].styled-slider::-webkit-slider-thumb:active {
//  background: #2f98f9;
//}
//
//input[type=range].styled-slider:active::-webkit-slider-runnable-track {
//  background: #f5f5f5;
//  border-color: #c1c1c1;
//}
//
//input[type=range].styled-slider.slider-progress::-webkit-slider-runnable-track {
//  background: linear-gradient(#007cf8,#007cf8) 0/var(--sx) 100% no-repeat, #efefef;
//}
//
//input[type=range].styled-slider.slider-progress:hover::-webkit-slider-runnable-track {
//  background: linear-gradient(#0061c3,#0061c3) 0/var(--sx) 100% no-repeat, #e5e5e5;
//}
//
//input[type=range].styled-slider.slider-progress:active::-webkit-slider-runnable-track {
//  background: linear-gradient(#2f98f9,#2f98f9) 0/var(--sx) 100% no-repeat, #f5f5f5;
//}
//
///*mozilla*/
//input[type=range].styled-slider::-moz-range-thumb {
//  width: 2em;
//  height: 2em;
//  border-radius: 1em;
//  background: #007cf8;
//  border: none;
//  box-shadow: 0 0 2px black;
//}
//
//input[type=range].styled-slider::-moz-range-track {
//  height: max(calc(1em - 1px - 1px),0px);
//  border: 1px solid #b2b2b2;
//  border-radius: 0.5em;
//  background: #efefef;
//  box-shadow: none;
//}
//
//input[type=range].styled-slider::-moz-range-thumb:hover {
//  background: #0061c3;
//}
//
//input[type=range].styled-slider:hover::-moz-range-track {
//  background: #e5e5e5;
//  border-color: #9a9a9a;
//}
//
//input[type=range].styled-slider::-moz-range-thumb:active {
//  background: #2f98f9;
//}
//
//input[type=range].styled-slider:active::-moz-range-track {
//  background: #f5f5f5;
//  border-color: #c1c1c1;
//}
//
//input[type=range].styled-slider.slider-progress::-moz-range-track {
//  background: linear-gradient(#007cf8,#007cf8) 0/var(--sx) 100% no-repeat, #efefef;
//}
//
//input[type=range].styled-slider.slider-progress:hover::-moz-range-track {
//  background: linear-gradient(#0061c3,#0061c3) 0/var(--sx) 100% no-repeat, #e5e5e5;
//}
//
//input[type=range].styled-slider.slider-progress:active::-moz-range-track {
//  background: linear-gradient(#2f98f9,#2f98f9) 0/var(--sx) 100% no-repeat, #f5f5f5;
//}
//
///*ms*/
//input[type=range].styled-slider::-ms-fill-upper {
//  background: transparent;
//  border-color: transparent;
//}
//
//input[type=range].styled-slider::-ms-fill-lower {
//  background: transparent;
//  border-color: transparent;
//}
//
//input[type=range].styled-slider::-ms-thumb {
//  width: 2em;
//  height: 2em;
//  border-radius: 1em;
//  background: #007cf8;
//  border: none;
//  box-shadow: 0 0 2px black;
//  margin-top: 0;
//  box-sizing: border-box;
//}
//
//input[type=range].styled-slider::-ms-track {
//  height: 1em;
//  border-radius: 0.5em;
//  background: #efefef;
//  border: 1px solid #b2b2b2;
//  box-shadow: none;
//  box-sizing: border-box;
//}
//
//input[type=range].styled-slider::-ms-thumb:hover {
//  background: #0061c3;
//}
//
//input[type=range].styled-slider:hover::-ms-track {
//  background: #e5e5e5;
//  border-color: #9a9a9a;
//}
//
//input[type=range].styled-slider::-ms-thumb:active {
//  background: #2f98f9;
//}
//
//input[type=range].styled-slider:active::-ms-track {
//  background: #f5f5f5;
//  border-color: #c1c1c1;
//}
//
//input[type=range].styled-slider.slider-progress::-ms-fill-lower {
//  height: max(calc(1em - 1px - 1px),0px);
//  border-radius: 0.5em 0 0 0.5em;
//  margin: -1px 0 -1px -1px;
//  background: #007cf8;
//  border: 1px solid #b2b2b2;
//  border-right-width: 0;
//}
//
//input[type=range].styled-slider.slider-progress:hover::-ms-fill-lower {
//  background: #0061c3;
//  border-color: #9a9a9a;
//}
//
//input[type=range].styled-slider.slider-progress:active::-ms-fill-lower {
//  background: #2f98f9;
//  border-color: #c1c1c1;
//}
