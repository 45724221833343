.Result
{
    @include AdaptiveBlock;
    width: 100%;
    max-width: var(--wid-project);
    margin: -2.3rem auto 3.2rem;



    &-Item
    {
        position: relative;

        display: block;

        padding: 2.6rem 6rem 2.6rem 0;

        text-decoration: none;

        border-bottom: 0.1rem solid var(--gray-25);

        &::after
        {
            position: absolute;
            top: 52%;
            right: 2.4rem;

            display: inline-block;

            width: 1.1rem;
            height: 2.2rem;

            content: '';

            background: no-repeat 50% 50% url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAxNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMSAyM0wxMiAxMkwxIDEiIHN0cm9rZT0iYmxhY2siIHN0cm9rZS13aWR0aD0iMiIvPjwvc3ZnPg==);
        }
    }

    &-Category
    {
        font-size: var(--small);

        margin-bottom: 0.8rem;

        letter-spacing: 0.02rem;

        color: var(--gray-1050);
    }

    &-Title
    {
        font-size: var(--extra-medium);
        font-weight: bold;

        letter-spacing: 0.05rem;

        color: var(--black);
    }
}
