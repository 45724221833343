.EstateObject
{
    width: 100%;
    max-width: var(--wid-project);
    margin: 0 auto 4.8rem;

    @media screen and (max-width: $tablet-media)
    {
        margin-bottom: 3.4em;
    }

    .Tabs-Caption
    {
        margin-left: 5.6rem !important;
    }

    &-Inner
    {
        display: flex;

        margin-bottom: 6.4rem;

        justify-content: space-between;

        @media screen and (max-width: $desktop-media)
        {
            flex-direction: column;

            margin-bottom: 0;
        }
    }

    &-Info
    {
        position: relative;

        display: flex;
        flex-direction: column;

        max-width: 105.6rem;

        flex-grow: 1;

        @media screen and (max-width: $large-desktop-media-second)
        {
            padding-right: 3.2rem;
            padding-left: 3.2rem;
        }

        @media screen and (max-width: $tablet-media)
        {
            padding-right: 2.6rem;
            padding-left: 2.6rem;
        }

        @media screen and (max-width: $mobile-media)
        {
            padding-right: 1.6rem;
            padding-left: 1.6rem;
        }
    }

    &-Actions
    {
        display: flex;

        margin-bottom: 3.2rem;

        justify-content: space-between;
    }

    &-Switchers
    {
        display: flex;

        list-style-type: none;

        gap: 0.8rem;
    }

    &-Plan
    {
        width: 100%;
        max-width: 44.3rem;
        height: 52.4rem;

        &_center
        {
            margin: 0 auto;
        }

        &_left
        {
            margin-left: auto;
        }
    }

    &-About
    {
        max-width: 100%;

        @include AdaptiveBlock;

        @media screen and (min-width: $medium-desktop-media)
        {
            width: 51.2rem;
        }

        @media screen and (max-width: $mobile-media)
        {
            padding-right: 0;
            padding-left: 0;
        }
    }

    &-TabsRoseWind
    {
        display: flex;
        flex-direction: row;

        align-items: flex-start;

        @media screen and (max-width: $second-medium-desktop-media)
        {
            padding-top: 8rem;
        }
    }

    &-RoseWind
    {
        display: flex;

        margin-bottom: 3.2rem;
        margin-left: 3.2rem;

        align-items: center;
        justify-content: flex-end;
        order: 1;

        @media screen and (max-width: $desktop-media)
        {
            position: absolute;
            top: 8rem;
            right: 0;
        }

        @media screen and (max-width: $tablet-media)
        {
            top: 7.2rem;
        }

        &_overlay
        {
            position: absolute;
            z-index: 100;
            top: 2rem;
            right: 2rem;

            @media screen and (max-width: $mobile-media)
            {
                top: 1.5rem;
                right: 1.5rem;
            }
        }
    }

    &-back-button
    {
        position: absolute;

        display: block;

        padding: 0 !important;

        @media screen and (max-width: $fix-size)
        {
            display: none;
        }

        &_mob
        {
            display: none;

            @media screen and (max-width: $fix-size)
            {
                display: block;
            }
        }

        .Arrow
        {
            transform: rotate(180deg);
        }
    }

    &-Img
    {
        display: block;

        max-height: 80rem;

        max-width: 100%;
        margin-right: auto;
        margin-left: auto;

        @media screen and (max-width: $tablet-media)
        {
            max-width: 100%;
        }

        @media screen and (max-width: $mobile-media)
        {
            margin-top: 7rem;
        }
    }
}
