.FinishedProgress
{
    @media screen and (max-width: $desktop-media)
    {
        margin-bottom: 5.1rem;
    }

    @media screen and (max-width: $mobile-media)
    {
        margin-bottom: 3.7rem;
    }

    @media screen and (max-width: $small-mobile-media)
    {
        margin-bottom: 3.1rem;
    }

    &-Container
    {
        display: grid;

        width: 100%;

        grid-auto-flow: column;
        grid-template-rows: 1fr;
        gap: 3.2rem;
        align-items: flex-start;

        @media (min-width: $mobile-media)
        {
            grid-template-columns: 1fr;
        }

        @media (min-width: $tablet-media)
        {
            grid-template-columns: repeat( 2, minmax(0, 1fr) );
        }

        @media screen and (max-width: $medium-desktop-media)
        {
            gap: 2rem;
        }

        @media screen and (max-width: $landscape-mobile-media)
        {
            grid-auto-flow: row;
        }
    }

    &-VideoContainer
    {
        height: 100%;

        flex: auto;
        justify-content: space-evenly;

        .FinishedProgress-Photo,
        iframe
        {
            display: block;

            width: 78.4rem;
            max-width: 100%;
            height: 49.4rem;
            margin: 0 auto;

            flex-shrink: 0;

            @media screen and (max-width: $medium-desktop-media)
            {
                height: 48rem;
            }

            @media screen and (max-width: $tablet-media)
            {
                overflow: hidden;

                width: 100%;
            }

            @media screen and (max-width: $landscape-mobile-media)
            {
                height: auto;
                min-height: 51vw;
            }

            @media screen and (max-width: $small-mobile-media)
            {
                min-height: 51vw;
            }
        }

        .FinishedProgress-PhotoImg
        {
            width: 100%;
            max-width: 100%;
            height: 100%;
        }
    }

    &-PhotoContainer
    {
        display: grid;

        width: 100%;
        height: 100%;

        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr;
        gap: 3.2rem;

        @media screen and (max-width: $medium-desktop-media)
        {
            gap: 2rem;
        }

        @media screen and (max-width: $tablet-media)
        {
            gap: 1.6rem;
        }

        @media screen and (max-width: $landscape-mobile-media)
        {
            grid-template-rows: auto;
        }
    }

    &-Photo
    {
        position: relative;

        display: block;

        height: 23rem;


        @media screen and (max-width: $tablet-media)
        {
            height: 10.5rem;
        }

        @media screen and (max-width: $landscape-mobile-media)
        {
            height: auto;
            max-height: 20rem;
        }

        &:nth-child(3)
        {
            @media screen and (max-width: $landscape-mobile-media)
            {
                display: none;
            }
        }

        &:nth-child(4)
        {
            @media screen and (max-width: $landscape-mobile-media)
            {
                height: auto;
                min-height: 51vw;

                grid-column: 1 / -1;
            }

            @media screen and (max-width: $small-mobile-media)
            {
                min-height: 51vw;
            }
        }

        &_hidden
        {
            display: none;
        }
    }

    &-PhotoImg
    {
        display: block;

        width: 37.6rem;
        max-width: 100%;
        height: 100%;

        object-fit: cover;

        @media screen and (max-width: $landscape-mobile-media)
        {
            width: 100%;
        }
    }

    &-ShowAll
    {
        font-size: var(--extra-small);
        line-height: 23rem;

        position: absolute;
        top: 0;

        display: block;

        width: 37.6rem;
        max-width: 100%;
        height: 23rem;
        padding-left: 25%;

        text-align: left;

        color: var(--white);
        background-color: rgba(0, 0, 0, 0.40);
        background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNTQiIGhlaWdodD0iNTQiIHZpZXdCb3g9IjAgMCA1NCA1NCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE4LjAwMDIgMjYuOTk5OUwzNiAyNi45OTk5TTM2IDI2Ljk5OTlMMjcuMDAwMSAxOE0zNiAyNi45OTk5QzM2IDI2Ljk5OTkgMzAuNTE0OCAzMi40ODUxIDI3LjAwMDEgMzUuOTk5OCIgc3Ryb2tlPSJ3aGl0ZSIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbGluZWNhcD0ic3F1YXJlIi8+CjxyZWN0IHg9IjUzLjUiIHk9IjAuNSIgd2lkdGg9IjUzIiBoZWlnaHQ9IjUzIiByeD0iMjYuNSIgdHJhbnNmb3JtPSJyb3RhdGUoOTAgNTMuNSAwLjUpIiBzdHJva2U9IndoaXRlIi8+Cjwvc3ZnPgo=);
        background-repeat: no-repeat ;
        background-position: 70% 50% ;

        @media screen and (max-width: $large-desktop-media-second)
        {
            padding-left: 22%;

            background-position: 72% center;
        }

        @media screen and (max-width: $second-medium-desktop-media)
        {
            padding-left: 15%;

            background-position: 76% center;
        }

        
        @media screen and (max-width: $medium-desktop-media)
        {
          padding-left: 10%;
          background-position: 85% center;
        }


        @media screen and (max-width: $desktop-media)
        {
          padding-left: 0;
          text-align: center;
          background-position: 150% center;
        }

        @media screen and (max-width: $tablet-media)
        {
            font-size: var( --small );
            line-height: 10.5rem;
            text-align: left;

            height: 10.5rem;
            padding-left: 1rem;

            background-position: 90% center;
            background-size: 4rem;
        }

        @media screen and (max-width: $landscape-mobile-media)
        {
            font-size: var( --extra-medium );
            line-height: 51vw;

            width: 100%;
            height: 100%;
            padding-right: 45%;

            text-align: right;

            background-position: 66% center;
        }

        @media screen and (max-width: $mobile-media)
        {
            padding-right: 35%;

            background-position: 79% center;
        }

        @media screen and (max-width: $small-mobile-media)
        {
            line-height: 52vw;
        }
    }
}
