.GridContent
{
    //flex-direction: column;

    //width: 100%;
    //max-width: var(--wid-project);

    &-Row
    {

        border-top: 0.1rem solid var( --gray-25 );

        @media (min-width: $desktop-media) {
            display: flex;
        } //1024

        gap: 5rem;
        //justify-content: space-between;

        &:first-child
        {
            border-top: 0;

            .GridContent-Cell
            {
                padding-top: 0;
            }
        }

        &:last-child
        {
            .GridContent-Cell
            {
                padding-bottom: 0;
            }
        }
    }

    &-Cell
    {
        font-size: var( --extra-small );
        line-height: 1.48;

        max-width: 100%;
        padding-top: 4.8rem;

        padding-bottom: 2.4rem;

        color: var( --black );

        flex: 1 1 70%;

        @media (min-width: $mobile-media) {
            padding-bottom: 3.2rem;
        } //480

        @media (min-width: $desktop-media) {
            padding-bottom: 4.7rem;
        } //1024

        & + &
        {

            @media (min-width: $desktop-media) {
                padding-left: 3.2rem;

                border-left: 0.1rem solid var( --gray-25 );
            } //1024
        }

        &_title
        {
            font-size: var(--additional-medium-2 );


            font-weight: 400;
            line-height: 1.2;

            color: var( --black );

            flex: 0 0 12.3%;
            min-width: 15rem;

            @media (min-width: $mobile-media) {
                font-size: var(--tiny-large );
            } //480
        }

        a
        {
            font-weight: bold;

            color: var( --green-300 );
        }
    }
}
