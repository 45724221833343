.Questions
{

    @include AdaptiveBlock;
    display: flex;

    width: 100%;
    max-width: 78.4rem;
    margin: 6.4rem auto 11.3rem;

    flex-wrap: wrap;
    gap: 3.2rem;

    &-Info
    {
        display: flex;
        flex-direction: column;

        width: 100%;
        max-width: 24rem;

        gap: 2.4rem;
    }

    &-Title
    {
        font-size: var(--extra-large);
        font-weight: bold;
        line-height: 1.19;

        letter-spacing: 0.13rem;

        color: var(--black);

        @media screen and (max-width: $mobile-media) {
            font-size: var(--extra-medium);
        }
    }

    &-Text
    {
        font-size: var(--extra-medium);
        line-height: 1.4;

        @media screen and (max-width: $mobile-media) {
            font-size: var(--extra-small);
        }
    }

    &-Form
    {
        display: flex;

        width: 100%;
        max-width: 51.2rem;
        padding-bottom: 0.9rem;

        flex-wrap: wrap;
        justify-content: space-between;
        align-items: flex-end;
        gap: 2rem;

        @media screen and (max-width: $mobile-media) {
            flex-wrap: nowrap;
        }
    }

    &-TextFieldBlock
    {
        display: flex;
        flex-direction: column;

        justify-content: flex-end;
        gap: 2.4rem;

        flex-basis: 37.5rem;

        @media screen and (max-width: $landscape-mobile-media) {
            flex-basis: unset;
        }
    }

    //Input start
    &-TextField
    {
        position: relative;

        overflow: hidden;

        width: 100%;
        height: 6.4rem;
    }

    &-Input
    {
        font-size: var(--extra-medium);
        font-weight: 400;

        position: relative;
        z-index: 30;

        width: 100%;
        height: 100%;

        white-space: nowrap;
        text-overflow: ellipsis;

        border: transparent;
        border-bottom: 0.2rem solid var(--black);
        background: transparent;

        &:hover
        {
            border-color: var(--black);
        }

        &:focus
        {
            border-color: var(--black);
            outline: none;
        }

        &::placeholder
        {
            font-size: var(--medium);
        }

        &:focus + .Questions-Label
        {
            font-size: var(--tiny);

            top: 0.2rem;

            color: var(--gray-600);
        }

        &_active + .Questions-Label
        {
            font-size: var(--tiny);

            top: 0.2rem;
        }
    }

    &-Label
    {
        font-size: var(--extra-medium);

        position: absolute;
        z-index: 20;
        top: 1.9rem;
        left: 0;

        overflow: hidden;

        width: calc(100%);

        transition: all var(--transition-time);
        white-space: nowrap;
        text-overflow: ellipsis;

        color: var(--black);
    }

    //Input end

    &-Button
    {
        margin-bottom: 0.2rem;

        @media screen and (max-width: $mobile-media) {
            flex: 0 0 7rem;
            height: 7rem;
        }
    }
}
