.MapOnMainContainer
{
    position: absolute;
    z-index: 998;

    display: none;

    width: 100%;
    height: 100%;

    ymaps[class*="image-with-content"],
    ymaps[class*="svg-icon "] {
        &:has([data-placemark-city])         
        {
            padding-bottom: 0;
            box-sizing: border-box;
            transform: translateY(1rem);                
            /* min-width: 16rem; */
            display: block;
            cursor: pointer;
            min-height: 3.4rem;
            min-height: 52px;

            @media screen and (max-width: $mobile-media) {
                transform: translateY( .25rem );
            }
        }

        &:has([data-placemark-city="Симферополь"])
        {
            transform: translateY(2rem);
        }

         &:has([data-placemark-city="Алушта"]),
         &:has([data-placemark-city="Севастополь"]) 
         {
            transform: translateY(.2rem);

            @media screen and (max-width: $mobile-media) {
                transform: translateY( -1.25rem );
            }
         }

         &:has([data-placemark-city="Бахчисарай"]) {
            transform: translateY(.7rem);
        }

        &:has([data-placemark-city="Евпатория"]) {
            transform: translateY(.1rem);
        }

        &:has([data-placemark-city="Феодосия"]) {
            transform: translateY(1.5rem);
        }
 

     /*    ymaps[class*="svg-icon-content"],
        ymaps:not( [class] ) {
            min-width: 100%;
            min-height: 100%;
        } */
    }   

    ymaps[class*='balloon__content']
    {
        margin: 0;
        padding: 0;

        ymaps[id^='id_']
        {
            width: 37.6rem !important;

            @media screen and (max-width: 40rem)
            {
                width: 100% !important;
                max-width: 100%;
            }
        }
    }

    ymaps[class*='balloon_layout_panel']
    {
        @media screen and (max-width: $mobile-media)
        {
            padding-left: 0;
        }
    }

    ymaps[class*='balloon__close-button']
    {
        @media screen and (max-width: $mobile-media)
        {
            height: 30px;

            opacity: 1;
            background-color: white;
        }
    }

    ymaps[class*='clusterSmallIcon']
    {
        background-image: none;

        /* filter: hue-rotate(34deg) saturate(0.7) contrast(1.1); */

        @media screen and (max-width: $mobile-media)
        {
            top: -8px !important;
            left: -8px !important;

            width: 32px !important;
            height: 32px !important;

            background-size: 100%;
        }

        ymaps[class*='svg-icon-content']
        {
            @media screen and (max-width: $mobile-media)
            {
                top: 9px !important;

                width: 100% !important;
                height: 50% !important;
            }
        }
    }

    &-Close
    {
        font-size: var(--extra-small);

        position: absolute;
        z-index: 9998;
        right: 10%;

        display: block;

        margin-top: 10rem;
        padding: 1.2rem 5.8rem 1.2rem 2.4rem;

        cursor: pointer;
        letter-spacing: 0.02rem;

        color: var(--white);
        border: 0;
        border-radius: 24px;
        background-color: var(--green-300);
        background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEwLjU0NTUgMTIuMDAwMUw0LjAwMDE2IDE4LjU0NTRMNS40NTQ2OSAyMEwxMiAxMy40NTQ2TDE4LjU0NTMgMjBMMTkuOTk5OCAxOC41NDU1TDEzLjQ1NDUgMTIuMDAwMUwyMCA1LjQ1NDU0TDE4LjU0NTUgNEwxMiAxMC41NDU1TDUuNDU0NTMgNC4wMDAwMkw0IDUuNDU0NTZMMTAuNTQ1NSAxMi4wMDAxWiIgZmlsbD0id2hpdGUiLz4KPC9zdmc+Cg==);
        background-repeat: no-repeat;
        background-position: 90% 50% ;
        box-shadow: 0 0 16px 2px rgba(97, 97, 97, 0.03), 0 6px 40px rgba(97, 97, 97, 0.25);

        appearance: none;

        @media screen and (max-width: $mobile-media)
        {
            right: 2%;

            margin-top: 6rem;
        }
    }
}

.MapPopup
{
    /* overflow: hidden; */
    /* width: 37.6rem; */
    min-height: 450px;

    width: 100%;
    height: 100%;

    img
    {
        width: 37.6rem;

        @media screen and (max-width: 40rem)
        {
            width: 100%;
        }

        @media screen and (min-width: $mobile-media)
        {
            margin-top: 4rem;
        }

        @media screen and (max-width: 40rem)
        {
            max-width: 100%;
        }
    }

    .MapPopupPlacemark
    {
        &-Number
        {
            color: var(--white);
        }
    }

    .MapPopupTitle,
    .MapPopupAddress,
    .MapPopupTerm,
    .MapPopupPrice
    {
        font-weight: 400;
        font-style: normal;

        margin-right: 2.4rem;
        margin-left: 2.4rem;
    }

    .MapPopupTitle
    {
        font-size: var(--medium);
        font-weight: bold;
        line-height: 3.2rem;

        margin-top: 1.6rem;

        letter-spacing: 0.01rem;

        &-Link
        {
            color: inherit;
        }
    }

    .MapPopupAddress
    {
        font-size: var(--extra-small);
        line-height: 2.4rem;

        margin-top: 1.2rem;

        letter-spacing: 0.01rem;
    }

    .MapPopupTerm
    {
        font-size: var(--extra-small);
        line-height: 2.4rem;

        letter-spacing: 0.01em;

        color: var(--gray-1100);
    }

    .MapPopupPrice
    {
        font-size: var(--additional-small);
        font-weight: bold;
        line-height: 2.6rem;

        margin-top: 1.6rem;
        margin-bottom: 2.4rem;

        letter-spacing: 0.01rem;
    }
}

.ObjectPlacemark
{
    font-size: var(--extra-small);
    font-weight: 400;
    font-style: normal;
    line-height: 2.8rem;

    display: flex;
    flex-direction: row;

    width: fit-content;
    padding: 0.25rem 0.4rem;

    letter-spacing: 0.02em;

    border-radius: 2.4rem;
    background: white;
    box-shadow: 0 4px 8px rgba(97, 97, 97, 0.14), 0 8px 16px rgba(97, 97, 97, 0.14);

    flex-wrap: nowrap;

    &-Number
    {
        line-height: 28px;

        display: inline-block;

        width: 28px;
        height: 28px;

        text-align: center;

        color: white;
        border-radius: 100px;

       
      

          &[data-items-initial="1"]:not([data-items="1"]),
          &[data-items-initial="2"]:not([data-items="2"]),
          &[data-items-initial="3"]:not([data-items="3"]),
          &[data-items-initial="4"]:not([data-items="4"]),
          &[data-items-initial="5"]:not([data-items="5"]),
          &[data-items-initial="6"]:not([data-items="6"]),
          &[data-items-initial="7"]:not([data-items="7"]) {
            & ~ .ObjectPlacemark-Price {
              display: none;
            } 
          }
        
    }

    &-Price
    {
        display: inline-block;

        margin-right: 8px;
        margin-left: 8px;

        white-space: nowrap;;
    }
}
