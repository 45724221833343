.Projects
{
    &_indent
    {
        &_bottom
        {
            margin-bottom: 11.2rem;
        }
    }

    .Wrapper-ButtonPosit {
        margin-left: auto;
        margin-right: auto;
    }
}