html
{
    font-size: 62.5%;

    width: 100%;
    height: 100%;

    scroll-behavior: smooth;
}

//@mixin transition-time($property: all, $timing-function: ease-in-out) {
//  transition: $property .2s $timing-function 0s;
//}

//.gfjkdg {
//  transform: translateY(-50%);
//
//  @include transition-time(height);
//}


//.trr
//{
//
//  word-wrap: normal;
//  -webkit-hyphens: none;
//  hyphens: none;
//
//  overflow-wrap: normal;
//  -ms-hyphens: none;
//}

*
{
    box-sizing: border-box;
    margin: 0;
    padding: 0;

    //box-shadow: 0 0 1px #f00 !important;
    ////background: green;
    //&:before,
    //&:after
    //{
    //     box-shadow: 0 0 1px #f00 !important;
    //}
}


//Size Variables
//$wid-project: 1120px;
//$mobile-media: 480px;
//$tablet-media: 768px;
//$desktop-media: 1024px;
//$fix-size: 1120px;
//$medium-desktop-media: 1200px;
//$second-medium-desktop-media: 1407px;
//$large-desktop-media: 1600px;
//$large-desktop-media-second: 1664px;
//$full-desktop-media: 1920px;

//@media (min-width: $mobile-media) {} //480
//@media (min-width: $tablet-media) {}  //768
//@media (min-width: $desktop-media) {} //1024
//@media (min-width: $fix-size) {} //1120
//@media (min-width: $medium-desktop-media) {} //1200
//@media (min-width: $second-medium-desktop-media) {} //1407
//@media (min-width: $large-desktop-media) {} //1600
//@media (min-width: $large-desktop-media-second) {} //1664
//@media (min-width: $full-desktop-media) {} //1920

@mixin ProjectWidth
{
    max-width: var(--wid-project);
}

@mixin AdaptiveContainer
{
    display: flex;

    width: 100%;
    max-width: var(--wid-project);
    padding: 0 1.6rem;

    flex-wrap: wrap;
    justify-content: center;
    gap: 1.6rem;

    @media (min-width: $tablet-media)
    {
        padding: 0 1.6rem;

        gap: 2.4rem;
    }

    //768
    @media (min-width: $desktop-media)
    {
        padding: 0 2.4rem;

        gap: 3.6rem 3.2rem;
    }

    //1024
    @media (min-width: $large-desktop-media)
    {
        padding: 0;
    } //1600
}

@mixin AdaptiveItemTreeColumn
{
    width: 100%;
    max-width: 51.2rem;

    @media (min-width: $tablet-media)
    {
        width: 47%;
    }

    //768

    //@media (min-width: $desktop-media)
    //{
    //    width: 32%;
    //}

    //1024

    @media (min-width: $medium-desktop-media)
    {
        width: 30%;
    } //1200
}


@mixin AdaptiveBlock
{
    width: 100%;
    max-width: var(--max-wid-project);
    margin-right: auto;
    margin-left: auto;
    padding-right: 1.6rem;
    padding-left: 1.6rem;

    @media (min-width: $tablet-media)
    {
        padding-right: 2.4rem;
        padding-left: 2.4rem;
    }

    //768

    @media (min-width: $desktop-media)
    {
        padding-right: 3.2rem;
        padding-left: 3.2rem;
    }

    @media (min-width: $large-desktop-media-second)
    {
        padding-right: 0;
        padding-left: 0;
    } //1664
}

@mixin CollapsedText
{
    position: relative;

    overflow: hidden;

    max-height: 18rem;

    &:after
    {
        position: absolute;
        bottom: 0;
        left: 0;

        display: block;

        width: 100%;
        height: 10rem;

        content: '';

        background: linear-gradient( to bottom, rgba(255,255,255, 0 ) 0%, rgba(255,255,255, 0.9 ) 70%, rgba(255,255,255, 1 ) 100% );
    }

    &.active
    {
        max-height: initial;

        &:after
        {
            display: none;
        }
    }
}

@mixin ShowAllText
{
    font-size: var(--extra-small);

    position: relative;

    margin-bottom: 8rem;

    cursor: pointer;
    text-align: left;

    /* color: var(--gray-250);
    background: rgb(255,255,255);
    background:    -moz-linear-gradient(0deg, rgba(255,255,255,1) 39%, rgba(255,255,255,0) 100%);
    background: -webkit-linear-gradient(0deg, rgba(255,255,255,1) 39%, rgba(255,255,255,0) 100%);
    background:         linear-gradient(0deg, rgba(255,255,255,1) 39%, rgba(255,255,255,0) 100%); */

    @media screen and (max-width: $medium-desktop-media)
    {
        margin-bottom: 2rem;
    }

    .Button
    {
        font-family: 'TT Firs Neue', Arial, Helvetica, sans-serif;

        position: relative;

        padding-right: 5rem;

        background-image: url('data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9IjY0cHgiIHdpZHRoPSI2NHB4IiB2ZXJzaW9uPSIxLjEiIGlkPSJDYXBhXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHZpZXdCb3g9Ii0xNC44MyAtMTQuODMgMjE1LjAwIDIxNS4wMCIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSIgZmlsbD0iIzAwMDAwMCIgc3Ryb2tlPSIjMDAwMDAwIiBzdHJva2Utd2lkdGg9IjEyLjA0NzM2MDAwMDAwMDAwMSI+PGcgaWQ9IlNWR1JlcG9fYmdDYXJyaWVyIiBzdHJva2Utd2lkdGg9IjAiPjwvZz48ZyBpZD0iU1ZHUmVwb190cmFjZXJDYXJyaWVyIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIHN0cm9rZT0iI0NDQ0NDQyIgc3Ryb2tlLXdpZHRoPSIwLjM3MDY4OCI+PC9nPjxnIGlkPSJTVkdSZXBvX2ljb25DYXJyaWVyIj4gPGc+IDxnPiA8cGF0aCBzdHlsZT0iZmlsbDojMDEwMDAyOyIgZD0iTTkyLjY3MiwxNDQuMzczYy0yLjc1MiwwLTUuNDkzLTEuMDQ0LTcuNTkzLTMuMTM4TDMuMTQ1LDU5LjMwMWMtNC4xOTQtNC4xOTktNC4xOTQtMTAuOTkyLDAtMTUuMTggYzQuMTk0LTQuMTk5LDEwLjk4Ny00LjE5OSwxNS4xOCwwbDc0LjM0Nyw3NC4zNDFsNzQuMzQ3LTc0LjM0MWM0LjE5NC00LjE5OSwxMC45ODctNC4xOTksMTUuMTgsMCBjNC4xOTQsNC4xOTQsNC4xOTQsMTAuOTgxLDAsMTUuMThsLTgxLjkzOSw4MS45MzRDOTguMTY2LDE0My4zMjksOTUuNDE5LDE0NC4zNzMsOTIuNjcyLDE0NC4zNzN6Ij48L3BhdGg+IDwvZz4gPC9nPiA8L2c+PC9zdmc+');
        background-repeat: no-repeat;
        background-position: 85% center;
        background-size: 1.25rem 1.25rem;

        &:hover
        {
            background-image: url('data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9IjY0cHgiIHdpZHRoPSI2NHB4IiB2ZXJzaW9uPSIxLjEiIGlkPSJDYXBhXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHZpZXdCb3g9Ii0xNC44MyAtMTQuODMgMjE1LjAwIDIxNS4wMCIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSIgZmlsbD0iI2ZmZmZmZiIgc3Ryb2tlPSIjZmZmZmZmIiBzdHJva2Utd2lkdGg9IjEyLjA0NzM2MDAwMDAwMDAwMSIgdHJhbnNmb3JtPSJyb3RhdGUoMCkiPjxnIGlkPSJTVkdSZXBvX2JnQ2FycmllciIgc3Ryb2tlLXdpZHRoPSIwIj48L2c+PGcgaWQ9IlNWR1JlcG9fdHJhY2VyQ2FycmllciIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIiBzdHJva2U9IiNDQ0NDQ0MiIHN0cm9rZS13aWR0aD0iMC4zNzA2ODgiPjwvZz48ZyBpZD0iU1ZHUmVwb19pY29uQ2FycmllciI+IDxnPiA8Zz4gPHBhdGggc3R5bGU9ImZpbGw6I2ZmZmZmZjsiIGQ9Ik05Mi42NzIsMTQ0LjM3M2MtMi43NTIsMC01LjQ5My0xLjA0NC03LjU5My0zLjEzOEwzLjE0NSw1OS4zMDFjLTQuMTk0LTQuMTk5LTQuMTk0LTEwLjk5MiwwLTE1LjE4IGM0LjE5NC00LjE5OSwxMC45ODctNC4xOTksMTUuMTgsMGw3NC4zNDcsNzQuMzQxbDc0LjM0Ny03NC4zNDFjNC4xOTQtNC4xOTksMTAuOTg3LTQuMTk5LDE1LjE4LDAgYzQuMTk0LDQuMTk0LDQuMTk0LDEwLjk4MSwwLDE1LjE4bC04MS45MzksODEuOTM0Qzk4LjE2NiwxNDMuMzI5LDk1LjQxOSwxNDQuMzczLDkyLjY3MiwxNDQuMzczeiI+PC9wYXRoPiA8L2c+IDwvZz4gPC9nPjwvc3ZnPg==');
            background-repeat: no-repeat;
            background-position: 85% center;
            background-size: 1.25rem 1.25rem;
        }

        &:before
        {
            content: 'Читать всё';
        }
    }

    &.active
    {
        margin-top: 2rem;

        .Button
        {
            background-image: url('data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9IjY0cHgiIHdpZHRoPSI2NHB4IiB2ZXJzaW9uPSIxLjEiIGlkPSJDYXBhXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHZpZXdCb3g9Ii0xNC44MyAtMTQuODMgMjE1LjAwIDIxNS4wMCIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSIgZmlsbD0iIzAwMDAwMCIgc3Ryb2tlPSIjMDAwMDAwIiBzdHJva2Utd2lkdGg9IjEyLjA0NzM2MDAwMDAwMDAwMSIgdHJhbnNmb3JtPSJyb3RhdGUoMTgwKSI+PGcgaWQ9IlNWR1JlcG9fYmdDYXJyaWVyIiBzdHJva2Utd2lkdGg9IjAiPjwvZz48ZyBpZD0iU1ZHUmVwb190cmFjZXJDYXJyaWVyIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIHN0cm9rZT0iI0NDQ0NDQyIgc3Ryb2tlLXdpZHRoPSIwLjM3MDY4OCI+PC9nPjxnIGlkPSJTVkdSZXBvX2ljb25DYXJyaWVyIj4gPGc+IDxnPiA8cGF0aCBzdHlsZT0iZmlsbDojMDAwMDAwOyIgZD0iTTkyLjY3MiwxNDQuMzczYy0yLjc1MiwwLTUuNDkzLTEuMDQ0LTcuNTkzLTMuMTM4TDMuMTQ1LDU5LjMwMWMtNC4xOTQtNC4xOTktNC4xOTQtMTAuOTkyLDAtMTUuMTggYzQuMTk0LTQuMTk5LDEwLjk4Ny00LjE5OSwxNS4xOCwwbDc0LjM0Nyw3NC4zNDFsNzQuMzQ3LTc0LjM0MWM0LjE5NC00LjE5OSwxMC45ODctNC4xOTksMTUuMTgsMCBjNC4xOTQsNC4xOTQsNC4xOTQsMTAuOTgxLDAsMTUuMThsLTgxLjkzOSw4MS45MzRDOTguMTY2LDE0My4zMjksOTUuNDE5LDE0NC4zNzMsOTIuNjcyLDE0NC4zNzN6Ij48L3BhdGg+IDwvZz4gPC9nPiA8L2c+PC9zdmc+');

            &:hover
            {
                @media screen and (pointer: fine)
                {
                    background-image: url('data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9IjY0cHgiIHdpZHRoPSI2NHB4IiB2ZXJzaW9uPSIxLjEiIGlkPSJDYXBhXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHZpZXdCb3g9Ii0xNC44MyAtMTQuODMgMjE1LjAwIDIxNS4wMCIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSIgZmlsbD0iI2ZmZmZmZiIgc3Ryb2tlPSIjZmZmZmZmIiBzdHJva2Utd2lkdGg9IjEyLjA0NzM2MDAwMDAwMDAwMSIgdHJhbnNmb3JtPSJyb3RhdGUoMTgwKSI+PGcgaWQ9IlNWR1JlcG9fYmdDYXJyaWVyIiBzdHJva2Utd2lkdGg9IjAiPjwvZz48ZyBpZD0iU1ZHUmVwb190cmFjZXJDYXJyaWVyIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIHN0cm9rZT0iI0NDQ0NDQyIgc3Ryb2tlLXdpZHRoPSIwLjM3MDY4OCI+PC9nPjxnIGlkPSJTVkdSZXBvX2ljb25DYXJyaWVyIj4gPGc+IDxnPiA8cGF0aCBzdHlsZT0iZmlsbDojZmZmZmZmOyIgZD0iTTkyLjY3MiwxNDQuMzczYy0yLjc1MiwwLTUuNDkzLTEuMDQ0LTcuNTkzLTMuMTM4TDMuMTQ1LDU5LjMwMWMtNC4xOTQtNC4xOTktNC4xOTQtMTAuOTkyLDAtMTUuMTggYzQuMTk0LTQuMTk5LDEwLjk4Ny00LjE5OSwxNS4xOCwwbDc0LjM0Nyw3NC4zNDFsNzQuMzQ3LTc0LjM0MWM0LjE5NC00LjE5OSwxMC45ODctNC4xOTksMTUuMTgsMCBjNC4xOTQsNC4xOTQsNC4xOTQsMTAuOTgxLDAsMTUuMThsLTgxLjkzOSw4MS45MzRDOTguMTY2LDE0My4zMjksOTUuNDE5LDE0NC4zNzMsOTIuNjcyLDE0NC4zNzN6Ij48L3BhdGg+IDwvZz4gPC9nPiA8L2c+PC9zdmc+');
                }
            }

            &:active,
            &:focus {
                  @media screen and (pointer: coarse)
                {
                    background-image: url('data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9IjY0cHgiIHdpZHRoPSI2NHB4IiB2ZXJzaW9uPSIxLjEiIGlkPSJDYXBhXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHZpZXdCb3g9Ii0xNC44MyAtMTQuODMgMjE1LjAwIDIxNS4wMCIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSIgZmlsbD0iI2ZmZmZmZiIgc3Ryb2tlPSIjZmZmZmZmIiBzdHJva2Utd2lkdGg9IjEyLjA0NzM2MDAwMDAwMDAwMSIgdHJhbnNmb3JtPSJyb3RhdGUoMTgwKSI+PGcgaWQ9IlNWR1JlcG9fYmdDYXJyaWVyIiBzdHJva2Utd2lkdGg9IjAiPjwvZz48ZyBpZD0iU1ZHUmVwb190cmFjZXJDYXJyaWVyIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIHN0cm9rZT0iI0NDQ0NDQyIgc3Ryb2tlLXdpZHRoPSIwLjM3MDY4OCI+PC9nPjxnIGlkPSJTVkdSZXBvX2ljb25DYXJyaWVyIj4gPGc+IDxnPiA8cGF0aCBzdHlsZT0iZmlsbDojZmZmZmZmOyIgZD0iTTkyLjY3MiwxNDQuMzczYy0yLjc1MiwwLTUuNDkzLTEuMDQ0LTcuNTkzLTMuMTM4TDMuMTQ1LDU5LjMwMWMtNC4xOTQtNC4xOTktNC4xOTQtMTAuOTkyLDAtMTUuMTggYzQuMTk0LTQuMTk5LDEwLjk4Ny00LjE5OSwxNS4xOCwwbDc0LjM0Nyw3NC4zNDFsNzQuMzQ3LTc0LjM0MWM0LjE5NC00LjE5OSwxMC45ODctNC4xOTksMTUuMTgsMCBjNC4xOTQsNC4xOTQsNC4xOTQsMTAuOTgxLDAsMTUuMThsLTgxLjkzOSw4MS45MzRDOTguMTY2LDE0My4zMjksOTUuNDE5LDE0NC4zNzMsOTIuNjcyLDE0NC4zNzN6Ij48L3BhdGg+IDwvZz4gPC9nPiA8L2c+PC9zdmc+');
                }
            }

            &:before
            {
                content: 'Свернуть';
            }
        }
    }
}

@mixin NoAdaptiveBlock
{
    padding-right: 0;
    padding-left: 0;

    @media (min-width: $tablet-media)
    {
        padding-right: 0;
        padding-left: 0;
    }

    //768

    @media (min-width: $desktop-media)
    {
        padding-right: 0;
        padding-left: 0;
    }

    @media (min-width: $large-desktop-media-second)
    {
        padding-right: 0;
        padding-left: 0;
    } //1664
}

@mixin AfterDot
{
    font-size: var( --extra-medium );
    line-height: 0.5;

    display: inline-block;

    margin-top: -0.33rem;
    margin-right: 1rem;
    margin-left: 1rem;

    content: '•';
    vertical-align: middle;

    @media (min-width: $mobile-media)
    {
        display: none;
    }
}

input[type='range']
{
    width: 100%;
    height: 7px;
    margin-top: 0.7rem;

    border-radius: 3px;
    background: var(--green-500);

    -webkit-appearance: none !important;

    &::-webkit-slider-thumb
    {
        width: 17px;
        height: 17px;

        border: 1px solid var(--green-500);
        border-radius: 10px;
        background: var(--white);

        -webkit-appearance: none !important;
    }
}

.__jivoMobileButton {
    margin-bottom: 12rem;
}

.loaderBlock {
    position: relative;
    min-height: 8rem;
}

.loadingContainer {
    width: 100%;
    height: calc(100% + 3rem);
    position: absolute;
    background-color: rgb(204 204 204 / 38%);
    z-index: 101;
    margin-top: -3rem;
    padding: 3rem;
    backdrop-filter: blur(1.5px);
}

.loading {
    height: 0;
    width: 0;
    padding: 15px;
    border: 6px solid #ccc;
    border-right-color: #888;
    border-radius: 22px;
    -webkit-animation: loadingRotate 1s infinite linear;
    margin: auto;
}

.smart-captcha {
    margin-top: 2rem;
}

@keyframes loadingRotate {
    /* 100% keyframe for  clockwise.
       use 0% instead for anticlockwise */
    100% {
        -webkit-transform: rotate(360deg);
    }
}