.ModalCamerasContent {

  .Modal {

    &-CamerasTop {
      position: absolute;
      inset: 2.4rem 2.4rem auto;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: .8rem;
    }

    &-CamerasBottons {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: .8rem;
    }

    &-Close {
      position: relative;
      top: auto;
      right: auto;
      width: 4.8rem;
      height: 4.8rem;
      background: var(--white);
      border-radius: 4.8rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }

  }

}