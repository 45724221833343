.HeaderSmall
{
    width: 100%;

    background: var(--gray-150);

    flex: 0 0 auto;

    &_overlap
    {
        position: relative;
        z-index: 1000;        

        color: var( --white );

        .Title {
            @media screen and (max-width: $landscape-mobile-media)
            {
                position: absolute;
            }
        }
    }

    &_indent
    {
        padding-bottom: 4.5rem;
    }

    &-Wrap
    {
        @include AdaptiveBlock;

        width: 100%;
        max-width: var(--wid-project);
        margin: 0 auto;
        //padding: 4.8rem 0 6.4rem;
        //padding-top: 5.2rem;
        //padding-bottom: 6.4rem;
        padding-bottom: 4.8rem;
        z-index: 998;
  
    }

    &-Date
    {
        font-size: var(--small);

        display: flex;

        margin-top: 1.6rem;

        color: var(--gray-1050);

        align-items: center;
        justify-content: flex-start;
        gap: 1.2rem;
    }
}
