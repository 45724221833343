.MarkedList
{
    font-size: var(--extra-small);
    line-height: 1.5;

    display: flex;
    flex-direction: column;

    margin-bottom: 4.8rem;
    padding-top: 1.2rem;
    padding-left: 3.2rem;

    list-style-type: none;

    gap: 1.2rem;

    &-Item
    {
        font-size: var(--extra-small);
        line-height: 1.5;

        position: relative;

        &:after
        {
            position: absolute;
            top: 1.2rem;
            left: -2rem;

            display: block;

            width: 0.8rem;
            height: 0.8rem;

            content: '';
            transform: translateY(-50%);

            border-radius: 100%;
            background: var(--green-500);
        }

        &_black
        {
            &:after
            {
                background: var(--black);
            }
        }
    }
}
