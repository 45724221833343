.FloorPlanStore
{
    position: relative;

    width: 100%;
    max-width: var(--wid-project);
    margin: 0 auto;
    padding-top: 3.6rem;

    &-Top
    {
        position: absolute;
        left: 0;

        display: flex;

        align-items: center;
        justify-content: flex-start;
        gap: 3.2rem;
    }

    &-Back
    {
        transform: rotate(180deg);
    }

    &-Title
    {
        font-size: var(--ultra-large);
        font-weight: bold;

        letter-spacing: 0.06rem;
    }

    &-ChoiceWrap
    {
        margin-top: 4.5rem;
        padding: 0 0 18rem;
    }

    &-Choice
    {
        position: relative;

        margin: 0 auto;

        &_floor-4
        {
            width: 108rem;
            height: 33.8rem;

            background: url('../img/storeroom-min.png');
        }

        &-Svg
        {
            position: absolute;

            width: 100%;
            height: 100%;

            stroke-width: 10px;
            stop-color: green;
            inset: 0;
        }
    }

    &-Link
    {
        &_purchased,
        &_reservation
        {
            cursor: default;
        }
    }

    &-ChoiceObject
    {
        //stroke-width: .2rem;
        //stroke: var(--green-300);
        fill: transparent;

        &:hover
        {
            fill: var(--green-300);
            fill-opacity: 0.5;
            stroke-opacity: 0.5;
        }

        &_purchased
        {
            fill: var(--red-500);
            fill-opacity: 0.5;
            stroke-opacity: 0.5;

            &:hover
            {
                fill: var(--red-500);
                fill-opacity: 0.5;
                stroke-opacity: 0.5;
            }
        }
        //&_reservation {
        //
        //    fill: var(--white);
        //    fill-opacity: 0.8;
        //    stroke-opacity: 0.5;
        //
        //    &:hover {
        //        fill: var(--white);
        //        fill-opacity: 0.8;
        //        stroke-opacity: 0.5;
        //    }
        //
        //}
    }
}

.zamok
{
    position: absolute;
    top: 50%;
    left: 50%;

    border: 10px solid #f00;
    background: red;
}
