.ResultForm
{
    &-SubmitButton
    {
        line-height: 1.4;

        max-width: 22.8rem;
        margin: 4.8rem auto 0 auto;
        padding: 2rem 2.6rem;

        white-space: nowrap;
    }

    &-FormWrapper
    {
        display: flex;
        flex-direction: column;

        justify-content: center;
    }
}
