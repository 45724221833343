.Main
{
    flex: 1 0 auto;

    &-Wrap
    {
        width: 100%;
        padding: 6.4rem 0 6.2rem;

        &_cover
        {
            padding-top: 0;
        }

        &_planblock
        {
            @media screen and (max-width: $desktop-media)
            {
                padding-top: 0;
            }
        }
    }

    &-OffsetUp
    {
        margin-top: -9.8rem;

        @media screen and (max-width: $landscape-mobile-media)
        {
            margin-top: -2.8rem;
        }
    }

    &-Img
    {
        margin-bottom: 4.1rem;

        img
        {
            display: block;

            width: 100%;
            max-width: 100%;
        }
    }

    &-ImgNewsContainer
    {
        margin-top: -9.8rem;

        img
        {
            object-fit: cover;
            object-position: center;
        }
    }

    &-Video
    {
        max-width: 100%;
        margin-top: 2.2rem;

        video,
        iframe,
        object
        {
            display: block;

            max-width: 100%;

            @media screen and (max-height: $tablet-media)
            {
                height: auto;
            }
        }
    }

    &-ImgTitle
    {
        padding: 3.2rem;

        background: var(--gray-150);
    }

    img.Main-OffsetUp
    {
        margin-bottom: 0;
    }

    &-TextDocs
    {
        display: flex;

        align-items: flex-start;
        gap: 3.2rem;
    }

    &-TextButton
    {
        margin-top: 6.4rem;
    }

    &-Text
    {
        font-size: var(--extra-small);
        line-height: 1.5;

        width: 100%;
        max-width: var(--wid-project);
        margin: 0 auto;
        padding-bottom: 4.3rem;

        letter-spacing: 0.02rem;

        @include AdaptiveBlock;

        //padding: 4.8rem 0 6.4rem;

        &_News
        {
            text-align: justify;
        }

        #MainPhoto
        {
            width: 100%;
        }

        &_Collapsed
        {
            @include CollapsedText;
        }

        &-ShowAll
        {
            @include ShowAllText;
        }

        h2
        {
            font-size: var(--medium);
            font-weight: 400;
            line-height: 1.5rem;

            padding-top: 2rem;
            padding-bottom: 2rem;

            @media screen and (max-width: $mobile-media)
            {
                line-height: 1.4;
            }
        }

        p
        {
            margin-bottom: 3.2rem;
        }

        a
        {
            color: var(--green-300);
        }

        ul
        {
            margin: 0 0 3.2rem 1.2rem;
            padding: 0;

            list-style: none;

            li
            {
                position: relative;

                margin: 1.2rem 0;
                padding-left: 2rem;

                &::before
                {
                    position: absolute;
                    top: 0.8rem;
                    left: 0;

                    display: inline-block;

                    width: 0.8rem;
                    height: 0.8rem;

                    content: '';

                    border-radius: 0.8rem;
                    background: var(--green-500);
                }
            }

            .black
            {
                &::before
                {
                    position: absolute;
                    top: 0.8rem;
                    left: 0;

                    display: inline-block;

                    width: 0.8rem;
                    height: 0.8rem;

                    content: '';

                    border-radius: 0.8rem;
                    background: var(--black);
                }
            }

            ul
            {
                margin-bottom: 0;

                list-style: none;

                li
                {
                    padding-left: 2.2rem;

                    &::before
                    {
                        top: 0;

                        width: auto;
                        height: auto;

                        content: '—';

                        border-radius: 0;
                        background: none;
                    }
                }
            }
        }
    }

    ol
    {
        counter-reset: item;

        li
        {
            display: block;

            margin-bottom: 2.4rem;

            &:before
            {
                content: counters(item, '.') '. ';
                counter-increment: item;
            }

            ul
            {
                margin-left: 0;

                li
                {
                    &:before
                    {
                        position: absolute;
                        top: 0.8rem;
                        left: 0;

                        display: inline-block;

                        width: 0.8rem;
                        height: 0.8rem;

                        content: '';
                        counter-increment: none;

                        border-radius: 0.8rem;
                        background: var(--green-500);
                    }
                }
            }
        }
    }
}
