.SortPicker {
  font-size: var(--extra-small);
  font-weight: bold;

  display: inline-flex;

  vertical-align: middle;

  align-items: center;

  cursor: pointer;

  @media screen and (min-width: $tablet-media)
  {
    margin-right: 3rem;
  }

  &-ListContainer {
    margin-top: 2.5rem;
    font-weight: normal;
    background-color: var(--white);
    box-shadow: 0 .5rem 1rem .13rem rgba(61,61,61, .1), 0 1rem 2rem .13rem rgba(61,61,61, .1);
    border-radius: 3px;
    position: absolute;
    list-style-type: none;
    z-index: 99;
    display: none;
  }

  &-ListItem {
    padding: 1rem 2rem;
    margin: 0;

    &:hover {
      background-color: var(--gray-200);
    }
  }
}