@mixin CityNew
{
    color: var(--green-500);
    background: no-repeat center url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDQiIGhlaWdodD0iNTQiIHZpZXdCb3g9IjAgMCA0NCA1NCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTIyIDBDMTAuNDUgMCAwIDguNjk0IDAgMjIuMTRDMCAzMS4xMDQgNy4zNDI1IDQxLjcxNSAyMiA1NEMzNi42NTc1IDQxLjcxNSA0NCAzMS4xMDQgNDQgMjIuMTRDNDQgOC42OTQgMzMuNTUgMCAyMiAwWiIgZmlsbD0iIzAwQTU1NiIvPgo8Y2lyY2xlIGN4PSIyMiIgY3k9IjIyIiByPSIxNSIgZmlsbD0id2hpdGUiLz4KPC9zdmc+Cg==');
}
@mixin CityReady
{
    color: var(--orange-500);
    background: no-repeat center url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDQiIGhlaWdodD0iNTQiIHZpZXdCb3g9IjAgMCA0NCA1NCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTIyIDBDMTAuNDUgMCAwIDguNjk0IDAgMjIuMTRDMCAzMS4xMDQgNy4zNDI1IDQxLjcxNSAyMiA1NEMzNi42NTc1IDQxLjcxNSA0NCAzMS4xMDQgNDQgMjIuMTRDNDQgOC42OTQgMzMuNTUgMCAyMiAwWiIgZmlsbD0iI0RCNzUyMSIvPgo8Y2lyY2xlIGN4PSIyMiIgY3k9IjIyIiByPSIxNSIgZmlsbD0id2hpdGUiLz4KPC9zdmc+Cg==');
}

.GeoMedia
{
    position: relative;

    @media (max-width: $desktop-media)
    {
        display: none;
    }

    //1024

    &-Img
    {
        display: block;

        width: 100%;
        margin: 0 auto;
    }

    &-Label
    {
        font-size: var(--large);
        font-weight: 400;
        font-style: normal;
        line-height: 4.8rem;

        position: absolute;

        margin-top: 6.4rem;
        margin-left: 16rem;

        letter-spacing: 0.03rem;

        color: var(--white);
    }

    &-Legend
    {
        position: absolute;
        right: 16rem;

        display: flex;

        width: 376px;
        margin-top: 6.4rem;
        padding: 1.3rem 3.2rem;

        color: var(--white);
        border-radius: 4rem;
        background: var(--white);
    }

    &-LegendBlock
    {
        display: flex;

        width: 50%;

        &:first-child
        {
            .GeoMedia-LegendNumber
            {
                @include CityNew;
            }
        }

        &:last-child
        {
            .GeoMedia-LegendNumber
            {
                @include CityReady;
            }
        }
    }

    &-LegendNumber,
    &-CityReady,
    &-CityNew
    {
        font-size: var(--extra-small);
        font-weight: bold;
        font-style: normal;
        line-height: 4.6rem;

        min-width: 4.4rem;
        height: 5.4rem;
        margin-right: 1.6rem;

        text-align: center;
        letter-spacing: 0.05rem;
    }

    &-CityNew,
    &-CityReady
    {
        position: absolute;

        text-decoration: none;
    }

    &-CityNew
    {
        @include CityNew;
    }

    &-CityReady
    {
        @include CityReady;
    }

    &-CityLabel
    {
        font-size: var(--extra-small);
        font-weight: 400;
        font-style: normal;
        line-height: 2.2rem;

        position: absolute;
        top: 50%;
        left: 50%;

        padding: 5px 16px;

        text-align: center;
        text-decoration: none;

        color: var(--black);
        border-radius: 17px;
        background: var(--white);
        box-shadow: 0 4px 8px rgba(97, 97, 97, 0.14), 0 8px 16px rgba(97, 97, 97, 0.14);

        /* Bakhchisaray */
        &[href$="/31"] {
            transform: translateX(-25px);
        }
    }

    &-LegendText
    {
        font-size: var(--extra-small);
        font-weight: 400;
        line-height: 2.4rem;

        letter-spacing: 0.05em;

        color: var(--black);
    }
}
