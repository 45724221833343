
.Progress
{
    position: relative;

    width: 100%;

    border-radius: 0.4rem;
    background: #fff;

    &-Loading
    {
        position: relative;

        display: inline-block;
        overflow: hidden;

        width: 100%;
        height: 0.6rem;

        border-radius: 0.4rem;
        background: var(--gray-25);
    }

    &-LoadingBar
    {
        position: absolute;
        left: 0;

        width: 25%;
        max-width: 100%;
        height: 100%;

        background: var(--green-300);
    }
}
