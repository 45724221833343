.Arrow
{
    display: inline-block;

    width: 6.4rem;
    height: 6.4rem;

    cursor: pointer;

    border: 0.2rem solid var(--black);
    border-radius: 50%;
    background: no-repeat 50% 50%  url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzMiIGhlaWdodD0iMzQiIHZpZXdCb3g9IjAgMCAzMyAzNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMS4wMDA0IDE2Ljk5OThMMzEgMTYuOTk5OE0zMSAxNi45OTk4TDE2LjAwMDIgMk0zMSAxNi45OTk4QzMxIDE2Ljk5OTggMjEuODU4IDI2LjE0MTggMTYuMDAwMiAzMS45OTk2IiBzdHJva2U9ImJsYWNrIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJzcXVhcmUiLz48L3N2Zz4=);

    &:hover
    {
        border-color: var(--green-300);
        background: var(--green-300) no-repeat 50% 50% url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzMiIGhlaWdodD0iMzQiIHZpZXdCb3g9IjAgMCAzMyAzNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMS4wMDA0IDE2Ljk5OThMMzEgMTYuOTk5OE0zMSAxNi45OTk4TDE2LjAwMDIgMk0zMSAxNi45OTk4QzMxIDE2Ljk5OTggMjEuODU4IDI2LjE0MTggMTYuMDAwMiAzMS45OTk2IiBzdHJva2U9IndoaXRlIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJzcXVhcmUiLz48L3N2Zz4=);
    }

    &_size
    {
        &_4
        {
            width: 4.8rem;
            height: 4.8rem;

            background-size: 2.4rem 2.4rem;

            &:hover
            {
                background-size: 2.4rem 2.4rem;
            }
        }

        &_8
        {
            width: 8rem;
            height: 8rem;
        }

        &_6
        {
            width: 6.4rem;
            height: 6.4rem;
        }
    }

    &_active
    {
        border-color: var(--green-300);
        background: var(--green-300) no-repeat 50% 50% url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzMiIGhlaWdodD0iMzQiIHZpZXdCb3g9IjAgMCAzMyAzNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMS4wMDA0IDE2Ljk5OThMMzEgMTYuOTk5OE0zMSAxNi45OTk4TDE2LjAwMDIgMk0zMSAxNi45OTk4QzMxIDE2Ljk5OTggMjEuODU4IDI2LjE0MTggMTYuMDAwMiAzMS45OTk2IiBzdHJva2U9IndoaXRlIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJzcXVhcmUiLz48L3N2Zz4=);

        &:hover
        {
            border-radius: 50%;
            background-color: var(--green-400);
        }
    }

    &_move_back
    {
        transform: scaleX(-1);
    }
}
