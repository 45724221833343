.NumberedList
{
    counter-reset: item;

    &-Item
    {
        display: block;

        &:before
        {
            content: counters(item, '.') '. ';
            counter-increment: item;
        }
    }
}
