.ParkingSlots
{
    margin-bottom: 8rem;
    padding-top: 3.2rem;

    .Filters-ButtonOpen
    {
        width: 100%;
    }

    .Filters-Block
    {
        @include NoAdaptiveBlock;
    }

    .Filters-Block_active
    {
        position: relative;

        display: block;
    }

    .Filters-Input
    {
        padding-top: 1rem;
        padding-bottom: 1rem;

        @media (min-width: $tablet-media)
        {
            padding-top: 1.5rem;
            padding-bottom: 1.5rem;
        }

        //768

        @media (min-width: $desktop-media)
        {
            padding-top: 2rem;
            padding-bottom: 2rem;
        } //1024
    }

    &-Wrap
    {
        display: flex;
        flex-direction: column;

        @include AdaptiveBlock;
        gap: 6.4rem;

        //@media (min-width: $desktop-media) {
        //    flex-direction: row;
        //} //1024

        @media (min-width: $second-medium-desktop-media)
        {
            flex-direction: row;
        } //1407
    }

    &-Filters
    {
        width: 100%;
        //padding-bottom: 10rem;
        //flex: 0 0 23.5%;

        flex-shrink: 0;

        .Filters-Bottom
        {
            display: block;

            margin-top: 3rem;

            text-align: center;

            @media (min-width: $second-medium-desktop-media)
            {
                text-align: left;
            } //1407
        }

        &-Top
        {
            display: flex;
            flex-direction: row !important;
            //flex-direction: column;

            margin-bottom: 0;

            align-items: center;
            flex-wrap: wrap;
        }

        .Filters-Col
        {
            width: 100%;
            //margin-bottom: 3.5rem;
            max-width: 100%;

            @media (min-width: $tablet-media)
            {
                max-width: calc(50% - 1.6rem);
            }

            //768

            //@media (min-width: $desktop-media) {
            //    max-width: 100%;
            //} //1024

            @media (min-width: $second-medium-desktop-media)
            {
                max-width: 100%;
            }

            //1407

            .chosen-single
            {
                span
                {
                    //font-weight: bold;
                }
            }
        }
    }

    &-Content
    {
        flex: 1 1 auto;
    }

    &-MetaControls
    {
        padding-top: 1rem;
    }

    &-MetaControlsItem
    {
        font-size: var(--extra-small);
        font-weight: 400;

        display: inline-flex;

        margin-right: 3rem;

        vertical-align: middle;

        align-items: center;

        &_filter
        {
            cursor: pointer;
        }

        .ParkingSlots-Icon
        {
            margin-right: 1rem;
        }
    }

    &-List
    {
        margin: 3.5rem 0 0 0;
    }

    &-Item
    {
        display: flex;

        padding: 4.7rem 0;

        border-bottom: 0.1rem solid var( --gray-25 );
        //background-color: var( --gray-150 );

        justify-content: space-between;
        align-items: center;
        column-gap: 3rem;

        @media screen and (max-width: $landscape-mobile-media)
        {
            position: relative;
            padding-right: 3.5rem;
        }

        &:last-child
        {
            border-bottom: 0;
        }

        &_reserved
        {
            position: relative;

            &:before
            {
                position: absolute;
                top: 50%;
                left: 7rem;

                width: 5rem;
                height: 5rem;

                content: '';
                transform: translateY(-50%);

                border-radius: 50%;
                background: var( --gray-25 ) $lock-icon no-repeat center center / 45% 45%;
            }

            .ParkingSlots
            {
                &-Label
                {
                    color: var( --gray-450 );

                    &_amount
                    {
                        &:before
                        {
                            display: block;
                        }
                    }
                }
            }
        }
    }

    &-Center
    {
        display: flex;
        flex-direction: column;
        //flex-grow: 1;
        //flex-wrap: wrap;

        row-gap: 1.6rem;
        column-gap: 1.6rem;
        flex-grow: 1;

        @media (min-width: $tablet-media)
        {
            flex-direction: row;
        }  //768
    }

    &-CenterCol
    {
        display: flex;

        flex-grow: 1;
        flex-wrap: wrap;
        row-gap: 1.6rem;
        column-gap: 1.6rem;

        @media screen and (max-width: $landscape-mobile-media)

        {

        }

        @media (min-width: $desktop-media)
        {
            flex-direction: row;

            justify-content: space-between;
        }

        //1024

        @media (max-width: $mobile-media)
        {
            column-gap: 0;
        }
    }

    &-LabelText
    {
        display: inline;

        @media (min-width: $desktop-media)
        {
            display: none;
        } //1024
    }

    &-Label
    {
        font-size: var( --small2 );

        display: flex;
        flex-direction: column;

        letter-spacing: 0.05rem;

        gap: 0.8rem;

        @media (min-width: $mobile-media)
        {
            font-size: var(--extra-medium);
        }

        //480

        &_big
        {
            width: 100%;

            @media (min-width: $desktop-media)
            {
                width: auto;
            } //1024

            @media screen and (max-width: $landscape-mobile-media)
            {
                flex: 1 1 100%;
            }
        }

        &_small
        {
            font-size: var(--small);

            width: auto;

            @media (min-width: $desktop-media)
            {
                font-size: var(--extra-medium);
            }

            @media (max-width: $mobile-media)
            {
                flex-direction: row;

                align-items: center;
                gap: 0;

                &:after
                {
                    @include AfterDot;
                }
            }

            & + &
            {
                &:after
                {
                    display: none;
                }
            }

            //1024

            .ParkingSlots-SubLabel
            {
                display: none;

                @media (min-width: $desktop-media)
                {
                    display: block;
                } //1024
            }
        }

        &_title
        {
            font-weight: 400;

            width: 12rem;

            //margin-right: auto;
            //padding-left: 3.5rem;

            //text-align: left;

            @media screen and ($mobile-media)
            {
                width: 20%;
            }
        }

        &_amount
        {
            position: relative;

            padding-right: 0.5rem;

            &:before
            {
                font-size: var( --small );

                position: absolute;
                top: -2rem;
                right: 0;

                display: none;

                content: 'Бронь';

                color: var( --red );
            }

            @media (min-width: $tablet-media)
            {
                min-width: 22rem;

                text-align: right;
            }

            //768

            @media (max-width: $mobile-media)
            {
                font-size: var(--extra-medium );
                font-weight: 400;
                line-height: 1.2;

                align-items: flex-start;
            }
        }

        &_favourite
        {
            min-width: initial;
            padding-left: 0;

            align-self: flex-start;

            @media (min-width: $tablet-media)
            {
                align-self: center;
            }

            @media screen and (max-width: $landscape-mobile-media)
            {
                position: absolute;
                top: 5rem;
                right: 0;
            }

            //768

            .Icon_heart
            {
                background-color: transparent;
            }
        }

        .ObjectCard-Pic
        {
            width: auto;
            max-width: none;
            max-width: 100%;
            height: auto;
            max-height: 8.8rem !important;
            max-height: none;
            margin: auto;

            object-fit: contain;
        }
    }

    &-SubLabel
    {
        font-size: var(--small);
        //position: absolute;
        //right: 0;
        //bottom: -2rem;

        color: var( --gray-650 );

        @media (min-width: $mobile-media)
        {
            font-size: var( --ultra-small );
        } //480
    }

    &-Power
    {
        font-size: var( --ultra-small );

        display: inline-block;

        margin-left: 0.2rem;

        &_large
        {
            font-size: var( --tiny );
        }
    }

    &-Icon
    {
        width: 2.2rem;
        height: 2.2rem;

        background-size: contain;
    }

    &-Actions
    {
        padding: 3.5rem 0;

        text-align: center;
    }

    .Filters_vertical
    {
        max-width: 100%;

        @media (min-width: $second-medium-desktop-media)
        {
            max-width: 37.6rem;
        } //1407
    }
}
