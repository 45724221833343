.PopupService
{
    position: fixed;
    z-index: 999;
    top: 2.4rem;
    right: 2.4rem;

    display: none;
    flex-direction: column;

    max-width: 51.2rem;
    padding: 2.4rem;

    background: var(--green-300);
    box-shadow: 0 16px 32px 2px rgba(97, 97, 97, 0.10), 0 8px 16px 2px rgba(97, 97, 97, 0.10);

    justify-content: center;
    align-items: flex-start;
    gap: 1.6rem;

    &_active
    {
        display: flex;
    }

    @media screen and (max-width: $tablet-media)
    {
        top: 4.2rem;
        right: 2rem;
        left: 2rem;
    }

    &-Text
    {
        font-family: TT Firs Neue;
        font-size: 1.6rem;
        font-weight: 400;
        font-style: normal;
        line-height: 2.4rem; /* 150% */

        letter-spacing: 0.04rem;

        color: var(--white);
    }

    &-Close
    {
        font-family: TT Firs Neue;
        font-size: 1.6rem;
        font-weight: bold;
        font-style: normal;
        line-height: 2.4rem; /* 150% */

        display: flex;

        width: 100%;
        padding: 1.2rem 2.4rem;

        cursor: pointer;
        letter-spacing: 0.08rem;

        color: var(--white);
        border: 1px solid var(--white);
        border-radius: 2.4rem;
        background: transparent;

        justify-content: center;
        align-items: flex-start;
        gap: 1.2rem;
    }
}
