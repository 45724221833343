.MobileBottomLineMenu
{
    position: fixed;
    z-index: 100;
    bottom: 0;

    display: none;

    width: 100%;
    height: 7rem;
    padding: 1.6rem;

    background-color: var(--white);
    box-shadow: 0 6px 40px 0 rgba(97, 97, 97, 0.25), 0 0 16px 2px rgba(97, 97, 97, 0.03);

    justify-content: space-between;
    align-items: flex-start;
    gap: 1.6rem;
    flex-shrink: 0;

    @media screen and (max-width: $second-medium-desktop-media)
    {
        display: flex;
    }

    @media screen and (max-width: $small-mobile-media)
    {
        padding-right: 1.2rem;
        padding-left: 1.2rem;

        gap: 1.2rem;
    }

    a
    {
        font-family: TT Firs Neue;
        font-size: var(----ultra-small);
        font-weight: 400;
        font-style: normal;
        line-height: 1.4rem; /* 116.667% */

        display: block;

        height: 3.8rem;
        margin: 0;
        padding: 3rem 0 0 0;

        text-align: center;
        text-decoration: none;
        letter-spacing: 0.024rem;

        color: var(--black);
        background-repeat: no-repeat;
        background-position: center;
        position: relative;
    }

    &-NewProjects
    {
        background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgY2xpcC1wYXRoPSJ1cmwoI2NsaXAwXzMzMDlfMjQzMjYpIj4KPHJlY3QgeT0iMjAiIHdpZHRoPSIyNCIgaGVpZ2h0PSIyIiBmaWxsPSJibGFjayIvPgo8cmVjdCB4PSIyIiB5PSI4IiB3aWR0aD0iMiIgaGVpZ2h0PSIxMiIgZmlsbD0iYmxhY2siLz4KPHJlY3QgeD0iMyIgeT0iOCIgd2lkdGg9IjYiIGhlaWdodD0iMiIgZmlsbD0iYmxhY2siLz4KPHJlY3QgeD0iMTEiIHk9IjIiIHdpZHRoPSI5IiBoZWlnaHQ9IjIiIGZpbGw9ImJsYWNrIi8+CjxyZWN0IHg9IjkiIHk9IjIiIHdpZHRoPSIyIiBoZWlnaHQ9IjE4IiBmaWxsPSJibGFjayIvPgo8cmVjdCB4PSIyMCIgeT0iMiIgd2lkdGg9IjIiIGhlaWdodD0iMTgiIGZpbGw9ImJsYWNrIi8+CjxyZWN0IHg9IjEzIiB5PSI2IiB3aWR0aD0iMiIgaGVpZ2h0PSIyIiBmaWxsPSJibGFjayIvPgo8cmVjdCB4PSI1LjUiIHk9IjEyIiB3aWR0aD0iMiIgaGVpZ2h0PSIyIiBmaWxsPSJibGFjayIvPgo8cmVjdCB4PSI1LjUiIHk9IjE2IiB3aWR0aD0iMiIgaGVpZ2h0PSIyIiBmaWxsPSJibGFjayIvPgo8cmVjdCB4PSIxMyIgeT0iMTAiIHdpZHRoPSIyIiBoZWlnaHQ9IjIiIGZpbGw9ImJsYWNrIi8+CjxyZWN0IHg9IjEzIiB5PSIxNCIgd2lkdGg9IjIiIGhlaWdodD0iMiIgZmlsbD0iYmxhY2siLz4KPHJlY3QgeD0iMTYiIHk9IjYiIHdpZHRoPSIyIiBoZWlnaHQ9IjIiIGZpbGw9ImJsYWNrIi8+CjxyZWN0IHg9IjE2IiB5PSIxMCIgd2lkdGg9IjIiIGhlaWdodD0iMiIgZmlsbD0iYmxhY2siLz4KPHJlY3QgeD0iMTYiIHk9IjE0IiB3aWR0aD0iMiIgaGVpZ2h0PSIyIiBmaWxsPSJibGFjayIvPgo8L2c+CjxkZWZzPgo8Y2xpcFBhdGggaWQ9ImNsaXAwXzMzMDlfMjQzMjYiPgo8cmVjdCB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIGZpbGw9IndoaXRlIi8+CjwvY2xpcFBhdGg+CjwvZGVmcz4KPC9zdmc+Cg==);
    }

    &-Flats
    {
        background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTIwIDE5VjRIMTZWM0g0VjE5SDBWMjFIMTZWNkgxOFYyMUgyNFYxOUgyMFpNMTQgMTlINlY1SDE0VjE5Wk0xMSAxMUgxM1YxM0gxMVYxMVoiIGZpbGw9ImJsYWNrIi8+Cjwvc3ZnPgo=);
    }

    &-Favorites
    {
        background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTIwLjU4MTggNC40NjAyOUwyMC41ODIyIDQuNDYwNjNDMjEuMDI5MSA0LjkyNjMgMjEuMzg1OCA1LjQ4MTQyIDIxLjYyOTggNi4wOTUyOUMyMS44NzM5IDYuNzA5MjIgMjIgNy4zNjg2IDIyIDguMDM1NDFDMjIgOC43MDIyMiAyMS44NzM5IDkuMzYxNiAyMS42Mjk4IDkuOTc1NTNDMjEuMzg1OCAxMC41ODk0IDIxLjAyOTEgMTEuMTQ0NSAyMC41ODIyIDExLjYxMDJMMjAuNTgyIDExLjYxMDRMMTkuNDY2NCAxMi43NzMyTDExLjk5OTcgMjAuNTU1Nkw0LjUzMzA2IDEyLjc3MzJMMy40MTc0NCAxMS42MTA0QzIuNTE0MyAxMC42NjkgMiA5LjM4Mzc4IDIgOC4wMzU0MUMyIDYuNjg3MDMgMi41MTQzIDUuNDAxOCAzLjQxNzQ0IDQuNDYwNDZDNC4zMTkyMyAzLjUyMDU0IDUuNTMzMjcgMy4wMDA1OSA2Ljc4OTk3IDMuMDAwNTlDOC4wNDY2NyAzLjAwMDU5IDkuMjYwNzEgMy41MjA1NCAxMC4xNjI1IDQuNDYwNDZMMTEuMjc4MSA1LjYyMzI2QzExLjQ2NjcgNS44MTk4MiAxMS43MjczIDUuOTMwOTUgMTEuOTk5NyA1LjkzMDk1QzEyLjI3MjEgNS45MzA5NSAxMi41MzI3IDUuODE5ODIgMTIuNzIxMyA1LjYyMzI2TDEzLjgzNjkgNC40NjA0NkwxMy44MzcxIDQuNDYwMjlDMTQuMjgzOCAzLjk5NDQ5IDE0LjgxMTkgMy42MjczIDE1LjM5MDEgMy4zNzc1OEMxNS45NjgyIDMuMTI3OTEgMTYuNTg2MyAzIDE3LjIwOTUgM0MxNy44MzI2IDMgMTguNDUwNyAzLjEyNzkxIDE5LjAyODggMy4zNzc1OEMxOS42MDcgMy42MjczIDIwLjEzNTEgMy45OTQ0OSAyMC41ODE4IDQuNDYwMjlaIiBzdHJva2U9ImJsYWNrIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8L3N2Zz4K);
    }

    &-Rent
    {
        background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgY2xpcC1wYXRoPSJ1cmwoI2NsaXAwXzMzMDlfMjQzNTMpIj4KPHBhdGggZD0iTTMuNzA3MTYgMTUuMDIxMUwxNS4wMjE0IDMuNzA3MkwxMy42MDcyIDIuMjkyOTdMMi4yOTI5NyAxMy42MDY4TDMuNzA3MTYgMTUuMDIxMVoiIGZpbGw9ImJsYWNrIi8+CjxwYXRoIGQ9Ik0xNy43MDcyIDYuMjkyOThMMTYuMjkzIDcuNzA3MTlMMjAuMDAwMSAxMS40MTQzVjIwLjAwMDFINy4wMDAwNlYxNy4wMDAxSDUuMDAwMDZWMjIuMDAwMUgyMi4wMDAxVjEwLjU4NTlMMTcuNzA3MiA2LjI5Mjk4WiIgZmlsbD0iYmxhY2siLz4KPHBhdGggZD0iTTcuMDAwMzEgNS4wMDAwOUM3LjAwMDMxIDYuMTA0NjYgNi4xMDQ4OCA3LjAwMDA5IDUuMDAwMzEgNy4wMDAwOUMzLjg5NTc0IDcuMDAwMDkgMy4wMDAzMSA2LjEwNDY2IDMuMDAwMzEgNS4wMDAwOUMzLjAwMDMxIDMuODk1NTIgMy44OTU3NCAzLjAwMDA5IDUuMDAwMzEgMy4wMDAwOUM2LjEwNDg4IDMuMDAwMDkgNy4wMDAzMSAzLjg5NTUyIDcuMDAwMzEgNS4wMDAwOVoiIGZpbGw9ImJsYWNrIi8+CjxwYXRoIGQ9Ik0xMi4yODIgMTQuMjgxM0MxMy4zODY2IDE0LjI4MTMgMTQuMjgyIDEzLjM4NTkgMTQuMjgyIDEyLjI4MTNDMTQuMjgyIDExLjE3NjggMTMuMzg2NiAxMC4yODEzIDEyLjI4MiAxMC4yODEzQzExLjE3NzUgMTAuMjgxMyAxMC4yODIgMTEuMTc2OCAxMC4yODIgMTIuMjgxM0MxMC4yODIgMTMuMzg1OSAxMS4xNzc1IDE0LjI4MTMgMTIuMjgyIDE0LjI4MTNaIiBmaWxsPSJibGFjayIvPgo8L2c+CjxkZWZzPgo8Y2xpcFBhdGggaWQ9ImNsaXAwXzMzMDlfMjQzNTMiPgo8cmVjdCB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIGZpbGw9IndoaXRlIi8+CjwvY2xpcFBhdGg+CjwvZGVmcz4KPC9zdmc+Cg==);
    }
}
