.Tile
{
    display: flex;

    width: 100%;
    max-width: var(--wid-project);
    margin: 0 auto;
    padding-top: 5rem;
    padding-bottom: 8rem;

    justify-content: center;

    &-Wrap
    {
        background-color: var( --gray-150 );
    }

    &-Row
    {
        display: flex;
    }

    &-Numbers
    {
        font-size: var(--extra-small);
        font-weight: 400;

        display: flex;
        flex-direction: column;

        letter-spacing: 0.05rem;

        color: var(--gray-950);

        gap: 1.6rem;
    }

    &-FlatRow
    {
        display: flex;
    }

    &-NumbersItem
    {
        font-size: var(--extra-small);
        font-weight: 400;

        display: flex;

        width: 4.8rem;
        min-width: 4.8rem;
        height: 4.8rem;

        color: var(--gray-950);

        align-items: center;
        justify-content: center;
    }

    &-ApartmentsWrap
    {
        display: flex;
        flex-direction: column;

        gap: 1.6rem;
    }

    &-Apartments
    {
        display: flex;

        align-items: center;
        justify-content: flex-start;
        gap: 1.6rem;
        flex-wrap: wrap;
    }

    &-Item
    {
        position: relative;

        display: flex;

        width: 4.8rem;
        height: 4.8rem;

        text-decoration: none;

        background: var(--white);

        align-items: center;
        justify-content: center;
        flex: none;

        &:hover
        {
            background: var(--green-300);

            .Tile-Prise
            {
                color: var(--white);
            }

            .ApartmentCard
            {
                top: 5.8rem;
                left: 0;

                display: block;
                visibility: visible;

                opacity: 1;
            }

            //&:last-child,
            //&:nth-last-child(2)
            //{
            //  .ApartmentCard
            //  {
            //    right: 14rem;
            //    left: auto;
            //  }
            //}
        }

        &_sold
        {
            cursor: default;

            background: var(--gray-125);

            .Tile-Prise
            {
                color: var(--gray-950);
            }

            &:hover
            {
                background: var(--gray-125);

                .Tile-Prise
                {
                    color: var(--gray-950);
                }
            }
        }

        &_booking
        {
            background: var(--gray-125);

            //border: 0.1rem solid var(--gray-250);
            //background: transparent;

            .Tile-Prise
            {
                color: var(--gray-950);

                &::before
                {
                    display: inline-block;

                    width: 2.8rem;
                    height: 2.8rem;

                    content: '';

                    background: no-repeat 50% 50% url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjgiIGhlaWdodD0iMjgiIHZpZXdCb3g9IjAgMCAyOCAyOCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMjIuMDAzOCAxM0w1IDEzTDUuMDAzODMgMTVWMjRINy4wMDM4M0wyMyAyNEwyMy4wMDM4IDIyVjEzSDIyLjAwMzhaIiBzdHJva2U9IiM1RjYwNjIiIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIi8+PHBhdGggZD0iTTkgMTJWOUM5IDcuNjczOTIgOS41MjY3OCA2LjQwMjE1IDEwLjQ2NDUgNS40NjQ0N0MxMS40MDIxIDQuNTI2NzggMTIuNjczOSA0IDE0IDRDMTUuMzI2MSA0IDE2LjU5NzkgNC41MjY3OCAxNy41MzU1IDUuNDY0NDdDMTguNDczMiA2LjQwMjE1IDE5IDcuNjczOTIgMTkgOVYxMiIgc3Ryb2tlPSIjNUY2MDYyIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPjwvc3ZnPg==);
                }
            }

            &:hover
            {
                cursor: default;

                background: var(--gray-125);

                //background: transparent;

                .Tile-Prise
                {
                    color: var(--gray-950);
                }
            }
        }
    }

    &-Prise
    {
        font-size: var(--extra-small);
        font-weight: 400;

        color: var(--green-300);
    }
}

// Admin
.ea
{
    .Tile
    {
        &-Apartments
        {
            gap: 8px;
        }

        &-Item
        {
            width: 48px;
            height: 48px;

            text-align: center;
        }

        &-NumbersItem
        {
            font-size: 18px;
        }

        &-Prise
        {
            width: 48px;
            height: 48px;
            padding: 5px;
        }
    }
}
