.HouseCard,
.HouseCardParking,
.HouseCardStore
{
    position: fixed;
    z-index: 999;

    display: none;

    min-width: 28.8rem;
    padding: 2.4rem 2.4rem 0.8rem;

    animation: HouseCard 0.5s linear;

    background: var(--white);
    box-shadow: 0 0 1.6rem 0.2rem rgba(97, 97, 97, 0.03), 0 0.6rem 4rem rgba(97, 97, 97, 0.25);

    transform-style: preserve-3d;

    /* @media screen and (pointer: coarse) {
        display: none !important
    } */

    &_popup
    {
        position: absolute;
        top: 4.9rem;
        left: 0;

        display: block;

        width: 100vw;
    }

    &-Title
    {
        font-size: var(--extra-medium);
        font-weight: bold;
        line-height: 1.4;

        letter-spacing: 0.01rem;

        color: var(--black);
    }

    &-Deadline
    {
        font-size: var(--small);
        font-weight: 400;
        line-height: 1.5;

        letter-spacing: 0.02rem;

        color: var(--gray-1100);
    }

    &-List
    {
        font-size: var(--extra-small);
        line-height: 1.5;

        color: var(--black);

        @media screen and (max-width: $mobile-media)
        {
            overflow: auto;

            margin-bottom: 1.6rem;
            max-height: 60%;
        }
    }

    &-Item
    {
        display: flex;

        padding: 1.6rem 0;

        border-bottom: 0.1rem solid var(--gray-300);

        align-items: center;
        justify-content: space-between;
        gap: 1rem;

        &:last-child
        {
            border: transparent;
        }
    }

    &-Quantity
    {
        font-weight: 400;
    }

    &-Button, &-ButtonClose {
        @media screen and (min-width: $mobile-media)
        {
            display: none;
        }
    }
}

.HouseCardParking,
.HouseCardStore {
    &.HouseCard_popup
    {
        position: fixed;
        top: 18rem;
        left: 0;
        display: block;
        width: 100vw;
    }
}

@keyframes HouseCard
{
    0%
    {
        -webkit-transform: perspective(400px) rotateY(90deg);
                transform: perspective(400px) rotateY(90deg);

        opacity: 0;
    }

    0%,
    40%
    {
        -webkit-animation-timing-function: ease-in;
                animation-timing-function: ease-in;
    }

    40%
    {
        -webkit-transform: perspective(400px) rotateY(-40deg);
                transform: perspective(400px) rotateY(-40deg);
    }

    60%
    {
        -webkit-transform: perspective(400px) rotateY(3deg);
                transform: perspective(400px) rotateY(3deg);

        opacity: 1;
    }

    80%
    {
        -webkit-transform: perspective(400px) rotateY(-2deg);
                transform: perspective(400px) rotateY(-2deg);
    }

    100%
    {
        -webkit-transform: perspective(400px);
                transform: perspective(400px);
    }
}
