
.chosen-container-active.chosen-with-drop .chosen-single
{
    //border: .2rem solid var(--gray-50);
    border: 0.2rem solid var(--green-300);
    border-bottom-right-radius: 3.2rem;
    border-bottom-left-radius: 3.2rem;
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(20%, #fff), color-stop(80%, #fff));
    background-image: linear-gradient(#fff 20%, #fff 80%);
    -webkit-box-shadow: 0 0 0 #fff inset;
            box-shadow: 0 0 0 #fff inset;
}

.chosen-container-single .chosen-single
{
    font-size: var(--extra-small);
    line-height: 4.8rem;

    height: 4.8rem;
    padding: 0 1.9rem;

    color: var(--black);
    border: 0.2rem solid var(--gray-150);
    border-radius: 3.2rem;
    //background: -webkit-gradient(linear, left top, left bottom, color-stop(20%, #fff), color-stop(50%, #f6f6f6), color-stop(52%, #eee), to(#f4f4f4));
    background: linear-gradient(var(--gray-150) 20%, var(--gray-150) 100%);
    background-color: var(--gray-150);
    -webkit-box-shadow: 0 0 0 #fff inset, 0 0 0 rgba(0, 0, 0, 0);
            box-shadow: 0 0 0 #fff inset, 0 0 0 rgba(0, 0, 0, 0);


    @media (min-width: $tablet-media) {
        line-height: 5.6rem;
        height: 5.6rem;
    }  //768

    @media (min-width: $desktop-media) {
        line-height: 6.4rem;
        height: 6.4rem;
    } //1024

}

//.chosen-container-single .chosen-single:hover {
//    background: var(--gray-50);
//    border-color: var(--gray-50);
//}

.chosen-container-single .chosen-single div b
{
    //background: url('chosen-sprite.png') no-repeat 1px 2px;
    background: no-repeat 50% 55% url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNNSA4LjVMMTIgMTUuNUwxOSA4LjUiIHN0cm9rZT0iIzVGNjA2MiIgc3Ryb2tlLXdpZHRoPSIxLjUiLz48L3N2Zz4=);

    @media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-resolution: 144dpi), only screen and (min-resolution: 1.5dppx) {
        background-position: 0 0;
        height: 15px;
        width: 15px;
        position: relative;
        top: 1rem;
    }
}

.chosen-container-active.chosen-with-drop .chosen-single div b
{
    background: no-repeat 50% 50% url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNNSAxNS41TDEyIDguNUwxOSAxNS41IiBzdHJva2U9IiM1RjYwNjIiIHN0cm9rZS13aWR0aD0iMS41Ii8+PC9zdmc+);

    @media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-resolution: 144dpi), only screen and (min-resolution: 1.5dppx) {
         background-position: 40% 0%;
    }
}

.chosen-container-single .chosen-single div
{
    right: 2.2rem;
}

.chosen-container-single .chosen-single span
{
    color: black;
}

.chosen-container-single .chosen-drop
{
    border-radius: 0;
}

.chosen-container .chosen-drop
{
    font-size: var(--extra-small);

    margin-top: 0.8rem;

    border: 0.1rem solid rgba(0, 0, 0, 0.1);
    border-radius: 3.2rem;
    //-webkit-box-shadow: 0 .4rem .5rem rgba(0, 0, 0, 0.15);
    background: var(--white);
    box-shadow: 0 12px 24px 6px rgba(97, 97, 97, 0.08), 0 24px 48px 6px rgba(97, 97, 97, 0.08);
}

.chosen-container
{
    font-size: 15px;

    color: blue;
}

.chosen-container-single .chosen-single
{
    color: var(--black);
}

.chosen-container .chosen-results
{
    height: 100%;
    min-height: 3rem;
    max-height: 38rem;
    margin: 2rem;
    padding: 0;
}

.chosen-container .chosen-results li
{
    padding: 1.6rem 2rem;

    color: var(--black);
}

.chosen-container .chosen-results li.disabled-result
{
    display: list-item;

    cursor: default;

    color: red;
}

.chosen-container .chosen-results li.highlighted
{
    color: var(--black);
    background-color: var(--gray-200);
    background-image: none;
    //background-image: linear-gradient(var(--white) 20%, var(--white) 90%);
}

.chosen-container .chosen-results li.highlighted:active
{
    color: var(--black);
    background: var(--white);
}

.chosen-container
{
    width: 100% !important;
}




//
//.chosen-container-active.chosen-with-drop .chosen-single
//{
//    box-shadow: none;
//    background-image: none;
//
//    border: .1rem solid var(--green-300);
//    color: var(--green-300);
//    border: transparent;
//}
//
//
////In2
//.chosen-container-single .chosen-single
//{
//    width: 100%;
//    font-size: var(--extra-small);
//    line-height: 6.4rem;
//
//    height: 6.4rem;
//    padding: 0 2rem;
//
//    color: var(--black);
//    border: .1rem solid var(--gray-150);
//    border-radius: 6.4rem;
//    background: var(--gray-150);
//    box-shadow: none;
//
//}
//
//.chosen-single_no-click {
//    color: #6D7885 !important;
//}
//
//.chosen-container-single .chosen-single div b
//{
//    background: url('chosen-sprite.png') no-repeat .1rem .2rem;
//}
//
//.chosen-container-active.chosen-with-drop .chosen-single div b
//{
//    background-position: -1.7rem .2rem;
//}
//
////In2
//.chosen-container-single .chosen-single div
//{
//    right: 1.6rem;
//    top: 1.6rem;
//}
//
////In2
//.chosen-container-single .chosen-single span
//{
//    //color: $mainText2;
//}
//.Search-SelectField_no-active .chosen-container-single .chosen-single span {
//    //color: $gray;
//}
//
//
//.chosen-container-single .chosen-drop
//{
//    //border-radius: 0;
//}
//
////In2
//.chosen-container .chosen-drop
//{
//    margin-top: .8rem;
//    //border: 1px solid #0078bf;
//    border: none;
//    border-radius: 3.2rem;
//    background: var(--white);
//    box-shadow: 0 .6rem 2.4rem .6rem rgba(97, 97, 97, 0.08), 0 2.4rem 4.8rem .6rem rgba(97, 97, 97, 0.08);
//}
//
////In2
//.chosen-container
//{
//    font-size: 1.6rem;
//    color: #000;
//}
//
////.chosen-container-single .chosen-single
////{
////    color: black;
////}
//
//.chosen-container .chosen-results {
//    margin: 0;
//    padding: 2rem 0;
//}
//
//.chosen-container .chosen-results li.disabled-result
//{
//    display: list-item;
//
//    cursor: default;
//
//    color: #000;
//}
//
//.chosen-container .chosen-results li {
//    padding: 1.2rem 2rem;
//}
//
//
//.chosen-container .chosen-results li.highlighted
//{
//    color: var(--mainText2);
//    background: #e5f3fc;
//    border-radius: .6rem;
//}
//
//.chosen-container .chosen-results li.highlighted:active {
//    background: var(--blue7);
//    color: var(--white);
//}
//
//
////Init
//.chosen-container
//{
//    width: 100% !important;
//    border: .1rem solid var(--grayLight6);
//    border-radius: .8rem;
//    color: var(--mainText2);
//
//}
//
//
//.chosen-container-active.chosen-with-drop .chosen-single {
//    border-radius: 6.4rem;
//}
////.chosen-container-single .chosen-single {}
////.chosen-container-single .chosen-single div {}
//
