.SliderCarouselPosit
{
    margin-bottom: 3.2rem;
    padding-bottom: 5.7rem;

    @media screen and (max-width: $tablet-media)
    {
        padding-bottom: 0;
    }

    /* @media screen and (max-width: $mobile-media)
    {
        margin-bottom: 0;
    } */

    &_with_border
    {
        border-bottom: 0.2rem solid var(--black);
    }
}

.SliderCarousel
{
    width: 100%;
    max-width: 160rem;

    @media (min-width: 1600px)
    {
        max-width: 100%;
        margin-right: auto;
        margin-left: auto;
    }

    //1920


    @media (min-width: $full-desktop-media)
    {
        padding: 0;
    }

    //1920

    &-Slick
    {
        width: calc(100% - 2px);
        max-width: 182.4rem;
        margin: 0 auto;
        padding-right: 1.6rem;
        padding-left: 1.6rem;

        @media screen and (min-width: $mobile-media)
        {
            padding-right: 3.2rem;
            padding-left: 3.2rem;
        }

        @media (min-width: $medium-desktop-media)
        {
            padding-right: 11rem;
            padding-left: 11rem;
        }
    }

    .slick-prev,
    .slick-next
    {
        z-index: 100;
        top: 50%;

        width: 6.4rem;
        height: 6.4rem;

        transform: translate(0, -50%);

        border: 0.1rem solid var(--black);
        border-radius: 6.4rem;

        &.slick-disabled
        {
            opacity: 0.2;
        }
    }

    .slick-prev
    {
        //left: -11.2rem;
        background: no-repeat 50% 50% url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIHZpZXdCb3g9IjAgMCA0MCA0MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMzQuOTk5NiAxOS45OTk4TDUgMTkuOTk5OE01IDE5Ljk5OThMMTkuOTk5OCA1TTUgMTkuOTk5OEM1IDE5Ljk5OTggMTQuMTQyIDI5LjE0MTggMTkuOTk5OCAzNC45OTk2IiBzdHJva2U9ImJsYWNrIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJzcXVhcmUiLz48L3N2Zz4=);

        &:hover,
        &:focus
        {
            border-color: var(--green-300);
            background: var(--green-300) no-repeat 50% 50% url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIHZpZXdCb3g9IjAgMCA0MCA0MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMzQuOTk5NiAxOS45OTk4TDUgMTkuOTk5OE01IDE5Ljk5OThMMTkuOTk5OCA1TTUgMTkuOTk5OEM1IDE5Ljk5OTggMTQuMTQyIDI5LjE0MTggMTkuOTk5OCAzNC45OTk2IiBzdHJva2U9IndoaXRlIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJzcXVhcmUiLz48L3N2Zz4=);
        }

        &.slick-disabled
        {
            &:hover,
            &:focus
            {
                border-color: var(--black);
                background: no-repeat 50% 50% url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIHZpZXdCb3g9IjAgMCA0MCA0MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMzQuOTk5NiAxOS45OTk4TDUgMTkuOTk5OE01IDE5Ljk5OThMMTkuOTk5OCA1TTUgMTkuOTk5OEM1IDE5Ljk5OTggMTQuMTQyIDI5LjE0MTggMTkuOTk5OCAzNC45OTk2IiBzdHJva2U9ImJsYWNrIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJzcXVhcmUiLz48L3N2Zz4=);
            }
        }
    }

    .slick-next
    {
        //right: -11.2rem;
        background: no-repeat 50% 50% url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIHZpZXdCb3g9IjAgMCA0MCA0MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNNS4wMDA0IDE5Ljk5OThMMzUgMTkuOTk5OE0zNSAxOS45OTk4TDIwLjAwMDIgNU0zNSAxOS45OTk4QzM1IDE5Ljk5OTggMjUuODU4IDI5LjE0MTggMjAuMDAwMiAzNC45OTk2IiBzdHJva2U9ImJsYWNrIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJzcXVhcmUiLz48L3N2Zz4=);

        &:hover,
        &:focus
        {
            border-color: var(--green-300);
            background: var(--green-300) no-repeat 50% 50% url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIHZpZXdCb3g9IjAgMCA0MCA0MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNNS4wMDA0IDE5Ljk5OThMMzUgMTkuOTk5OE0zNSAxOS45OTk4TDIwLjAwMDIgNU0zNSAxOS45OTk4QzM1IDE5Ljk5OTggMjUuODU4IDI5LjE0MTggMjAuMDAwMiAzNC45OTk2IiBzdHJva2U9IndoaXRlIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJzcXVhcmUiLz48L3N2Zz4=);
        }

        &.slick-disabled
        {
            &:hover,
            &:focus
            {
                border-color: var(--black);
                background: no-repeat 50% 50% url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIHZpZXdCb3g9IjAgMCA0MCA0MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNNS4wMDA0IDE5Ljk5OThMMzUgMTkuOTk5OE0zNSAxOS45OTk4TDIwLjAwMDIgNU0zNSAxOS45OTk4QzM1IDE5Ljk5OTggMjUuODU4IDI5LjE0MTggMjAuMDAwMiAzNC45OTk2IiBzdHJva2U9ImJsYWNrIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJzcXVhcmUiLz48L3N2Zz4=);
            }
        }
    }

    .slick-prev
    {
        left: 5px;

        [dir='rtl'] &
        {
            right: -125px;
            left: auto;
        }
    }

    .slick-next
    {
        right: 5px;

        [dir='rtl'] &
        {
            right: auto;
            left: -125px;
        }
    }
}

.slick-slide:nth-child(4n+4)
{
    .NewsSlider-Item
    {
        border: transparent;
    }
}
