.Page
{
    font-family: 'TT Firs Neue', Arial, Helvetica, sans-serif;
    font-size: 100%;

    //min-width: 160rem;
    height: 100%;

    &_no-scroll
    {
        //overflow: hidden;
    }
}
