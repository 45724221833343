.Modal
{
    position: fixed;
    z-index: 9999;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    display: none;

    &[data-modal-id='ModalWebCam']
    {
        .Modal-Close
        {
            @media screen and (max-width: $mobile-media)
            {
                top: 0.8rem;
                right: 0;
            }
        }
    }

    &_inform-text
    {
        .Form_in-modal
        {
            padding-top: 4.8rem;
        }
    }

    &_open
    {
        display: flex;

        justify-content: center;
        align-items: center;
    }

    &-Window
    {
        position: relative;
        z-index: 9999;

        display: block;
        overflow: auto;

        width: 100%;
        max-height: 100vh;

        border-radius: 0;
        //margin: auto;
        background-color: var(--white);

        @media screen and (min-width: $small-mobile-media)
        {
            width: calc( 100% - 4rem );
            max-height: 94vh;

            //border-radius: 1.6rem;
        }

        @media (min-width: $tablet-media)
        {
            width: auto;
            min-width: 32rem;
            margin: 2.4rem;
            margin: 1.6rem;

            border-radius: 0;
        }

        //768

        @media (min-width: $desktop-media)
        {
            margin: 3.2rem;
        }

        //1024

        &_wide
        {
            width: 100%;
            margin: 0;
        }

        .Tabs-Caption
        {
            display: flex;
        }

        iframe,
        object
        {
            display: block;

            max-width: 100%;

            @media screen and (max-width: $mobile-media)
            {
                width: 100%;
                height: auto;
                min-height: 50vh;
            }
        }
        
        object {
            width: 100%;
        }
    }

    &-Title-Container
    {
        padding: 2.4rem 9.6rem 2.4rem 4.8rem;

        border-bottom: 1px solid var(--gray-25);

        @media screen and (max-width: $mobile-media)
        {
            padding: 1.9rem 2.7rem;
        }
    }

    &-Title
    {
        font-size: var(--medium);
        font-weight: bold;
        line-height: 3.2rem;

        text-align: left;
        letter-spacing: 0.06rem;

        @media screen and (max-width: $mobile-media)
        {
            font-size: var( --additional-small );
            line-height: 1.4;

            padding-right: 1.8rem;
        }
    }

    &-Close
    {
        position: absolute;
        z-index: 100;
        top: 2.8rem;
        right: 3.6rem;

        width: 3.2em;
        height: 3.2rem;

        cursor: pointer;

        @media screen and (max-width: $mobile-media)
        {
            top: 2.3rem;
            right: 1rem;
        }

        /*         @media screen and (max-width: $small-mobile-media)
        {
            right: 0;
        } */

        &::before
        {
            display: block;

            width: 2.4rem;
            height: 2.4rem;

            content: '';

            background: no-repeat 0 50% url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTExLjk5ODMgMTAuNTQ1M0w0Ljk0NzUxIDMuNDk0NjFMMy40OTI5NiA0Ljk0OTE1TDEwLjU0MzcgMTEuOTk5OEwzLjQ5Mjc5IDE5LjA1MDdMNC45NDczMyAyMC41MDUyTDExLjk5ODMgMTMuNDU0NEwxOS4wNDk0IDIwLjUwNTRMMjAuNTAzOSAxOS4wNTA5TDEzLjQ1MjggMTEuOTk5OEwyMC41MDM5IDQuOTQ5MDhMMTkuMDQ5NCAzLjQ5NDU1TDExLjk5ODMgMTAuNTQ1M1oiIGZpbGw9ImJsYWNrIi8+Cjwvc3ZnPgo=);
        }

        &_white
        {
            &::before
            {
                background: no-repeat 0 50% url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMTAuNTQ1NSAxMi4wMDAxTDQuMDAwMTYgMTguNTQ1NEw1LjQ1NDY5IDIwTDEyIDEzLjQ1NDZMMTguNTQ1MyAyMEwxOS45OTk4IDE4LjU0NTVMMTMuNDU0NSAxMi4wMDAxTDIwIDUuNDU0NTRMMTguNTQ1NSA0TDEyIDEwLjU0NTVMNS40NTQ1MyA0LjAwMDAyTDQgNS40NTQ1NkwxMC41NDU1IDEyLjAwMDFaIiBmaWxsPSJ3aGl0ZSIvPjwvc3ZnPg==);
            }
        }

        &_overlay
        {
            line-height: 1.3;

            top: 7rem;

            display: flex;
            /* padding: 1.7em; */

            width: 4rem;
            height: 4rem;

            text-align: center;

            border-radius: 50%;
            background: var(--gray-350);

            &:before
            {
                margin: auto;
            }
        }

        &_gray
        {
            top: 2.4rem;
            right: 2.4rem;

            &::before
            {
                background-color: rgba(255, 255, 255, 0.4);
                background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMTAuNTQ1NSAxMi4wMDAxTDQuMDAwMTYgMTguNTQ1NEw1LjQ1NDY5IDIwTDEyIDEzLjQ1NDZMMTguNTQ1MyAyMEwxOS45OTk4IDE4LjU0NTVMMTMuNDU0NSAxMi4wMDAxTDIwIDUuNDU0NTRMMTguNTQ1NSA0TDEyIDEwLjU0NTVMNS40NTQ1MyA0LjAwMDAyTDQgNS40NTQ1NkwxMC41NDU1IDEyLjAwMDFaIiBmaWxsPSJncmV5Ii8+PC9zdmc+);
                background-repeat: no-repeat;
                background-position: 0 50%;
            }
        }
    }

    &-Img
    {
        display: block;
    }

    &-Footer
    {
        display: flex;

        padding: 2.4rem 4.8rem;

        border-top: 0.1rem solid var(--gray-25);

        gap: 2.4rem;

        @media screen and (max-width: $mobile-media)
        {
            padding-right: 1.6rem;
            padding-left: 1.6rem;
        }
    }

    &-ProgressBar
    {
        width: 100%;
    }

    &-FooterTextWrapper
    {
        font-size: var(--small);

        margin-bottom: 1.2rem;
    }

    &-FooterText
    {
        line-height: 1.4;

        color: var(--gray-60);

        &_green
        {
            color: var(--green-300);
        }
    }

    &-FooterButtons
    {
        display: flex;

        gap: 1.2rem;
    }

    &-Footer
    {
        display: flex;

        padding: 2.4rem 4.8rem;

        border-top: 0.1rem solid var(--gray-25);

        gap: 2.4rem;
    }

    &-ProgressBar
    {
        width: 100%;
    }

    &-FooterTextWrapper
    {
        font-size: var(--small);

        margin-bottom: 1.2rem;
    }

    &-FooterText
    {
        line-height: 1.4;

        color: var(--gray-60);

        &_green
        {
            color: var(--green-300);
        }
    }

    &-FooterButtons
    {
        display: flex;

        gap: 1.2rem;
    }
}

.ModalOverlay
{
    position: fixed;
    z-index: 9997;
    top: 0;
    left: 0;

    display: none;

    width: 100%;
    height: 100%;

    cursor: pointer;

    background: rgba(0, 0, 0, 0.4);

    &_open
    {
        display: block;
    }

    iframe
    {
        margin: -3px;
    }
}
