.Form
{
    &_in-modal
    {
        width: 100%;
        max-width: 57.6rem;
        padding: 3.2rem 4.8rem 4.8rem;

        @media screen and (max-width: $mobile-media)
        {
            max-width: 100%;
            padding: 2.6rem 2.4rem;            
        }
    }

    &-Row
    {
        margin-top: 3.4rem;

        @media screen and (max-width: $mobile-media)
        {
            margin-top: 1.6rem;
        }

        &_vertical
        {
            display: flex;
            flex-direction: column;
        }

        &_icon
        {
            .chosen-single
            {
                padding-left: 4.7rem;

                background-repeat: no-repeat;
                background-position: 1.75rem center;
                background-size: 2rem 2rem;
            }

            &-clock
            {
                .chosen-single
                {
                    background-image: url('data:image/svg+xml;base64,PHN2ZyBjbGFzcz0ic3ZnLWljb24iIHN0eWxlPSJ3aWR0aDogMWVtOyBoZWlnaHQ6IDFlbTt2ZXJ0aWNhbC1hbGlnbjogbWlkZGxlO2ZpbGw6IGN1cnJlbnRDb2xvcjtvdmVyZmxvdzogaGlkZGVuOyIgdmlld0JveD0iMCAwIDEwMjQgMTAyNCIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxwYXRoIGQ9Ik00NzEuODQ5NTU4IDUzNi44ODg4NTNjLTQuMTM5Mjc0LTcuMjk5MjQzLTYuMjEyNDkyLTE1LjQ0NjgwNy02LjE5NTA5Ni0yMy42MDg2OTdsMC0wLjA4NTk1OCAwLTIzOS44MTE4MDhjMC0yNi41OTU3MjggMjEuMzExMzc1LTQ4LjE1NTc2NiA0OC4wMTg2NDMtNDguMTU1NzY2IDI2LjUxODk4IDAgNDguMDE1NTczIDIxLjUzMzQzMiA0OC4wMTU1NzMgNDguMTU1NzY2bDAgMjE5Ljk2MDY5MSAxMDUuMDAyNDcyIDEwNC45OTgzNzljMTguNjc1MzM4IDE4LjY3OTQzMSAxOC43NDM5IDQ4Ljg5MDUtMC4xMzcxMjMgNjcuNzcxNTIzLTE4Ljc1MzEwOSAxOC43NTIwODYtNDkuMjM4NDI0IDE4LjY3MDIyMi02Ny43NzE1MjMgMC4xMzcxMjNMNDgxLjM0MTc0NiA1NDguODEwMzcxQzQ3Ny41ODUxODkgNTQ1LjM3NjE1NSA0NzQuMzc0MDU0IDU0MS4zNTM1MzggNDcxLjg0OTU1OCA1MzYuODg4ODUzTDQ3MS44NDk1NTggNTM2Ljg4ODg1M3pNNTEzLjY3MzEwNiA4OTcuNDgxNDkyYzIxMi4xNTU5MzQgMCAzODQuMTQ1MDU0LTE3MS45ODkxMTkgMzg0LjE0NTA1NC0zODQuMTQ1MDU0IDAtMjEyLjE2MDAyOC0xNzEuOTg5MTE5LTM4NC4xNDgxMjQtMzg0LjE0NTA1NC0zODQuMTQ4MTI0LTIxMi4xNjAwMjggMC0zODQuMTQ4MTI0IDE3MS45ODgwOTYtMzg0LjE0ODEyNCAzODQuMTQ4MTI0QzEyOS41MjQ5ODIgNzI1LjQ5MjM3MiAzMDEuNTEzMDc4IDg5Ny40ODE0OTIgNTEzLjY3MzEwNiA4OTcuNDgxNDkyTDUxMy42NzMxMDYgODk3LjQ4MTQ5MnpNNTEzLjY3MzEwNiA4OTcuNDgxNDkyIiBmaWxsPSIjNTU1NTU1IiAvPjwvc3ZnPg==') !important;
                }
            }
        }
    }

    &-Text
    {
        font-size: var(--extra-small);
        font-weight: 400;
        font-style: normal;
        line-height: 1.5;

        letter-spacing: 0.03rem;

        &_center
        {
            text-align: center;

            @media screen and (max-width: $mobile-media)
            {
                padding-bottom: .5rem;
            }
        }

        &_icon
        {
            font-size: var(--medium);
            font-weight: bold;

            display: flex;
            flex-direction: column;

            max-width: 57.6rem;

            text-align: center;

            gap: 2.4rem;

            &_send
            {
                &::before
                {
                    display: block;

                    width: 8rem;
                    height: 8rem;
                    margin: 0 auto;

                    content: '';

                    background: no-repeat 50% 50% url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iODAiIGhlaWdodD0iODAiIHZpZXdCb3g9IjAgMCA4MCA4MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNNzguNTAwOCA0MC4wMDA0Qzc4LjUwMDggNjEuMjYzNiA2MS4yNjM2IDc4LjUwMDggNDAuMDAwNCA3OC41MDA4QzE4LjczNzIgNzguNTAwOCAxLjUgNjEuMjYzNiAxLjUgNDAuMDAwNEMxLjUgMTguNzM3MiAxOC43MzcyIDEuNSA0MC4wMDA0IDEuNUM2MS4yNjM2IDEuNSA3OC41MDA4IDE4LjczNzIgNzguNTAwOCA0MC4wMDA0WiIgc3Ryb2tlPSIjMDBBNjU1IiBzdHJva2Utd2lkdGg9IjMiIHN0cm9rZS1saW5lY2FwPSJzcXVhcmUiLz48cGF0aCBkPSJNNTkgMjdMMzIuODc1IDUzLjEyNUwyMSA0MS4yNSIgc3Ryb2tlPSIjMDBBNjU1IiBzdHJva2Utd2lkdGg9IjQiIHN0cm9rZS1saW5lY2FwPSJzcXVhcmUiLz48L3N2Zz4=);
                }
            }
        }
    }

    &-Title
    {
        font-size: var(--small);
        font-weight: 400;

        margin-bottom: 0.6rem;

        letter-spacing: 0.02rem;

        color: var(--gray-450);
    }

    &-Error
    {
        font-size: var(--small);

        margin-top: 1rem;
        margin-left: 2rem;

        color: var(--red-500);
    }

    &-Input
    {
        @include TextInput;

        &_error
        {
            outline: 0.2rem solid var(--red-500);
        }

        &_icon
        {
            padding-left: 4.8rem;

            background-repeat: no-repeat;
            background-position: 2rem center;
            background-size: 2rem 2rem;

            &-name
            {
                background-image: url('data:image/svg+xml;base64,PHN2ZyBjbGFzcz0ic3ZnLWljb24iIHN0eWxlPSJ3aWR0aDogMS4wMjczNDM3NWVtOyBoZWlnaHQ6IDFlbTt2ZXJ0aWNhbC1hbGlnbjogbWlkZGxlO2ZpbGw6IGN1cnJlbnRDb2xvcjtvdmVyZmxvdzogaGlkZGVuOyIgdmlld0JveD0iMCAwIDEwNTIgMTAyNCIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxwYXRoIGQ9Ik00OTYuOTgxMzMzIDEyOGMtMTI1LjUyNTMzMyAwLTIyNy43MjYyMjIgMTAyLjE3MjQ0NC0yMjcuNzI2MjIyIDIyNy43NTQ2NjcgMCAxMjUuNDk2ODg5IDEwMi4xMTU1NTYgMjI3LjcyNjIyMiAyMjcuNzI2MjIyIDIyNy43MjYyMjIgMTI1LjU1Mzc3OCAwIDIyNy43NTQ2NjctMTAyLjIyOTMzMyAyMjcuNzU0NjY3LTIyNy43MjYyMjIgMC0xMjUuNTgyMjIyLTEwMi4yMDA4ODktMjI3Ljc1NDY2Ny0yMjcuNzU0NjY3LTIyNy43NTQ2Njd6TTY3NS4zMjggNTgzLjQ4MDg4OWMtNDguNjY4NDQ0IDM4LjQtMTA5LjI4MzU1NiA2Mi4yMDgtMTc2LjA3MTExMSA2Mi4yMDgtNjYuNzg3NTU2IDAtMTI3LjM0NTc3OC0yMy44OTMzMzMtMTc2LjEyOC02Mi4yMDgtOTIuMzU5MTExIDU4LjY4MDg4OS0xNTYuNjcyIDE2MS43NjM1NTYtMTY1LjQ4OTc3OCAyODEuMTQ0ODg5IDMyLjM0MTMzMyA0LjM4MDQ0NCA4OS44ODQ0NDQgOS4xMDIyMjIgMTcxLjk3NTExMSA5LjEwMjIyMmgzMzkuMTQzMTExYzgyLjE0NzU1NiAwIDEzOS43MTkxMTEtNC43MjE3NzggMTcyLjA4ODg4OS05LjEwMjIyMi04Ljc2MDg4OS0xMTkuNDY2NjY3LTczLjA0NTMzMy0yMjIuNDM1NTU2LTE2NS41NDY2NjYtMjgxLjE0NDg4OXoiIGZpbGw9IiM1NTU1NTUiIC8+PC9zdmc+');
            }

            &-email
            {
                background-image: url('data:image/svg+xml;base64,PHN2ZyBjbGFzcz0ic3ZnLWljb24iIHN0eWxlPSJ3aWR0aDogMWVtOyBoZWlnaHQ6IDFlbTt2ZXJ0aWNhbC1hbGlnbjogbWlkZGxlO2ZpbGw6IGN1cnJlbnRDb2xvcjtvdmVyZmxvdzogaGlkZGVuOyIgdmlld0JveD0iMCAwIDEwMjQgMTAyNCIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxwYXRoIGQ9Ik00OTAuMzM4NDYyIDU5Mi43Mzg0NjJjMTEuODE1Mzg1IDExLjgxNTM4NSAyOS41Mzg0NjIgMTEuODE1Mzg1IDQxLjM1Mzg0NiAwbDQ0NS4wNDYxNTQtNDEzLjUzODQ2MmM5Ljg0NjE1NC0xNS43NTM4NDYgNy44NzY5MjMtNDEuMzUzODQ2LTIzLjYzMDc3LTQxLjM1Mzg0NmwtODgyLjIxNTM4NCAxLjk2OTIzMWMtMjMuNjMwNzY5IDAtNDMuMzIzMDc3IDIxLjY2MTUzOC0yNS42IDQxLjM1Mzg0Nmw0NDUuMDQ2MTU0IDQxMS41NjkyMzF6TTk4NC42MTUzODUgMzQwLjY3NjkyM2MwLTE5LjY5MjMwOC0yMy42MzA3NjktMzEuNTA3NjkyLTM5LjM4NDYxNi0xNy43MjMwNzdMNTk2LjY3NjkyMyA2NDMuOTM4NDYyYy0yMy42MzA3NjkgMjEuNjYxNTM4LTUzLjE2OTIzMSAzMy40NzY5MjMtODQuNjc2OTIzIDMzLjQ3NjkyM3MtNjEuMDQ2MTU0LTExLjgxNTM4NS04NC42NzY5MjMtMzEuNTA3NjkzTDgwLjczODQ2MiAzMjIuOTUzODQ2Yy0xNS43NTM4NDYtMTMuNzg0NjE1LTM5LjM4NDYxNS0zLjkzODQ2Mi0zOS4zODQ2MTYgMTcuNzIzMDc3QzM5LjM4NDYxNSA0MjkuMjkyMzA4IDM5LjM4NDYxNSA2NjkuNTM4NDYyIDM5LjM4NDYxNSA3ODcuNjkyMzA4YzAgNDMuMzIzMDc3IDM1LjQ0NjE1NCA3OC43NjkyMzEgNzguNzY5MjMxIDc4Ljc2OTIzaDc4Ny42OTIzMDhjNDMuMzIzMDc3IDAgNzguNzY5MjMxLTM1LjQ0NjE1NCA3OC43NjkyMzEtNzguNzY5MjNWMzQwLjY3NjkyM3oiIGZpbGw9IiM1NTU1NTUiIC8+PC9zdmc+');;
                background-size: 1.75rem 1.75rem;
            }

            &-phone
            {
                background-image: url('data:image/svg+xml;base64,PHN2ZyBjbGFzcz0ic3ZnLWljb24iIHN0eWxlPSJ3aWR0aDogMWVtOyBoZWlnaHQ6IDFlbTt2ZXJ0aWNhbC1hbGlnbjogbWlkZGxlO2ZpbGw6IGN1cnJlbnRDb2xvcjtvdmVyZmxvdzogaGlkZGVuOyIgdmlld0JveD0iMCAwIDEwMjQgMTAyNCIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxwYXRoIGQ9Ik0yNjAuOCA2MzQuODhDMTQ0LjMyIDQ5NS42OCA4OC45NiAzNTIgMTA4LjggMjQxLjI4di01LjEyYzU5LjUyLTE1Ni4xNiAyMzAuMDgtMTYwIDI1Ni03Ni4xNkw0MTYgMzA5Ljc2YTcwLjA4IDcwLjA4IDAgMCAxLTE2Ljk2IDcxLjY4bC01MS4yIDUxLjJhNDc0LjU2IDQ3NC41NiAwIDAgMCAyMzMuNiAyMzQuNTZsNDMuMi00My4yYTcwLjA4IDcwLjA4IDAgMCAxIDY2LjU2LTE4LjU2bDE3Mi44IDQyLjg4YzgxLjkyIDIzLjM2IDg3LjY4IDE5Mi03OS4wNCAyNjEuNDQtMTczLjQ0IDQ4LjMyLTQwNC4xNi0xMzEuODQtNTI0LjE2LTI3NC44OHoiIGZpbGw9IiM1NTU1NTUiIC8+PC9zdmc+');
            }

            &-passport
            {
                background-image: url('data:image/svg+xml;base64,PHN2ZyBjbGFzcz0ic3ZnLWljb24iIHN0eWxlPSJ3aWR0aDogMWVtO2hlaWdodDogMWVtO3ZlcnRpY2FsLWFsaWduOiBtaWRkbGU7ZmlsbDogY3VycmVudENvbG9yO292ZXJmbG93OiBoaWRkZW47IiB2aWV3Qm94PSIwIDAgMTAyNCAxMDI0IiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTI2Ni4yNCA2MS40NGgzNjguNjRjMTEzLjExMTA0IDAgMjA0LjggOTEuNjg4OTYgMjA0LjggMjA0Ljh2NDkxLjUyYzAgMTEzLjExMTA0LTkxLjY4ODk2IDIwNC44LTIwNC44IDIwNC44SDI2Ni4yNGE4MS45MiA4MS45MiAwIDAgMS04MS45Mi04MS45MlYxNDMuMzZhODEuOTIgODEuOTIgMCAwIDEgODEuOTItODEuOTJ6IG05Mi4xNiA1NzMuNDRhMzAuNzIgMzAuNzIgMCAwIDAgMCA2MS40NGgzMDcuMmEzMC43MiAzMC43MiAwIDAgMCAwLTYxLjQ0aC0zMDcuMnogbTgxLjkyIDEyMi44OGEzMC43MiAzMC43MiAwIDAgMCAwIDYxLjQ0aDE2My44NGEzMC43MiAzMC43MiAwIDAgMCAwLTYxLjQ0aC0xNjMuODR6TTUxMiA1MTJhMTQzLjM2IDE0My4zNiAwIDEgMCAwLTI4Ni43MiAxNDMuMzYgMTQzLjM2IDAgMCAwIDAgMjg2LjcyeiIgZmlsbD0iIzU1NTU1NSIgLz48L3N2Zz4=');
            }
        }
    }

    &-Submit
    {
        width: 23rem;

        @include InputSubmit;
    }

    &-WarnText
    {
        font-size: var(--small);
        font-weight: 400;
        font-style: normal;
        line-height: 2rem;

        margin-top: 3.3rem;

        text-align: center;
        letter-spacing: 0.02rem;

        @media screen and (max-width: $mobile-media)
        {
            margin-top: 2.3rem;
        }
    }

    &-PersonalAgreementLink
    {
        color: var(--green-300);
    }
}

.popupCookies
{
    display: none;
    flex-direction: row;

    position: fixed;
    bottom: 3rem;

    background-color: #fff;
    width: 85%;
    margin: auto;
    left: 50%;
    margin-left: -42.5%;
    z-index: 1000;

    padding: 24px;
    font-size: 16px;
    line-height: 24px;

    box-shadow: 0 .5rem 1rem .13rem rgba(61,61,61, .1), 0 1rem 2rem .13rem rgba(61,61,61, .1);

    @media screen and (max-width: $landscape-mobile-media) {
        flex-direction: column;
        align-items: center;
        gap: 1.5rem;
    }

    &-active
    {
        display: flex;
    }

    button
    {
        border: none;
        background: none;
        font-size: 16px;
        line-height: 24px;
        font-weight: bold;
        margin-left: 24px;
        padding: 12px 24px;
        border: solid 1px #000;
        border-radius: 24px;
    }
}