.ChooseAdditional
{
    &-Label
    {
        font-size: var(--extra-small);
        font-weight: 400;
        line-height: 1.5;

        margin-bottom: 3.2rem;

        letter-spacing: 0.02rem;
    }
}
