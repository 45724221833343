.ActionIcons
{
    display: flex;

    gap: 0.8rem;

    &_posit
    {
        &_absolute
        {
            position: absolute;
            top: 0;
            right: 0;

            @media screen and (max-width: $large-desktop-media-second) {
                right: 3.2rem;
            }

            @media screen and (max-width: $desktop-media) {
                right: 0;
            }
        }
    }

    .ya-share2__icon_more
    {
        width: 4.1rem !important;
        height: 4.1rem !important;
    }
}
