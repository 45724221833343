.Links {

  @include AdaptiveBlock;

  margin-bottom: 3rem;

  @media (min-width: $tablet-media) {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 2.4rem;
  }  //768

  @media (min-width: $second-medium-desktop-media)
  {
    display: block;
  } //1407


  .NoTabs-CaptionSelect + .chosen-container
  {
    width: 100% !important;
    margin-right: 0;
    margin-left: 0;

    @media (min-width: $tablet-media)
    {
      width: 100% !important;
      margin-right: 0;
      margin-left: 0;
    }

    //768
    @media (min-width: $desktop-media)
    {
      width: 100% !important;
      margin-right: 0;
      margin-left: 0;
    }

    //1024

    @media (min-width: $second-medium-desktop-media)
    {
      display: none;
    } //1407
  }


  .Button-Map {
      width: 100%;
      padding-top: 1.2rem;
      padding-bottom: 1.2rem;
      border-radius: 3.2rem;

      @media (min-width: $tablet-media)
      {
        width: 50%;
        padding-top: 1.8rem;
        padding-bottom: 1.8rem;
      }//768

      @media (min-width: $desktop-media) {
        padding-top: 2rem;
        padding-bottom: 2rem;
      } //1024

      @media (min-width: $second-medium-desktop-media)
      {
        display: none;
      } //1407
  }


  @media (min-width: $second-medium-desktop-media) {

    .Button {
      display: none;
    }

  } //1407

  &-Select {
    width: 100%;

    @media (min-width: $tablet-media)
    {
      width: 50%;
    }//768
  }

  &-List {

    display: none;

    @media (min-width: $second-medium-desktop-media)
    {
      display: flex;
    }
  }

  &-Item {
    font-size: var(--extra-small);

    position: relative;

    padding: 0.4rem 2.4rem;

    text-decoration: none;
    letter-spacing: 0.09rem;
    text-transform: uppercase;

    color: var(--black);

    &:first-child
    {
      padding-left: 0;
    }

    &:last-child
    {
      padding-right: 0;

      &::after
      {
        display: none;
      }
    }

    &::after
    {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;

      width: 0.1rem;
      height: calc(100% - 0.4rem);

      content: '';

      background: var(--gray-250);
    }

    &:hover
    {
      text-decoration: underline;
    }

    &:not(.active)
    {
      cursor: pointer;
    }

    &:not(.active):hover
    {
      text-decoration: underline;
    }

    &.active
    {
      font-weight: bold;
    }
  }

}