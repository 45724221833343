.Checkbox > input
{
    position: absolute;
    z-index: -1;

    opacity: 0;
}

.Checkbox > span
{
    font-size: var(--extra-small);
    font-weight: 400;

    position: relative;

    display: inline-flex;

    width: 100%;
    min-width: 6.4rem;
    height: 4.8rem;
    padding: 1.2rem;

    cursor: pointer;
    user-select: none;

    color: var(--black);
    border: 0.2rem solid var(--gray-150);
    border-radius: 2.4rem;
    background: var(--gray-150);

    align-items: center;
}

.Checkbox > span::before
{
    position: absolute;

    display: inline-block;

    width: 2rem;
    height: 2rem;
    margin-right: 0.5em;

    content: '';

    border: 0.2rem solid var(--black);
    border-radius: 0.4rem;
    background: transparent;

    flex-shrink: 0;
    flex-grow: 0;
}

.Checkbox > span > span
{
    z-index: 10;

    margin: auto auto auto 1.2rem;
    padding-right: 3.2rem;
    padding-left: 2rem;

    text-align: center;
}

.Checkbox > input:not(:disabled):not(:checked) + span:hover::before
{
    background: var(--gray-50);
}

.Checkbox > input:not(:disabled):active + span::before
{
    border-color: var(--green-300);
    background-color: var(--green-300);
}

.Checkbox > input:checked + span::before
{
    border-color: var(--green-300);
    background: no-repeat 50% 50% url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iMTEiIHZpZXdCb3g9IjAgMCAxNCAxMSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEzLjY1NTYgMS42NjY2NUwxMi4wODg5IDAuMDg4ODY3Mkw0Ljc2NjcxIDcuNDExMDlMMS45MDAwNCA0LjU1NTUzTDAuMzIyMjY2IDYuMTIyMkw0Ljc2NjcxIDEwLjU1NTVMMTMuNjU1NiAxLjY2NjY1WiIgZmlsbD0id2hpdGUiLz4KPC9zdmc+Cg==);
    background-color: var(--green-300);
}

.Checkbox > input:checked + span
{
    border-color: var(--green-300);;
    background: var(--white);
}

.Checkbox > input:focus:not(:checked) + span::before
{
    border-color: var(--gray-300);
}

.Checkbox > input:disabled + span::before
{
    background-color: #e9ecef;
}
