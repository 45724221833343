.Chess
{
    width: 100%;
    max-width: var(--wid-project);
    margin: 0 auto;
    padding-top: 5rem;
    padding-bottom: 8rem;

    &-Row
    {
        display: flex;
    }

    &-Numbers
    {
        font-size: var(--extra-medium);
        font-weight: 400;

        display: flex;
        flex-direction: column;

        letter-spacing: 0.05rem;

        color: var(--gray-950);

        gap: 0.8rem;
    }

    &-NumbersItem
    {
        display: flex;

        width: 4.8rem;
        height: 10.8rem;

        align-items: center;
        justify-content: center;
    }

    &-ApartmentsWrap
    {
        display: flex;
        flex-direction: column;

        gap: 0.8rem;
    }

    &-Apartments
    {
        display: flex;

        align-items: center;
        justify-content: flex-start;
        gap: 0.8rem;

        &:last-child,
        &:nth-last-child(2),
        &:nth-last-child(3)
        {
            .ApartmentCard
            {
                top: auto;
                bottom: 8rem;
            }
        }
    }

    &-Item
    {
        display: flex;
        flex-direction: column;

        width: 19.2rem;
        height: 10.8rem;
        padding: 1.6rem;

        text-decoration: none;

        background: var(--white);

        justify-content: space-between;
        flex: none;

        &:hover
        {
            background: var(--green-300);

            .Chess-Title,
            .Chess-Prise,
            .Chess-Parametrs
            {
                color: var(--white);
            }

            .ApartmentCard
            {
                display: block;
                visibility: visible;

                opacity: 1;
            }

            &:last-child,
            &:nth-last-child(2)
            {
                .ApartmentCard
                {
                    right: 14rem;
                    left: auto;
                }
            }
        }

        &_sold
        {
            cursor: default;

            background: var(--gray-125);

            .Chess-Prise
            {
                color: var(--gray-950);
            }

            .Chess-Parametrs
            {
                opacity: 0;
            }

            &:hover
            {
                background: var(--gray-125);

                .Chess-Title
                {
                    color: var(--gray-550);
                }

                .Chess-Prise
                {
                    color: var(--gray-950);
                }
            }
        }

        &_booking
        {
            border: 0.1rem solid var(--gray-250);
            background: transparent;

            .Chess-Prise
            {
                color: var(--gray-950);

                &::before
                {
                    display: inline-block;

                    width: 2.8rem;
                    height: 2.8rem;

                    content: '';

                    background: no-repeat 50% 50% url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjgiIGhlaWdodD0iMjgiIHZpZXdCb3g9IjAgMCAyOCAyOCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMjIuMDAzOCAxM0w1IDEzTDUuMDAzODMgMTVWMjRINy4wMDM4M0wyMyAyNEwyMy4wMDM4IDIyVjEzSDIyLjAwMzhaIiBzdHJva2U9IiM1RjYwNjIiIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIi8+PHBhdGggZD0iTTkgMTJWOUM5IDcuNjczOTIgOS41MjY3OCA2LjQwMjE1IDEwLjQ2NDUgNS40NjQ0N0MxMS40MDIxIDQuNTI2NzggMTIuNjczOSA0IDE0IDRDMTUuMzI2MSA0IDE2LjU5NzkgNC41MjY3OCAxNy41MzU1IDUuNDY0NDdDMTguNDczMiA2LjQwMjE1IDE5IDcuNjczOTIgMTkgOVYxMiIgc3Ryb2tlPSIjNUY2MDYyIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPjwvc3ZnPg==);
                }
            }

            &:hover
            {
                cursor: default;

                background: transparent;

                .Chess-Parametrs,
                .Chess-Title
                {
                    color: var(--gray-550);
                }

                .Chess-Prise
                {
                    color: var(--gray-950);
                }
            }
        }
    }

    &-Title
    {
        font-size: var(--ultra-small);

        letter-spacing: 0.05rem;

        color: var(--gray-550);
    }

    &-Prise
    {
        font-size: var(--extra-medium);
        font-weight: bold;

        display: flex;

        letter-spacing: 0.01rem;

        color: var(--green-300);

        align-items: center;
        gap: 0.8rem;
    }

    &-Parametrs
    {
        font-size: var(--ultra-small);

        display: flex;

        letter-spacing: 0.05rem;

        color: var(--gray-550);

        align-items: center;
        justify-content: flex-start;
        gap: 2.4rem;
    }

    &-ParametrsItem
    {
        position: relative;

        &::after
        {
            position: absolute;
            top: 50%;
            right: -1.4rem;

            width: 0.4rem;
            height: 0.4rem;

            content: '';
            transform: translateY(-50%);

            border-radius: 0.4rem;
            background: var(--gray-250);
        }

        &:last-child
        {
            &::after
            {
                display: none;
            }
        }
    }
}

// Admin
.ea {
    .Chess {
        //overflow-x: scroll;
        width: calc(100vw - 302px);

        &-ApartmentsWrap {
            overflow-x: scroll;
            overflow-y: clip;
        }

        &-Item {
            width: 192px;
            height: 108px;
            padding: 16px;
        }
        &-Title {
            font-size: 13px;
        }
        &-Prise {
            font-size: 18px;
        }
        &-ParametrsItem {
            font-size: 15px;
        }
        &-Numbers {
            font-size: 18px;
        }
        &-NumbersItem {
            height: 6.8rem;
        }
    }
}