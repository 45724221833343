.Switch
{
    position: relative;

    display: inline-block;

    margin: 0;

    cursor: pointer;

    width: 100%;

    &-Inner
    {
        position: relative;

        display: block;
        overflow: hidden;

        width: 100%;
        height: 4.8rem;
        margin: 0;

        transition: all var(--transition-time) ease;

        border-radius: 3.2rem;
        background: var(--gray-150);

        @media (min-width: $tablet-media) {
            height: 5.6rem;
        }  //768

        @media (min-width: $desktop-media) {
            height: 6.4rem;
        } //1024

        &:before
        {
            font-size: var(--extra-small);
            font-weight: 400;

            position: absolute;
            top: 50%;
            right: 2rem;

            content: attr(data-on);
            transform: translateY(-50%);

            color: var(--gray-950);
            //width: 46%;
            width: 40%;
            text-align: center;
        }

        &:after
        {
            font-size: var(--extra-small);
            font-weight: 400;
            //line-height: 4.8rem;
            line-height: 3.2rem;

            position: absolute;
            top: 0.8rem;
            left: 0.8rem;

            //width: 10rem;
            width: 40%;
            //height: 4.8rem;
            height: 3.2rem;

            content: attr(data-off);
            transition: all var(--transition-time) ease;
            text-align: center;

            color: var(--white);
            border-radius: 3.2rem;
            background: var(--green-300);
            box-shadow: 0 0.2rem 0.4rem rgba(0, 166, 85, 0.18), 0 0.4rem 0.8rem rgba(0, 166, 85, 0.18);


            @media (min-width: $mobile-media) {
                line-height: 4rem;
                height: 4rem;
            }  //768

            @media (min-width: $desktop-media) {
                line-height: 4.8rem;
                height: 4.8rem;
            } //1024


        }

    }


    input[type='checkbox']
    {
        font-family: 'TT Firs Neue', Arial, Helvetica, sans-serif;
        position: absolute;
        z-index: 1;
        top: 0;

        width: 100%;
        height: 6.4rem;
        margin: 0;

        cursor: pointer;

        opacity: 0;
    }

    input[type='checkbox']:checked + label.Switch-Inner
    {
        background: var(--gray-150);
    }

    input[type='checkbox']:checked + label.Switch-Inner:after
    {
        right: 0.8rem;
        left: auto;

        //width: 46%;
        width: 50%;

        content: attr(data-on);

        background: var(--green-300);
    }

    input[type='checkbox']:checked + label.Switch-Inner:before
    {
        right: auto;
        left: 2rem;

        content: attr(data-off);
    }

    input[type='checkbox']:checked + label.Switch-Inner
    {
        /*background: #66BB6A; */
        /*color: #fff;*/
    }

    input[type='checkbox']:checked ~ .alert
    {
        display: block;
    }


    .alert
    {
        display: none;

        color: #fff;
        border: none;
        background: #ff9800;
    }


    &_small {
        .Switch-Inner {
            width: 21.8rem;

            @media screen and (max-width: $mobile-media)
            {
                width: 100%;
            }
        }
    }

    input[type='checkbox']:checked + label.Switch-Inner:after {
        width: 46%;
    }

    &_disabled {
        .Switch-Inner {
            &:after {
                background: transparent;
                box-shadow: none;
                color: var(--gray-950);
                width: 100%;
                left: 0;
            }
            &:before {
                display: none;
            }
        }
    }

}
