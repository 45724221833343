.FooterMenu
{
    flex-grow: 1;

    &-Wrapper
    {
        position: relative;

        display: flex;
        overflow: hidden;
        flex-direction: column;

        transition: height var(--transition-time);

        align-items: flex-start;
        justify-content: space-between;
        //flex-wrap: wrap;
        gap: 1.6rem;

        @media (min-width: $desktop-media)
        {
            flex-direction: row;

            height: 27.7rem;
            padding-bottom: 2.4rem;
        }

        //1024

        &::after
        {
            position: absolute;
            z-index: 100;
            right: 0;
            bottom: 0;
            left: 0;

            display: none;

            width: 100%;
            height: 7rem;

            content: '';
            transition: opacity var(--transition-time);

            background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(236,236,236,1) 70%, rgba(236,236,236,1) 100%);

            @media (min-width: $desktop-media)
            {
                display: block;
            } //1024
        }

        &_active
        {
            height: auto;

            &::after
            {
                z-index: -1;

                opacity: 0;
            }
        }
    }

    &-Col
    {
        position: relative;

        width: 100%;

        //min-height: 24rem;

        //flex-basis: 24rem;

        //&:hover {
        //    .FooterMenu-List {
        //        display: flex;
        //    }
        //}


        @media (min-width: $desktop-media)
        {
            min-height: 24rem;

            flex-basis: 24rem;
        } //1024
    }

    &-Arrow
    {
        position: absolute;
        z-index: 99;
        top: -0.2rem;
        right: 0;

        display: inline-block;

        width: 2.4rem;
        height: 2.4rem;

        content: '';
        cursor: pointer;

        background: no-repeat 50% 0 url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNNC4yODExNiA4LjMzNjUxTDEyLjM5NCAxNi40NTA0TDIwLjUwNzggOC4zMzY0MyIgc3Ryb2tlPSJibGFjayIgc3Ryb2tlLXdpZHRoPSIyLjUiLz48L3N2Zz4=);

        @media (min-width: $desktop-media)
        {
            display: none;
        } //1024
    }

    &-Limiter
    {
        display: none;

        padding: 1.4rem 0;

        text-align: center;

        border-top: 0.1rem solid var(--gray-300);

        @media (min-width: $desktop-media)
        {
            display: flex;

            justify-content: center;
        } //1024
    }

    &-LimiterButton
    {
        font-size: var(--extra-small);
        font-weight: 400;
        //display: flex;

        display: none;

        cursor: pointer;
        user-select: none;
        transition: color var(--transition-time);
        letter-spacing: 0.05rem;

        color: var(--gray-500);

        align-items: center;
        justify-content: center;
        gap: 1.3rem;

        @media (min-width: $desktop-media)
        {
            display: flex;
        }

        //1024

        &::after
        {
            display: block;

            width: 1.6rem;
            height: 1rem;

            content: '';

            background: no-repeat 50% 50% url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTAiIHZpZXdCb3g9IjAgMCAxNiAxMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMSAxLjVMOCA4LjVMMTUgMS41IiBzdHJva2U9IiM3MjcyNzMiIHN0cm9rZS13aWR0aD0iMS41Ii8+PC9zdmc+);
        }

        &:hover
        {
            color: var(--gray-700);

            &::after
            {
                background: no-repeat 50% 50% url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTAiIHZpZXdCb3g9IjAgMCAxNiAxMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMSAxLjVMOCA4LjVMMTUgMS41IiBzdHJva2U9IiM1OTU5NTkiIHN0cm9rZS13aWR0aD0iMS41Ii8+PC9zdmc+);
            }
        }

        &_active
        {
            color: var(--black);

            &::after
            {
                background: no-repeat 50% 50% url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTAiIHZpZXdCb3g9IjAgMCAxNiAxMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMTUgOC41TDggMS41TDEgOC41IiBzdHJva2U9ImJsYWNrIiBzdHJva2Utd2lkdGg9IjEuNSIvPjwvc3ZnPg==);
            }

            &:hover
            {
                color: var(--black);

                &::after
                {
                    background: no-repeat 50% 50% url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTAiIHZpZXdCb3g9IjAgMCAxNiAxMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMTUgOC41TDggMS41TDEgOC41IiBzdHJva2U9ImJsYWNrIiBzdHJva2Utd2lkdGg9IjEuNSIvPjwvc3ZnPg==);
                }
            }
        }
    }

    &-Title
    {
        font-size: var(--extra-small);
        font-weight: bold;

        position: relative;

        display: block;

        margin-bottom: 2rem;

        text-decoration: none;
        text-transform: uppercase;

        color: var(--black);

        //&::before {
        //    content: '';
        //    display: inline-block;
        //    width: 2.4rem;
        //    height: 2.4rem;
        //    position: absolute;
        //    right: 0;
        //    top: 50%;
        //    transform: translateY(-50%);
        //    background: no-repeat 50% 50% url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNNC4yODExNiA4LjMzNjUxTDEyLjM5NCAxNi40NTA0TDIwLjUwNzggOC4zMzY0MyIgc3Ryb2tlPSJibGFjayIgc3Ryb2tlLXdpZHRoPSIyLjUiLz48L3N2Zz4=);
        //
        //    @media (min-width: $desktop-media) {
        //        display: none;
        //    } //1024
        //}

        @media screen and (max-width: $small-mobile-media)
        {
            font-size: var( --small );

            margin-bottom: 1rem;
        }

        &_hideMob
        {
            @media screen and (max-width: $landscape-mobile-media)
            {
                display: none;
            }
        }

        &:hover
        {
            text-decoration: none;

            @media (min-width: $tablet-media)
            {
                text-decoration: underline;
            }  //768
        }
    }

    &-List
    {
        //display: flex;
        display: none;
        flex-direction: column;

        margin: 0;
        padding: 0;

        list-style: none;

        gap: 0.9rem;

        @media (min-width: $desktop-media)
        {
            display: flex;
        }

        //1024

        &_active
        {
            display: flex;
        }
    }

    &-Link
    {
        font-size: var(--small);
        line-height: 1.6;

        text-decoration: none;

        color: var(--gray-500);

        &:hover
        {
            text-decoration: underline;

            color: var(--black);
        }
    }
}
