
$awards-slider-nav-shift: -11rem;

.Awards
{
    padding-top: 6.5rem;

    &,
    &Carousel-Slick
    {
        width: 100%;
        max-width: var(--wid-project);
        margin-right: auto;
        margin-left: auto;
    }

    &-List
    {
        margin: 0 0 6.5rem 0;
    }

    &-Item {
        display: grid;
        gap: 3.2rem;
        align-items: center;
        justify-content: space-between;
        grid-template-columns: 10.5rem 37.5rem 10.5rem 37.5rem 1fr;

        padding: 3.2rem 0;

        cursor: pointer;

        border-bottom: 0.1rem solid var( --gray-25 );

        &:last-child
        {
            border-bottom: 0;
        }

        &_header
        {
            cursor: default;
        }
    }

    &-Image
    {
        img
        {
            object-fit: contain;
            object-position: center;
        }
    }

    &-Label
    {
        font-size: var( --extra-medium );

        padding: 0;

        text-align: center;
        letter-spacing: 0.05rem;

        &_image
        {
            grid-column: 1 / 2;
            //height: 10.5rem;
        }

        &_title
        {
            font-weight: 400;
            grid-column: 2 / 3;
        }

        &_year
        {
            grid-column: 3 / 4;
        }

        &_author
        {
            grid-column: 4 / 5;
            font-weight: bold;
        }

        &_description
        {
            grid-column: 5 / 6;
            font-size: var( --extra-small );

            padding-right: 0;
        }
    }
    
    &Carousel
    {
        &.SliderCarouselPosit
        {
            margin-bottom: 10rem;
        }

        &-Slick
        {
            .slick
            {
                &-arrow
                {
                    &.slick-prev
                    {
                        left: $awards-slider-nav-shift;
                    }

                    &.slick-next
                    {
                        right: $awards-slider-nav-shift;
                    }
                }

                &-dots
                {
                    bottom: -8rem;

                    display: flex;

                    width: auto;

                    justify-content: flex-start;

                    li
                    {
                        width: 100%;
                        height: auto;
                        margin: 0;

                        border-top: 0.1rem solid var( --white );

                        &.slick-active
                        {
                            position: relative;

                            &:before
                            {
                                position: absolute;
                                top: 0;
                                left: 0;

                                width: 100%;
                                height: 100%;

                                content: '';

                                background: rgba( 0,0,0, 0.6 );
                            }
                        }
                    }
                }
            }
        }
    }

    &-Thumb
    {
        display: block;

        width: 14.2rem;
        height: 8rem;
    }
}
