.Tabs
{
    max-width: var(--max-wid-project);
    margin: 0 auto 3rem;

    &_width
    {
        &_full
        {
            max-width: 100%;
            margin-top: 6.4rem;

            &_no-margin
            {
                margin: 0 0 3rem 0;

                @media screen and (max-width: $desktop-media)
                {
                    position: relative;
                }

                @media screen and (max-width: $tablet-media)
                {
                    margin-bottom: 5.1rem;
                }
            }

            .Tabs-Caption
            {
                max-width: var(--wid-project);
                margin-right: auto;
                margin-left: auto;
            }
        }
    }

    &_content
    {
        &_center
        {
            display: flex;

            align-items: center;
        }
    }

    &_compact
    {
        margin: 0;
    }

    &-CaptionSelect + .chosen-container
    {
        width: calc(100% - 3.2rem) !important;
        margin-right: 1.6rem;
        margin-bottom: 1.6rem;
        margin-left: 1.6rem;

        @media (min-width: $tablet-media)
        {
            width: calc(50% - 3.2rem) !important;
            margin-right: 2.4rem;
            margin-bottom: 2.4rem;
            margin-left: 2.4rem;
        }

        //768
        @media (min-width: $desktop-media)
        {
            width: calc(50% - 3.2rem) !important;
            margin-right: 3.2rem;
            margin-bottom: 3.2rem;
            margin-left: 3.2rem;
            //width: calc(100% - 6.4rem) !important;
        }

        //1024

        @media (min-width: $second-medium-desktop-media)
        {
            display: none;
        } //1407
    }

    &-Caption
    {
        position: relative;

        display: none;

        margin-bottom: 3rem;

        list-style: none;

        flex-wrap: wrap;
        @include AdaptiveBlock;

        @media (min-width: $second-medium-desktop-media)
        {
            display: flex;
        }

        //1407

        &_compact
        {
            margin-top: 2rem;
            margin-bottom: 2rem;
            padding-right: 2rem;
            padding-left: 2rem;

            @media (max-width: $mobile-media)
            {
                margin-top: 1.2rem;
                margin-bottom: 1.2rem;
                padding-right: 1rem;
                padding-left: 1rem;
            }

            .Button
            {
                @media (max-width: $mobile-media)
                {
                    font-size: var(--small) ;
                }
            }
        }

        &_center
        {
            justify-content: center;
        }

  

        li,
        a
        {
            font-size: var(--extra-small);

            position: relative;

            padding: 0.4rem 2.4rem;

            text-decoration: none;
            letter-spacing: 0.09rem;
            text-transform: uppercase;

            color: var(--black);

            &:first-child
            {
                padding-left: 0;
            }

            &:last-child
            {
                padding-right: 0;

                &::after
                {
                    display: none;
                }
            }

            &::after
            {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;

                width: 0.1rem;
                height: calc(100% - 0.4rem);

                content: '';

                background: var(--gray-250);
            }

            &:hover
            {
                text-decoration: underline;
            }

            &:not(.active)
            {
                cursor: pointer;
            }

            &:not(.active):hover
            {
                text-decoration: underline;
            }

            &.active
            {
                font-weight: bold;
            }
        }

        .Button
        {
            a
            {
                &,
                &:hover,
                &:active,
                &:focus
                {
                    text-decoration: none;
                    text-transform: none;

                    color: inherit;
                }
            }
        }
    }

    &-Content
    {
        display: none;

        flex-grow: 1;

        &.active
        {
            display: block;
        }
    }

    &_vertical
    {
        display: flex;

        margin-bottom: 0;

        gap: 8rem;

        .Tabs-Caption
        {
            flex-direction: column;

            flex-basis: 51.2rem;
        }

        .Tabs-Caption li
        {
            font-size: var(--extra-medium);
            font-weight: 400;

            position: relative;

            padding: 1.8rem 2.4rem;

            letter-spacing: 0.025rem;
            text-transform: none;

            color: var(--black);
            border-bottom: 0.1rem solid var(--black);

            &::after
            {
                display: none;
            }

            &:not(.active)
            {
                cursor: pointer;
            }

            &:not(.active):hover
            {
                background: no-repeat 97% 50% url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAxNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMSAyM0wxMiAxMkwxIDEiIHN0cm9rZT0iYmxhY2siIHN0cm9rZS13aWR0aD0iMiIvPjwvc3ZnPg==);
            }

            &.active
            {
                font-weight: bold;

                background: no-repeat 97% 50% url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAxNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMSAyM0wxMiAxMkwxIDEiIHN0cm9rZT0iYmxhY2siIHN0cm9rZS13aWR0aD0iMiIvPjwvc3ZnPg==);
            }
        }

        .Tabs-Caption li:last-child:before
        {
            display: none;
        }

        .Tabs-Caption .active:after
        {
            //left: auto;
            //top: 0;
            //right: -2px;
            //bottom: 0;
            //width: 2px;
            //height: auto;
        }

        .Tabs-Content
        {
            overflow: hidden;
        }
    }

    &-CaptionPosit
    {
        max-width: var(--wid-project);
        margin-right: auto;
        margin-left: auto;
    }

    &_pad
    {
        padding-top: 3.6rem;
    }

    &_gray-bg
    {
        .Tabs-CaptionPosit
        {
            .Tabs-Caption
            {
                display: inline-flex;

                padding: 0.8rem;

                border-radius: 5rem;
                background: var(--gray-150);
            }
        }

        .Tabs-Content
        {
            background: var(--gray-150);
        }
    }

    &_button
    {
        .Tabs-Caption
        {
            gap: 0.8rem;

            li
            {
                font-weight: 400;

                display: flex;

                padding: 1.4rem  2rem;

                text-decoration: none;
                text-transform: none;

                border-radius: 5rem;

                align-items: center;
                justify-content: center;

                &:hover
                {
                    text-decoration: none;

                    color: var(--white);
                    background-color: var(--green-300);
                }

                &.active
                {
                    color: var(--white);
                    background-color: var(--green-300);
                }

                &::after
                {
                    display: none;
                }
            }
        }

        &_white
        {
            .Tabs-Caption
            {
                li
                {
                    background: var(--white);

                    &:hover
                    {
                        background-color: var(--green-300);
                    }
                }
            }
        }

        &_gray
        {
            .Tabs-Caption
            {
                li
                {
                    background: var(--gray-150);

                    //&:hover {
                    //    background-color: var(--green-300);
                    //}
                }
            }
        }

        &_vertical
        {
            display: flex;

            margin-bottom: 0;

            gap: 8rem;

            .Tabs-Caption
            {
                flex-direction: column;

                width: auto;

                li
                {
                    width: 4.8rem;
                    height: 4.8rem;
                    padding: 0;
                }
            }
        }
    }
}

//Admin styles
.ea
{
    .Tabs
    {
        margin: 0 auto 30px;

        &_width
        {
            &_full
            {
                margin-top: 64px;

                &_no-margin
                {
                    margin: 0 0 30px 0;
                }
            }
        }

        &-CaptionSelect + .chosen-container
        {
            width: calc(100% - 320px) !important;
            margin-right: 16px;
            margin-bottom: 16px;
            margin-left: 16px;
        }

        &-Caption
        {
            margin-bottom: 30px;

            li,
            a
            {
                padding: 4px 24px;

                letter-spacing: 0.9px;

                &::after
                {
                    width: 1px;
                    height: calc(100% - 4px);
                }
            }
        }

        &_vertical
        {
            gap: 80px;

            .Tabs-Caption
            {
                flex-basis: 512px;
            }

            .Tabs-Caption li
            {
                padding: 18px 24px;

                letter-spacing: 0.25px;
            }
        }

        &_pad
        {
            padding-top: 36px;
        }

        &_gray-bg
        {
            .Tabs-CaptionPosit
            {
                .Tabs-Caption
                {
                    padding: 8px;

                    border-radius: 5px;
                }
            }
        }

        &_button
        {
            .Tabs-Caption
            {
                gap: 8px;

                li
                {
                    padding: 14px  20px;

                    border-radius: 5px;
                }
            }

            &_vertical
            {
                gap: 8px;

                .Tabs-Caption
                {
                    li
                    {
                        width: 48px;
                        height: 48px;
                    }
                }
            }
        }
    }
}
